.paypal-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.paypal-container table {
  width: 260px !important;
}

.paypal-container table td {
  padding: 7px 8px;
  text-align: left;
  vertical-align: top;
  border: none;
  font-weight: bold;
}

.paypal-container table td:nth-child(2) {
    text-align: right;
}

.paypal-checkout {
  background-image: url(https://www.paypalobjects.com/en_AU/i/btn/btn_buynowCC_LG.gif);
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 60px;
  background-color: #f54989;
  box-shadow: 1px 5px 0px -2px #8c1d1d;
  border-radius: 20px;
  cursor: pointer;
}
