.mobile-menu {
  background: #fff;
  position: relative;

  & .logo {
    margin: 10px;
    width: 130px;
  }

  & .search-form {
    & .search-input {
      height: 40px;
      margin: 0;
      position: absolute;
      top: 5px;
      right: 45px;
      line-height: 40px;
      width: 40%;
    }

    & .search-icon {
      height: 40px;
      position: absolute;
      right: 45px;
      top: 10px;
      width: 40px;
      font-size: 24px;
      color: #4d4d4d;
      text-align: center;
    }
  }

  & .menu-icon {
    position: absolute;
    right: 5px;
    top: 3px;
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    color: #4d4d4d;
    font-size: 30px;
    margin: auto;
    cursor: pointer;
  }

  & #bottom-header-mobile {
    & #menu-menu-login-m {
      height: 47px;
      position: relative;
      border-bottom: 1px solid #ebebeb;

      & .menu-button {
        padding: 5px;
        color: #fff;
        border: none;
        border-radius: 7px;
        background: #f54989;
        font-weight: bold;
        float: right;
        margin: 5px;
        cursor: pointer;
      }

      & .sub-menu {
        display: none;
      }

      & .sub-menu-active {
        display: inherit;
        visibility: unset;
        opacity: unset;
      }

      & .contact {
        padding: 5px;
        color: #4d4d4d;
        border: none;
        border-radius: 7px;
        margin: 5px;
        float: left;
        font-weight: bold;
      }
    }
  }

  & #drop-menu {
    background: white;
    position: relative;

    & .payment-item {
      padding-left: 10px;
      border-top: solid #efefef 1px;

      & h4 {
        font-size: 14px;
        font-weight: 500 !important;
        position: relative;
        padding: 12px 0 12px;

        & i {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 15px;
          bottom: 0px;
          margin: auto;
          right: 10px;
          cursor: pointer;
        }
      }

      & a {
        padding: inherit;
        padding-left: 10px;
        border-top: solid #efefef 1px;
      }

      & span {
        padding: inherit;
        padding-left: 10px;
        border-top: solid #efefef 1px;
        padding-top: 9px;
        padding-bottom: 7px;
        cursor: pointer;
      }

      & .action {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
