#form-file-upload {
  height: 250px;
  min-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #F8FAFC;
  width: 100% !important;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  background: #f54989;
  color: #fff;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding: 8px;
  border: none;
  margin-top: 10px;
}

.upload-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.upload-container p {
  cursor: pointer;
}

.upload-container p:hover {
  text-decoration: underline;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.uploading-container {
  display: flex;
  justify-content: center;
}

.uploading-container span {
  margin-right: 10px;
}

.uploaded-container {
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.file-item {
  display: flex;
  width: 500px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  padding: 10px 20px;
  align-items: center;
  border: 1px #000 solid;
  margin-bottom: 10px;
}

.file-item-name {
  flex-grow: 1;
  font-weight: bold;
  max-width: 100%;
  word-break: break-word;
  margin-right: 20px;
}

.uploaded-container .fa-file-o {
  font-size: 25px;
  margin-right: 20px;
}

.uploaded-container .fa-close {
  font-size: 18px;
  margin-left: 50px;
  cursor: pointer;
}

.upload-error-container .err-item {
  display: block;
  color: #f00;
  font-style: italic;
  font-weight: 600;
  line-height: 1;
  padding: 10px 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
  margin-top: 5px;
  clear: left;
  font-size: 12px;
}
