.bankTransfer-container img {
  width: 200px;
  height: 200px;
}
.bankTransfer-buttons {
  gap: 1rem;
  padding: 1rem 0;
  font-size: 13px;
  display: flex;
  justify-content: center;
  overflow: auto;

  & .bankTransfer-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    border: none;
    background: unset;
    margin-right: 10px;

    & .radioButton {
      display: flex;
      margin-bottom: 1rem;
      width: 100%;
      & input[type="radio"] {
        width: unset !important;
        box-shadow: unset !important;
      }

      & label {
        width: fit-content !important;
        padding: 15px 15px;
      }
    }

    & img {
      width: 100%;
      height: 50px;
      min-width: 100px;
    }
  }

  & .bankTransfer-button-selected {
    & img {
      border: 2px solid #0000CD;
      margin-left: 10px;
      width: calc(100% - 4px);
    }

    & label {
      border: 2px solid #0000CD;
      margin-left: 10px;
      width: calc(100% - 4px);
    }
  }
}

& .bankTransfer-information {
  width: fit-content;
  margin: 0 auto;
  padding: 2rem 3rem;
  border-radius: 1rem;

  & .qrCode {
    width: 170px;
    height: 170px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }

  & .logo {
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
    height: 100px;
  }

  & .description {
    margin: 0 auto;
    width: fit-content;

    & .bank-item {
      display: flex;
      max-width: 350px;
      width: fit-content;
      font-size: 12px;

      & .value {
        font-weight: 700;
        width: 250px;
        word-break: break-all;
      }

      & .bank-item-title {
        width: 130px;
        display: inline-block;
        color: #4d4d4d;
      }
    }
  }
  & .warning {
    margin-top: 2em;
    font-size: 13px;
    color: red;
    text-align: center;

    & span {
      font-weight: 700;
    }
  }

  & .note {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    font-size: 12px;

    & span {
      margin-bottom: 5px;
    }
  }

  & .checkout-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1em;
    border: none;
    background: #f54989;
  }
}

& .bankTransfer-information-nepal {
  & .logo {
    width: 200px;
  }
}
