.payment-url-container {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .logo {
    margin-bottom: 40px;
  }

  & .contact {
    width: 100%;
    max-width: 1000px;
  }
}


.allianz-payment {
  width: 100%;
  border: 1px solid #f54989;
  padding: 2rem;
  position: relative;
  margin: 2rem;
  background-color: #fff;
  max-width: 500px;

  & .header {
    position: absolute;
    top: -32px;
    height: 40px;
    background: #fff;
    line-height: 40px;
    left: 20px;
    padding: 0px 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #f54989;
    margin-bottom: 1rem;
  }

  & .payment-item {
    margin-bottom: 1rem;
    & .tel-title {
      margin: 0;
      padding: 10px;
      position: relative;
      background-color: #f54989;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      line-height: 1.1;

      & i {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 15px;
        bottom: 0px;
        margin: auto;
        right: 10px;
        cursor: pointer;
      }
    }

    & .personal-information {
      padding: 1rem;
      width: fit-content;
      margin: 0 auto;
      & .description {
        & .item {
          padding: 3px;
          & .title {
            width: 100px;
            display: inline-block;
          }

          & .value {
            font-weight: 700;
          }
        }
      }
    }

    & iframe {
      min-height: 500px;
      width: 100%;
    }
  }
}
