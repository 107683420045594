/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/

/*
    Created on : Dec 12, 2015, 8:59:20 AM
    Author     : KCF-chicken
*/

/*...*/

#options_td input,
#options_td select {
  border-radius: 14px;
}

#options_td input:disabled,
#options_td select:disabled {
  background: #dddddd;
}

#oshc_au_options_quote input:disabled,
#oshc_au_options_quote select:disabled,
#ovhc_options_quote input:disabled,
#ovhc_options_quote select:disabled,
#oshc_allianz_options_quote input:disabled,
#oshc_allianz_options_quote select:disabled {
  background: #dddddd;
}

#options_td li {
  list-style-position: outside;
  margin-left: 20px;
}

#options_td {
  padding-right: 40px;
  width: 250px;
}

#wrap .main-quote table td#options_td {
  border-right: 2px solid #f54989;
}

#provider_td > table > tbody > tr > td > table > tbody > tr > td:nth-child(1) {
  padding: 0;
}

#wrap
  .main-quote
  table
  td
  > table
  > tbody
  > tr
  > td.uc_oshc
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  background: #ebebeb;
  font-size: 10px;
  font-weight: lighter;
  width: 160px;
  margin-right: calc(50% - 170px);
  padding: 0px;
  text-align: left;
}

#wrap
  .main-quote
  table
  td
  > table
  > tbody
  > tr
  > td.uc_oshc
  > table
  > tbody
  > tr
  > td:nth-child(3) {
  background: #f54989;
  font-size: 24px;
  color: white;
  width: 160px;
  margin-left: calc(50% - 170px);
  padding: 0px;
}

#wrap
  .main-quote
  table
  td
  > table
  > tbody
  > tr
  > td.uc_oshc
  > table
  > tbody
  > tr
  > td:nth-child(4) {
  background: #f54989;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 160px;
  margin-left: calc(50% - 170px);
  padding: 0px;
}

a.a_policy,
a.a_card,
a.a_guideline {
  color: #333333;
}

a.a_policy:hover,
a.a_card:hover,
a.a_guideline:hover {
  color: #f54989;
}

a.a_policy:before,
a.a_card:before,
a.a_guideline:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 43px;
  width: 23px;
  margin: 5px 6px;
}

a.a_policy:before {
  background: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/policy.png)
    no-repeat center center;
}

a.a_policy:hover:before {
  background: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/policy_hover.png)
    no-repeat center center;
}

a.a_card:before {
  background: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/card.png)
    no-repeat center center;
}

a.a_card:hover:before {
  background: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/card_hover.png)
    no-repeat center center;
}

a.a_guideline:before {
  background: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/guideline.png)
    no-repeat center center;
}

a.a_guideline:hover:before {
  background: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/guideline_hover.png)
    no-repeat center center;
}

.img_logo {
  height: auto;
  width: 160px;
}

.img_display {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/*...*/

#show-pass,
#hide-pass {
  border: 1px solid #ddd;
  padding: 5px 20px;
  display: inline-block;
}

.err-item {
  color: #f93636;
}

#wrap label,
#wrap .wrap-field .label {
  float: left;
  padding-left: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  width: 30%;
  text-align: right;
  color: #333;
  font-weight: bold;
}

#wrap.join_us label,
#wrap.join_us .wrap-field .label {
  width: 30% !important;
}

#wrap .field-item.showtext {
  width: 80%;
}

#wrap input,
#wrap select,
#wrap .wrap-field textarea {
  padding: 6px;
  height: auto;
  line-height: normal;
  margin: 0;
  border: 1px solid #a18989;
}

#wrap .wrap-input-field.time-field input {
  width: 40%;
  float: left;
  margin-right: 10px;
}

#wrap .wrap-input-field.time-field select {
  height: 30px;
}

#wrap .field-val,
#wrap .wrap-field .value,
#wrap .wrap-input-field {
  float: left;
  width: 40%;
}

#wrap .wrap-input-field input,
#wrap .wrap-input-field textarea,
#wrap .wrap-input-field select {
  width: 100%;
}

#wrap .personal label,
#wrap .personal .wrap-field .label {
  /* float: left;
    padding-left: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    width: 25%;
    text-align: right;
    color: #333;*/
}

#wrap #wrap .personal .field-val,
#wrap .personal .wrap-field .value,
#wrap .personal input,
#wrap .personal select,
#wrap .personal .show-value,
#wrap .personal .wrap-field textarea,
#wrap .personal .right-radio {
  float: left;
}

#wrap .wrap-field textarea {
  height: 150px;
}

#wrap .field-item .date-item {
  float: left;
  margin-right: 5%;
}

#wrap .field-item .date-item:nth-child(1) {
  width: 20%;
}

#wrap .field-item .date-item:nth-child(2) {
  width: 40%;
}

#wrap .field-item .date-item:nth-child(3) {
  width: 30%;
}

#wrap .field-item .date-item:last-child {
  margin-right: 0;
}

#wrap .field-item:after {
  content: "";
  display: block;
  clear: both;
}

#wrap .field-item .first-name {
  float: left;
  width: 48%;
  margin-right: 4%;
}

#wrap .field-val span input,
#wrap .field-val span select {
  width: 100%;
}

#wrap .field-item .last-name {
  float: left;
  width: 48%;
}

#wrap .field-item,
#wrap .text-field,
#wrap .div-meta-key {
  margin: 15px 0px;
  position: relative;
}

#wrap .wrap-field {
}

#wrap .form-register-service:after {
  content: "";
  display: block;
  clear: both;
}

#wrap .form-register-service .personal {
  float: left;
  width: 50%;
  border-right: 2px solid #f2f2f2;
  padding-right: 6%;
  box-sizing: border-box;
}

#wrap .form-register-service .select-service {
  /*float: right;
    width: 50%;*/
  float: left;
  width: 100%;
  padding-left: 6%;
  box-sizing: border-box;
}

#wrap .terms .wrap-field label {
  width: calc(100% - 50px);
  text-align: left;
}

#wrap #terms,
#wrap #confirm,
#wrap #awarePreviousOnVisitorVisa,
#wrap #eligible,
#wrap .terms {
  /*    width: auto !important;*/
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
}

#wrap .terms input[type="checkbox"] {
  width: auto !important;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
}

#wrap .right-radio input,
#wrap .right-radio label {
  width: auto !important;
  float: none;
}

#wrap .right-radio input {
  margin-left: 15px;
}

#wrap .right-radio label {
  margin-left: 5px;
  padding-left: 0px;
}

#wrap .terms:after,
#wrap .field-item .wrap-field:after,
#wrap .wrap-field:after,
#wrap .div-meta-key:after {
  content: "";
  display: block;
  clear: both;
}

#wrap .select-service .list-right-input .meta_key_post div:after {
  content: "";
  display: block;
  clear: both;
}

#wrap .title-headding {
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

#wrap .text-required {
  color: #f00;
  font-size: 12px;
  margin-left: 3px;
}

#wrap .field-item .err-item,
#wrap .div-meta-key .err-item,
#wrap .form-field .err-item {
  display: block;
  color: #f00;
  font-style: italic;
  font-weight: 600;
  line-height: 1;
  padding: 10px 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
  margin-top: 5px;
  clear: left;
  font-size: 12px;
}

#wrap .wrap-input-field {
  float: left;
}

#wrap table .err-item,
p.err {
  display: block;
  width: 100%;
  float: right;
  color: #f00;
  line-height: 1;
  font-weight: 600;
  padding: 10px 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
  margin-top: 5px;
  font-size: 12px;
  line-height: 1.3;
  font-style: italic;
}

/*#wrap .field-item .err-item:after,
#wrap .div-meta-key .err-item:after,
#wrap .form-field .err-item:after,
#wrap table .err-item:after,
p.err:after
{
    content: '';
    position: absolute;
    border: 5px solid transparent;
    border-bottom: 5px solid #CF284F;
    top: -10px;
    left: 5px;
}*/

#wrap .button {
  clear: both;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
  float: left;
}

#wrap .button1 {
  clear: both;
  width: 100%;
  text-align: center;
  display: block;
  padding: 10px 0;
}

#wrap .button input[type="submit"],
#wrap .button .button-submit {
  display: inline-block;
  width: 180px;
  float: none;
  margin: 10px 10px;
  background: #fff;
  text-transform: uppercase;
  font-weight: 400;
  color: #82730f;
  border: 0px;
  border-top: 2px solid #ffde00;
  border-bottom: 2px solid #ffde00;
  cursor: pointer;
  border-radius: 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  font-style: normal;
  padding: 10px;
}

/*Add 2082016*/

#wrap .button1 .btn {
  display: inline-block;
  width: auto;
  float: none;
  margin: 0px 10px;
  background: #ff99cc;
  text-transform: uppercase;
  font-weight: 400;
  color: white;
  border: 0px;
  cursor: pointer;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  font-style: normal;
  padding: 5px 10px;
}

#wrap .button a.button-submit:hover {
  text-decoration: underline;
}

#wrap .button input[type="reset"],
#wrap .button .button-cancel {
  display: inline-block;
  width: 15%;
  float: none;
  margin: 0px 10px;
  background: #fff;
  text-transform: uppercase;
  font-weight: 400;
  color: #545454;
  border: 0px;
  border-top: 2px solid #f54989;
  border-bottom: 2px solid #f54989;
  cursor: pointer;
  border-radius: 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  padding: 10px;
}

#wrap .button .button-cancel,
#wrap .button a {
  padding: 6px 8px;
}

#wrap .button input:hover,
#wrap .button .button-item:hover {
  background-color: #545454;
  border-top: 2px solid #545454;
  border-bottom: 2px solid #545454;
  color: #fff;
}

#wrap .text-field .value,
#wrap .show-value {
  background-color: #e6e6e6;
  padding: 8px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
}

#wrap .terms .err-item {
  float: left;
  margin-left: 10px;
}

#wrap .list-title:after {
  content: "";
  display: block;
  clear: both;
}

#wrap .list-title {
  text-align: center;
  margin-top: 25px;
}

#wrap .list-title .title-item {
  border: 1px solid #a18989;
  border-radius: 12px;
  display: inline-block;
  width: 15%;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}

#wrap .list-title .title-item.active {
  font-weight: bold;
  color: #f54989;
  border-color: #f54989;
}

#wrap .list-title .title-item.active:after {
  content: "";
  display: none;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 20px;
  height: 20px;
  border-left: 1px solid #f54989;
  border-top: 1px solid #f54989;
  background-color: #fff;
  bottom: -31px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 2;
}

#wrap .main-quote,
#wrap .main-apply {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

#wrap .main-quote table td,
#wrap .main-quote table th,
#wrap .main-quote table tr,
#wrap .none-border td,
#wrap .none-border th,
#wrap .none-border tr {
  border: none;
}

#wrap .main-quote table,
#wrap .main-apply table {
  width: 100%;
}

#wrap .main-quote table input,
#wrap .main-quote table select,
#wrap .main-apply table input,
#wrap .main-apply table select {
  width: 100%;
}

#wrap .main-quote table .th td,
#wrap .border .th,
#wrap .bold {
  font-weight: bold;
}

#wrap .main-apply table.border {
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #e0e0e0;
}

#wrap .main-apply table.border td,
#wrap .main-apply table.border th {
  border: 4px solid white;
  padding: 5px 8px;
}

#wrap table td .date-item {
  width: 32%;
  float: left;
  margin-left: 2%;
}

#wrap .ovhc-us table td .date-item {
  width: auto !important;
}

#wrap table td .date-item:first-child {
  margin: 0;
}

#wrap table td err-item {
  clear: both;
}

#wrap table.table-partner tr td:nth-child(1) {
  width: auto;
}

#wrap table.table-partner tr td:nth-child(2) {
  width: auto;
}

#wrap table.table-partner tr td:nth-child(3) {
  width: auto;
}

#wrap table.table-partner tr td:nth-child(4) {
  width: 30%;
}

#wrap table.table-partner tr td:nth-child(5) {
  width: auto;
}

#wrap table.table-partner tr td:nth-child(6) {
  width: auto;
}

#wrap table.table-dependent tr td:nth-child(1) {
  width: auto;
}

#wrap table.table-dependent tr td:nth-child(2) {
  width: auto;
}

#wrap table.table-dependent tr td:nth-child(3) {
  width: auto;
}

#wrap table.table-dependent tr td:nth-child(4) {
  width: 30%;
}

#wrap table.table-dependent tr td:nth-child(5) {
  width: auto;
}

/*
#wrap.template-oshc table.imf tr td:nth-child(1){
    width: 30%;
}

#wrap.template-oshc table.imf tr td:nth-child(2){
    width: 20%;
}

#wrap.template-oshc table.imf tr td:nth-child(3){
    width: 20%;
}

#wrap.template-oshc table.imf tr td:nth-child(4){
    width: 15%;
}

#wrap.template-oshc table.imf tr td:nth-child(5){
    width: 15%;
}
*/

.page #content:after {
  content: "";
  display: block;
  clear: both;
}

#wrap table td.center,
#wrap table th {
  text-align: center;
}

#wrap .conetnt {
  margin-top: 20px;
  margin-bottom: 20px;
  clear: both;
}

#wrap .main-payment .mesage {
  background-color: #cc2127;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 33px 10px -35px #777777;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 20px;
}

#wrap .wrap-payment {
  border: 1px solid #f54989;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

#wrap .wrap-payment .title-pay-main {
  position: absolute;
  top: -32px;
  height: 40px;
  background: #fff;
  line-height: 40px;
  left: 20px;
  padding: 0px 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #f54989;
}

#wrap .payment-item .wrap-main-payment {
  padding: 20px;
  box-sizing: border-box;
}

#wrap .payment-item .wrap-main-payment .title-credit-card,
#wrap .payment-item .wrap-main-payment .td_pay {
  width: 600px;
  margin-left: calc(50% - 310px);
  padding: 8px 8px;
}

#wrap .payment-item .wrap-main-payment .title-credit-card {
  background: #fff8dc;
  min-height: 80px;
}

#wrap .payment-item .wrap-main-payment .title-credit-card p {
  margin-top: 10px;
}

#wrap .payment-item .wrap-main-payment .img-credit-card {
  float: left;
  width: 80px;
}

#wrap .payment-item .wrap-main-payment .img-credit-card img {
  margin-left: 35px;
}

#wrap .payment-item .wrap-main-payment .td_pay {
  background: white;
}

#wrap .payment-item .wrap-main-payment .td_pay table .td_title {
  padding: 10px 8px 10px 35px;
}

#wrap div.cancel:after {
  clear: both;
  content: "";
  display: block;
}

#wrap .payment-cash .payment-item {
  background-color: #ddd;
  margin: 10px 0px;
}

#wrap .payment-cash .payment-item .title {
  margin: 0;
  padding: 10px;
  position: relative;
  background-color: #f54989;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  line-height: 1.1;
}

#wrap .payment-cash .payment-item .title i {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 15px;
  bottom: 0px;
  margin: auto;
  right: 10px;
  cursor: pointer;
}

#wrap .payment-cash .payment-item .wrap-main-payment form {
  /*text-align: center;*/
  clear: both;
}

#wrap .payment-cash .payment-item input {
  width: 100px;
  display: inline-block;
  float: none;
  box-shadow: 1px 5px 0px -2px #8c1d1d;
  border-radius: 20px;
  background: #f54989;
  padding: 5px 20px;
}

#wrap .payment-cash .payment-item input[type="submit"] {
  display: inline-block;
  float: none;
  box-shadow: 1px 5px 0px -2px #8c1d1d;
  border-radius: 20px;
  background: #f54989;
  padding: 10px 25px;
  width: auto;
}

#wrap .payment-cash .payment-item #icon-i {
  float: left;
  width: 100px;
  height: 108px;
}

#wrap .payment-cash .payment-item .imf {
  float: left;
  width: calc(100% - 120px);
  margin-left: 5px;
}

#wrap .payment-cash .payment-item .wrap-imf:after {
  content: "";
  display: block;
  clear: both;
}

.page #service-suport {
  margin-bottom: 0;
}

#wrap.template-oshc .button,
#wrap.template-ovhc .button {
  margin-bottom: 30px;
}

/*
#wrap.template-ovhc table.imf tr td:nth-child(2),#wrap.template-ovhc table.imf tr td:nth-child(3){
	text-align: center;
}

#wrap.template-oshc table.imf tr td:nth-child(1){
    width: 25%;
}

#wrap.template-oshc table.imf tr td:nth-child(2){
    width: 15%;
}

#wrap.template-oshc table.imf tr td:nth-child(3){
    width: 15%;
}

#wrap.template-oshc table.imf tr td:nth-child(4){
    width: 15%;
}

#wrap.template-oshc table.imf tr td:nth-child(5){
    width: 15%;
}

#wrap.template-oshc table.imf tr td:nth-child(6){
    width: 15%;
}
*/

.single .nz .excerpt_countrys,
.page .nz .excerpt_countrys {
  background-color: #ffb05b;
}

#button-terms {
  cursor: pointer;
}

#button-terms:hover {
  color: #82730f;
}

#content-terms {
  padding: 20px;
  padding-bottom: 0px;
  background-color: #f2f2f2;
  position: relative;
  margin-top: 10px;
}

#content-terms:before {
  top: -19px;
  border: 10px solid transparent;
  border-bottom: 10px solid #f2f2f2;
  left: 40px;
  position: absolute;
  content: "";
}

#close_terms {
  display: block;
  font-size: 30px;
  text-align: center;
}

#close_terms i {
  background-color: #82730f;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  cursor: pointer;
  color: #fff;
}

#wrap.err {
  text-align: center;
}

.page-template-qh_page_register_request .content-wrap {
  text-align: center;
}

#wrap .field-item {
  float: left;
  width: 100%;
}

#wrap .field-item.full {
  width: 100% !important;
}

#wrap .field-item.left,
#wrap .field-item.div-left {
  float: left;
  width: 48%;
  clear: both;
}

#wrap .field-item.birthday .field-val,
#wrap .field-item.name .field-val {
}

#wrap .field-item.right,
#wrap .field-item.div-right {
  float: right;
  width: 48%;
}

#wrap .field-item .title {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0px;
}

#wrap .showtext p.label {
  float: left;
  width: 20%;
}

#wrap .showtext div.value {
  float: right;
  width: 78%;
  border: 1px solid #444;
  padding: 10px;
  box-sizing: border-box;
}

.wrap-accout {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  margin: 20px auto 20px auto;
  position: relative;
  box-shadow: 0px 0px 2px 0px #d8d8d8;
}

.wrap-accout table th,
.wrap-accout table td {
  color: #444;
  border: none;
}

.wrap-accout .left-imf {
  float: left;
  width: 240px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  /*                       background: -webkit-linear-gradient(right, #fff , #f2f2f2);  For Safari 5.1 to 6.0
                                              background: -o-linear-gradient(right, #fff , #f2f2f2);  For Opera 11.1 to 12.0
                                              background: -moz-linear-gradient(right, #fff , #f2f2f2);  For Firefox 3.6 to 15
                                              background: linear-gradient(right, #fff , #f2f2f2);  Standard syntax */
}

.wrap-accout .right-content {
  float: right;
  width: calc(100% - 10px);
  min-height: 450px;
}

.wrap-accout .right-content input,
.wrap-accout .right-content select,
.wrap-accout .right-content textarea {
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  margin: 0;
  width: 100%;
  /*    padding-left: 20px;*/
  box-sizing: border-box;
}

.wrap-accout .left-imf .display-name {
  display: block;
  text-align: center;
}

.wrap-accout .left-imf .display-name .email {
  font-size: 15px;
  font-size: 1.5rem;
  display: block;
}

.wrap-accout .left-imf .money {
  background-color: #cc2127;
  color: #fff;
  padding: 5px 10px;
}

.wrap-accout .left-imf .count {
  background-color: #f54989;
  color: #fff;
  padding: 5px 10px;
}

.wrap-accout .right-content .dispath a {
  background-color: #545454;
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  color: #fff;
  line-height: 1;
  border-radius: 15px;
  position: relative;
}

.wrap-accout .right-content .dispath a:hover {
  background-color: #757575;
}

.wrap-accout .right-content .dispath a.action:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  bottom: -26.4px;
  left: 20px;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-left: 1px solid #545454;
  border-top: 1px solid #545454;
  position: absolute;
  background-color: #fff;
}

/*phungxoa*/
/*.wrap-accout .right-content .dispath {
    border-bottom: 1px solid #545454;
    padding: 10px;
    text-align: center;
}*/

.wrap-accout .right-content .content-register-table table {
  width: 100%;
  margin: 20px 0px;
  font-size: 12px;
}

/*phungxoa*/
/*.wrap-accout .right-content .content-register-table {
    margin-top: 40px;
}
*/
.wrap-accout .right-content .content-register-table table th,
.wrap-accout .right-content .content-register-table table td,
.wrap-accout .right-content .content-register-table table tr {
  border: 1px solid #ddd !important;
  padding: 5px;
}

.wrap-accout .right-content .content-register-table tbody,
.wrap-accout .right-content .content-register-table thead,
.wrap-accout .right-content .content-register-table tfoot {
  border: 1px solid #ddd;
}

.wrap-accout .right-content #form-search {
  text-align: right;
  margin-top: 20px;
}

.wrap-accout .right-content .search-box {
  display: inline-block;
  width: 375px;
}

.wrap-accout .right-content .search-box:after {
  content: "";
  display: block;
  clear: both;
}

.wrap-accout .right-content .search-box input {
  float: left;
  height: 30px;
  line-height: 0px;
  padding: 5px;
}

.wrap-accout .right-content .search-box input[type="submit"] {
  width: 80px;
  line-height: 0;
  border: 0px;
  background: #ddd;
  color: #000;
  border: 1px solid #b3b7b7;
}

.wrap-accout .right-content .search-box input[type="search"] {
  width: calc(100% - 85px);
  margin-right: 5px;
}

.wrap-accout .right-content .tablenav:after {
  content: "";
  display: block;
  clear: both;
}

.wrap-accout .right-content .tablenav .alignleft,
.wrap-accout .right-content .tablenav .button {
  float: left;
  /*margin: 0px;
    margin-right: 10px;*/
}

.wrap-accout .right-content .button {
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.wrap-accout .right-content .button:hover {
  box-shadow: 1px 1px 2px -1px #777;
}

.wrap-accout .right-content .button:active {
  box-shadow: none;
}

.wrap-accout .right-content .tablenav .button,
.wrap-accout .right-content .tablenav select {
  height: 30px;
  padding: 5px;
  line-height: 0;
}

.login_agent .form-field:after {
  content: "";
  display: block;
  clear: both;
}

.login_agent .form-field {
  margin: 10px 0px;
}

.login_agent .form-field.left {
  clear: both;
  width: 49%;
}

.login_agent .form-field.right {
  width: 50%;
}

#wrap .login_agent .form-field.button input {
  display: inline-block;
  float: none;
  width: 150px;
}

.login_agent .form-field textarea {
  background-color: #e6e6e6;
  width: 60%;
  float: left;
  height: 150px;
}

#wrap .title-page,
#wrap .content {
  text-align: center;
}

#wrap .title-page {
  margin: 0px;
}

#wrap .content {
  margin-bottom: 30px;
}

#wrap .content-wrap .row-field:after {
  content: "";
  display: block;
  clear: both;
}

#wrap .content-wrap .row-field input {
  float: none;
  width: 100%;
}

#wrap .content-wrap .row-field input[type="checkbox"] {
  width: auto;
}

#wrap .content-wrap .row-field input[type="submit"] {
  padding: 5px 15px;
}

#wrap .content-wrap label {
  width: auto;
  float: none;
  font-weight: 100;
}

#wrap .wrap-login {
  display: inline-block;
  width: 280px;
  border: 1px solid #ddd;
  padding: 20px;
}

#menu-menu-login > li {
  position: relative;
}

#menu-menu-login .sub-menu {
  position: absolute;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  right: 10px;
  width: 250px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  box-shadow: 0px 5px 3px -5px #777;
}

/*
#menu-menu-login>li:hover .sub-menu {
    visibility: visible;
    opacity: 1;
}
*/

#menu-menu-login .sub-menu:before {
  content: "";
  border: 8px solid transparent;
  border-bottom: 8px solid #fff;
  height: 0px;
  width: 0px;
  top: -16px;
  right: 20%;
  position: absolute;
}

.row-field:after {
  content: "";
  display: block;
  clear: both;
}

.content-wrap .row-field {
  margin-bottom: 15px;
}

.content-wrap .row-field .err {
  color: #f00;
  font-style: italic;
  font-size: 13px;
  font-size: 1.3rem;
}

.content-wrap .row-field input {
  height: 40px;
  background-color: #f0f0f0;
  width: 100%;
}

.content-wrap .row-field input[type="checkbox"] {
  width: auto;
  height: auto;
}

.content-wrap .row-field input[type="submit"] {
  line-height: 1;
  padding: 6px 15px;
  height: auto;
}

.right-content .button-update {
  text-align: center;
}

.right-content .button-update .submit input {
  width: auto;
  display: inline-block;
  padding: 8px 10px;
}

.site-main .err {
  clear: right;
  color: #f00;
  display: block;
  text-align: right;
  font-style: italic;
  font-size: 13;
}

#wrap .field-item select {
  width: auto;
}

@media only screen and (max-width: 959px) {
  #wrap .field-item select {
    width: 100%;
  }
}

#price span {
  display: block;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid rgb(161, 137, 137);
}

.right-radio {
  float: left;
  width: 40%;
}

.wrap-accout .right-content select#country {
  width: auto;
}

.list-posts .post-message a:after {
  content: "";
  display: block;
  clear: both;
}

.list-posts .post-message:first-child {
  border-top: 1px solid #ddd;
}

.list-posts .post-message a {
  display: block;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  margin: 0;
  color: #000;
}

.list-posts .post-message .date-time {
  float: left;
  width: 25%;
}

.list-posts .post-message .title,
.list-posts .post-message .nt {
  display: inline-block;
}

.list-posts .post-message .nt {
  margin: 0 5px;
}

.list-posts .post-message .excerpt {
  display: inline-block;
  color: #777;
  white-space: nowrap;
}

.list-posts {
  margin-top: 20px;
}

.list-posts .post-message p {
  margin: 0;
}

.list-posts .post-message .title-content {
  float: left;
  width: 75%;
  overflow: hidden;
  white-space: nowrap;
}

.list-posts .post-message:hover,
.list-posts .post-message.viewed {
  background-color: #f2f2f2;
}

.list-posts .post-message.not-view {
  font-weight: bold;
}

.wrap-message-detail .post-message .title {
  border-bottom: 1px solid #ddd;
  margin: 0;
  font-size: 16px;
}

.wrap-message-detail .post-message .date-time {
  color: #777;
  font-size: 13px;
}

.wrap-message-detail .post-message .content {
  margin-top: 10px;
}

#wrap .mailing .wrap-input-field input {
  width: auto;
  float: left;
}

#wrap .mailing .wrap-field p {
  width: 25%;
  text-align: right;
  display: block;
}

#wrap .mailing .wrap-input-field input[type="text"] {
  clear: both;
  margin-top: 10px;
}

#wrap .mailing .item-radio:after {
  content: "";
  clear: both;
  display: block;
}

#wrap .mailing .item-radio label {
  float: left;
  text-align: left;
  line-height: 1;
}

/* Update 20200624 */

.full-content {
  width: 100% !important;
}

@media only screen and (min-width: 768px) {
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_oshc
    > table
    > tbody
    > tr
    > td:nth-child(3),
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_oshc
    > table
    > tbody
    > tr
    > td:nth-child(4) {
    margin: 0 auto;
  }

  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_oshc
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    margin: 0 auto;
  }

  a.a_policy:before,
  a.a_card:before,
  a.a_guideline:before {
    height: 35px;
  }

  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_ovhc
    > table
    > tbody
    > tr
    > td:nth-child(2),
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_ovhc
    > table
    > tbody
    > tr
    > td:nth-child(3),
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_ovhc
    > table
    > tbody
    > tr
    > td:nth-child(4) {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  #wrap .payment-item .wrap-credit-card {
    padding: 0px;
  }

  #wrap .payment-item .wrap-main-payment .title-credit-card,
  #wrap .payment-item .wrap-main-payment .td_pay {
    width: calc(100% - 16px);
    margin-left: 0px;
    padding: 8px 8px;
  }

  #wrap .payment-item .wrap-main-payment .img-credit-card {
    float: none;
    margin-left: calc(50% - 37px);
  }

  #wrap .payment-item .wrap-main-payment .img-credit-card img {
    margin-left: 0px;
  }

  #wrap .payment-cash .payment-item #icon-i {
    float: none;
    margin-left: calc(50% - 50px);
  }

  #wrap .payment-cash .payment-item .imf {
    width: calc(100%);
  }
}

@media only screen and (max-width: 579px) {
  #wrap .payment-item .wrap-main-payment .td_pay table td {
    padding: 0px;
    display: block;
  }

  #wrap .payment-item .wrap-main-payment .td_pay table .td_title {
    padding: 10px 8px 0px 0px;
  }
}

@media screen and (max-width: 480px) {
  #options_td {
    padding-right: 8px;
    width: auto;
  }

  #wrap .main-quote table td#options_td {
    border-right: none;
  }
}

@media only screen and (max-width: 399px) {
  #wrap .title-headding {
    font-size: 23px;
  }

  #wrap .button .button-cancel,
  #wrap .button input[type="submit"] {
    width: auto;
  }

  #wrap label {
    width: 100%;
    text-align: left;
  }

  #wrap .wrap-input-field,
  .right-radio {
    text-align: center;
    float: left;
    width: 100%;
  }
}

/*phung custom*/

.top-nav-sco {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 30px;
}

.nav-collapse-sco {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-collapse-sco li {
  list-style: none;
}

.nav-collapse-sco li a {
  padding: 3px !important;
  background: #ebebeb !important;
  height: 74px;
  box-sizing: border-box;
  display: table-cell !important;
  width: 129px;
  text-align: center;
  font-size: 13px;
  vertical-align: middle;
  border-radius: 5px !important;
  color: #333 !important;
  line-height: 16px !important;
}

.nav-collapse-sco li.active a {
  background: #d82165 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.flexslider-sco ul li img {
  width: 100%;
  max-height: 340px;
}

.main-slide-sco {
  position: relative;
}

.main-slide-sco .flex-direction-nav a::before {
  font-size: 20px;
  font-size: 2rem;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
}

.main-slide-sco .flex-direction-nav a {
  color: #fff;
  border: none;
  border-radius: 50%;
  background: #3333335c;
}

.main-slide-sco:hover .flex-prev {
  left: 15px;
  opacity: 0.7;
}

.main-slide-sco:hover .flex-next {
  opacity: 0.7;
  right: 15px;
}

.main-slide-sco:hover .flex-next:hover,
.main-slide-sco:hover .flex-prev:hover {
  opacity: 0.7;
}

.main-slide-sco .flex-control-nav {
  bottom: -10px;
}

.oshc-section {
  background: #fff;
  padding: 40px 0px;
}

.title-block-oshc span {
  border-bottom: 2px solid #e23675;
}

.oshc-section .list-input label {
  margin-bottom: 0px;
}

.oshc-section .list-input label select,
.oshc-section .list-input label input {
  margin-bottom: 0px;
}

.oshc-section .list-input label span {
  display: block;
}

.oshc-section .list-input label select {
  height: 31px;
  line-height: 31px;
  border: 1px solid #ccc;
}

.oshc-section .list-input label input {
  border: 1px solid #ccc;
}

.oshc-section .list-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0px;
}

button.btn-update-quote,
.btn-moreinfo {
  background: #fff;
  border: 1px solid #ccc;
  height: 31px;
  line-height: 31px;
  padding: 0px 30px;
}

.list-cart-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ovhc-section {
  padding-top: 0px;
}

.ovhc-section .list-cart-price {
  justify-content: space-around;
}

section#homestay {
  background: #fff;
  padding: 30px 0px;
}

.homestay-wrap {
  max-width: 660px;
  margin: 0 auto;
}

.homestay-wrap .panel-heading {
  padding: 0px;
}

.homestay-wrap .panel-title a {
  display: block;
  text-align: center;
  padding: 10px;
  background-color: #e02b6e;
  color: #fff;
}

.homestay-wrap .list-flag-national {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.homestay-wrap .content-text {
  margin-top: 20px;
}

#form-getstarted-now {
  background: #fff;
}

#form-getstarted-now .wrap-form {
  background-image: #fff;
  border: 1px solid #333;
  background-position: center;
  background-size: cover;
  padding: 25px 0px;
  text-align: center;
}

#form-getstarted-now .wrap-form .wpcf7-form {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 30px;
  box-sizing: border-box;
}

.logo-right {
  padding-right: 20px;
}

.logo-right img {
  margin-left: 20px;
}

.wrap-form p {
  font-style: italic;
}

.form-wrap-fgn .wpcf7 {
  background: unset;
  width: unset;
  border: unset;
}

.form-wrap-fgn .form-row select,
.form-wrap-fgn .form-row input,
.form-wrap-fgn .form-row .select2 .select2-selection {
  width: 100% !important;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.form-wrap-fgn
  .form-row
  .select2
  .select2-selection
  .select2-selection__rendered {
  line-height: 40px;
}

.form-wrap-fgn .form-row .select2 .select2-selection .select2-selection__arrow {
  height: 40px;
}

.form-wrap-fgn .form-row .col-md-4 {
  box-sizing: border-box;
}

.form-wrap-fgn #submit {
  display: block;
  height: 37px;
  line-height: 37px;
  padding: unset;
  width: 140px;
  background: #4294d3;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.list-cart-price-bank {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-cart-price-bank table {
  margin-right: 20px !important;
}

.list-cart-price-bank table:last-child {
  margin-right: 0px;
}

table.tbn-ph {
  width: auto !important;
}

table.tbn-ph tbody {
  border: unset !important;
}

.content-register-table-phung .list-cart-price-bank table.tbn-ph tr {
  display: flex;
  flex-direction: column;
  border: unset !important;
  padding: unset !important;
}

.content-register-table-phung .list-cart-price-bank table.tbn-ph tr td {
  border: unset !important;
  box-sizing: border-box;
}

.list-cart-price-bank table tr td img.img_logo {
  width: 200px;
  height: 120px;
  border-radius: unset;
}

.list-cart-price-bank table.tbn-ph tr td:nth-child(1) {
  border: 1px solid #ccc !important;
  border-radius: 3px 3px 0px 0px;
  padding: 10px;
  text-align: center;
}

.list-cart-price-bank table.tbn-ph tr td:nth-child(3) {
  border: 1px solid #ccc !important;
  border-top: unset !important;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}

.list-cart-price-bank table tr td:nth-child(4) {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.list-cart-price-bank table tr td .button1 .btn {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  display: block;
  background: #fff;
  width: 100%;
  border: 1px solid #ccc;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
}

#form-getstarted-now .form-row {
  margin-bottom: 10px;
}

.list-input-ovhc p {
  width: 155px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.list-input-ovhc p select,
.list-input-ovhc p input {
  width: 100%;
  height: 31px;
  line-height: 31px;
  border: 1px solid #ccc;
}

.list-input-ovhc p .btn-moreinfo {
  display: block;
  border-radius: 3px;
  font-weight: 500;
  text-transform: initial;
  text-align: center;
}

.list-cart-price .cart-item table tr td {
}

.list-cart-price .cart-item table tr td:nth-child(2) {
  margin: 10px 0px;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}

.list-cart-price .cart-item table.tbn-ph tr td:nth-child(3) {
  border: unset !important;
}

.form-wrap-fgn #submit {
  margin: 0 auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .top-nav-sco .container,
  .content-register-table-phung .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .nav-collapse-sco {
    display: block;
  }

  .nav-collapse-sco li {
    width: 50%;
    float: left;
    margin-bottom: 10px;
  }

  .nav-collapse-sco li a {
    width: 170px;
  }

  .oshc-section .list-input {
    display: block;
  }

  .oshc-section .list-input label,
  .oshc-section .list-input label select,
  .oshc-section .list-input label input {
    width: 100%;
  }

  button.btn-update-quote,
  .btn-moreinfo {
    width: 100%;
    display: block;
    margin-top: 10px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .list-cart-price-bank {
    display: block;
  }

  .list-cart-price-bank table {
    width: 47% !important;
    float: left;
  }

  .cart-item table {
    width: 100% !important;
    float: left;
  }

  .list-cart-price-bank table:nth-child(even) {
    margin-right: 0px !important;
  }

  .list-input-ovhc p {
    width: 100%;
  }

  .list-cart-price-bank .cart-item {
    overflow: hidden;
    width: 47%;
    float: left;
    margin-right: 20px;
  }

  .list-cart-price-bank .cart-item:nth-child(even) {
    margin-right: 0px;
  }

  .list-cart-price-bank .cart-item table {
    width: 100%;
  }

  .item-national-col .img-flag img {
    max-width: 100%;
  }

  .list-cart-price-bank table tr td img.img_logo {
    width: 100%;
    height: 80px;
    border-radius: unset;
  }

  .form-wrap-fgn .form-row select,
  .form-wrap-fgn .form-row input,
  .form-wrap-fgn .form-row .select2 .select2-selection {
    margin-bottom: 10px;
  }

  .list-cart-price-bank table tr td:nth-child(4) {
    margin-top: 0px;
    padding: 0px !important;
  }

  .list-cart-price-bank table tr td:nth-child(4) input {
    border-radius: 0px;
    border-top: 0px !important;
  }

  .list-cart-price .cart-item table tr td:nth-child(2) {
    margin: 0px 0px;
  }
}

/* Edit BY Bach*/

@media (max-width: 375px) {
  .nav-collapse-sco li a {
    width: 150px;
  }

  .wrap-accout .right-content .search-box {
    width: 325px;
  }
}

#requestModal .wpcf7,
#visaStatusModal .wpcf7 {
  width: auto;
  background: #ffffff;
  border: none;
}

#fillTheFormModal .wpcf7,
#fillTheFormModal-2 .wpcf7 {
  width: auto;
  border: none;
}

#fillTheFormModal label,
#fillTheFormModal-2 label {
  width: auto;
  text-align: left;
}

#fillTheFormModal input,
#fillTheFormModal select,
#fillTheFormModal-2 input,
#fillTheFormModal-2 select {
  border: 1px solid;
  border-color: #cccccc;
}

#fillTheFormModal input[type="checkbox"],
#fillTheFormModal input[type="submit"],
#fillTheFormModal input[type="radio"],
#fillTheFormModal-2 input[type="checkbox"],
#fillTheFormModal-2 input[type="submit"],
#fillTheFormModal-2 input[type="radio"] {
  width: auto !important;
}

#fillTheFormModal input[type="checkbox"],
#fillTheFormModal input[type="tel"],
#fillTheFormModal input[type="radio"],
#fillTheFormModal input[type="text"],
#fillTheFormModal input[type="date"],
#fillTheFormModal-2 input[type="checkbox"],
#fillTheFormModal-2 input[type="tel"],
#fillTheFormModal-2 input[type="radio"],
#fillTheFormModal-2 input[type="text"],
#fillTheFormModal-2 input[type="date"] {
  border-radius: unset !important;
  box-shadow: none !important;
}

#fillTheFormModal input[type="text"],
#fillTheFormModal input[type="date"],
#fillTheFormModal input[type="tel"],
#fillTheFormModal-2 input[type="text"],
#fillTheFormModal-2 input[type="tel"],
#fillTheFormModal-2 input[type="date"] {
  width: -webkit-fill-available !important;
  background: white !important;
}

#fillTheFormModal .wpcf7-response-output,
#fillTheFormModal-2 .wpcf7-response-output {
  font-size: 16px;
}

#fillTheFormModal input[type="submit"],
#fillTheFormModal-2 input[type="submit"] {
  color: #fff;
  display: inline-block;
  float: none;
  box-shadow: 1px 5px 0px -2px #8c1d1d;
  border-radius: 20px;
  background: #f54989;
  padding: 10px 25px;
  margin-left: calc(50% - 74px);
  line-height: normal;
}

#expiration-month,
#expiration-month-2 {
  display: inline;
}

#expiration-year,
#expiration-year-2 {
  display: inline;
  margin-left: 10px !important;
}

.wpcf7-special-radio-container .wpcf7-list-item-label {
  display: none;
}

.wpcf7-special-radio {
  display: none !important;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.special_radio_card_0 {
  background-image: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/MasterCard.png);
}

.special_radio_card_1 {
  background-image: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/Visa.png);
}

.special_radio_card_2 {
  background-image: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/AmericanExpress.png);
}

.wpcf7-special-radio:active + .drinkcard-cc {
  opacity: 0.9;
}

.wpcf7-special-radio:checked + .drinkcard-cc {
  border: solid;
  border-color: #f54989;
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.drinkcard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100px !important;
  height: 70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
  filter: brightness(1.8) grayscale(1) opacity(0.7);
}

.drinkcard-cc:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}

#requestModal .wpcf7-form,
#visaStatusModal .wpcf7-form {
  margin-left: auto;

  margin-right: auto;
}

#requestModal .wpcf7-form {
  margin-top: 75px;
}

#requestModal input[type="text"],
#requestModal input[type="date"],
#requestModal input[type="password"],
#requestModal textarea,
#requestModal select,
#requestModal input[type="email"],
#requestModal input[type="tel"],
#visaStatusModal input[type="text"],
#visaStatusModal input[type="date"],
#visaStatusModal input[type="password"],
#visaStatusModal textarea,
#visaStatusModal select,
#visaStatusModal input[type="email"],
#visaStatusModal input[type="tel"] {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #7e7e7e;
  border-width: 1px;
  border-style: solid;
  padding: 0 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border linear 0.3s;
  -webkit-transition: border linear 0.3s;
  -o-transition: border linear 0.3s;
  transition: border linear 0.3s;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 5px;
  background-color: #f7f7f7;
  height: 40px;
}

#requestModal input[type="date"],
#visaStatusModal input[type="date"] {
  height: auto;
  padding-right: 0px;
}

#requestModal input[type="file"] {
  width: auto;
  display: inline;
}

#visaStatusModal input[type="file"] {
  width: 235px;
  display: inline;
}

#visaStatusModal span.wpcf7-list-item {
  width: 50%;
  display: flex;
}

#visaStatusModal span.wpcf7-radio {
  display: flex;
}

#requestModal input[type="submit"],
#visaStatusModal input[type="submit"] {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 30px;
  background: #4294d3;
  color: #fff;
  border-radius: 30px;
  padding: 18px 20px;
  height: 60px;
  text-transform: uppercase;
  width: auto;
}

#requestModal .wpcf7-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

@media (min-width: 1200px) {
  .container_menu_accout {
    width: 970px;
    margin: auto;
  }
}

#date-of-birth,
#conditional-visa-8501 {
  display: none;
}

#date-of-birth.active,
#conditional-visa-8501.active {
  display: block;
}

.oshc_agent_receive_email {
  /*display: none;*/
}

#cover-spin,
.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  display: none;
}

.cover-spin.abs {
  position: absolute;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after,
.cover-spin:after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

#cover-spin .spin-message {
  position: absolute;
  top: 46%;
  right: 0;
  left: 0;
  text-align: center;
}

#cover-spin .spin-warning {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
  font-weight: bold;
}

#cover-spin .spin-warning svg {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
