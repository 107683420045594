.telegraphic-transfer {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 700px;
  max-width: 100%;
  border-radius: 1rem;

  & #icon-i {
    & img {
      width: 80px;
      height: 80px;
    }
  }

  & .link-btn {
    float: none;
    box-shadow: 1px 5px 0px -2px #8c1d1d;
    border-radius: 20px;
    background: #f54989;
    padding: 5px 20px;
    display: flex;
    width: fit-content;
    justify-content: center;
    color: #000;
    margin-right: 15px;
  }

  & .link-btn:hover {
    color: #fff;
  }

  & .telegraphic-transfer-action {
    display: flex;
    justify-content: center;
  }
}
