.wechat-return {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-items: center;
  padding: 50px 20px;
  background-color: white;
  height: 100vh;

  & img {
    width: 100px;
    margin: 0 auto;
  }

  & button {
    background-color: #f54989;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.1;
    padding: 10px;
    border: none;
    width: 150px;
    margin: 0 auto;
  }

  & p {
    margin-top: 2rem;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  && .logo {
    width: 120px;
  }
}