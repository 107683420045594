.customModal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  overflow: auto;

  & .successModal {
    & img {
      width: 100px;
      margin: 0 auto;
    }
  }

  & .errorModal {
    & .content {
      margin: 1rem 0;
    }
    & img {
      width: 100px;
      margin: 0 auto;
    }
  }


  & .actionBtn {
    background-color: #f54989;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.1;
    padding: 10px;
    border: none;
    width: 150px;
    margin: 0 auto;
  }

  & .cancelBtn {
    background-color: #f54989;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.1;
    padding: 10px;
    border: none;
    width: 150px;
    margin: 0 auto;
  }

  & p {
    padding: 1rem;
    font-size: 16px;
  }

  & .confirmModal {
    text-align: left;
    margin: 0 auto;
    width: fit-content;

    & .item {
      display: flex;
      width: fit-content;
      margin-bottom: 5px;

      & .title {
        font-weight: 700;
        width: 140px;
        display: inline-block;
        text-transform: capitalize;
      }

      & .content {
        max-width: 200px;
      }
    }
  }

  & .actionBlock{
    display: flex;
    gap: 1rem;
    & .actionBtn {
      box-shadow: 1px 5px 0px -2px #8c1d1d;
      border-radius: 20px;
      background: #f54989;
      padding: 10px 20px;
    }

    & .cancelBtn {
      background: #6c757d;
      box-shadow: 1px 5px 0px -2px #ccc;
      border-radius: 20px;
      padding: 10px 20px;

    }
  }
}
