.payment-link-at-bank {
  & .banks {
    display: flex;
    gap: 2rem;
    justify-content: center;
    justify-items: center;
    margin-bottom: 2rem;
    overflow: auto;

    & .bankTransfer-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      border: none;
      background: unset;

      & .radioButton {
        display: flex;
        margin-bottom: 1rem;
        width: 100%;
        & input[type="radio"] {
          width: unset !important;
          box-shadow: unset !important;
        }

        & label {
          width: fit-content !important;
        }
      }

      & img {
        width: 100%;
        height: 60px;
        max-width: 300px;
      }
    }

    & .bankTransfer-button:last-of-type {
      & img {
        width: inherit;
        height: inherit;
        max-width: inherit;
      }
      justify-content: space-between;
    }
    
    & .bankTransfer-button-selected {
      & img {
        border: 2px solid #0000CD;
        /* width: calc(100% - 4px); */
      }
    }
  }

  & .submit-btn {
    display: flex !important;
    margin: 0 auto !important;
    justify-content: center;
    width: 200px !important;
    margin-bottom: 10px !important;
  }

  & .submit-btn:hover {
    color: #fff;
  }
  
}
