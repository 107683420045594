@charset "utf-8";
/* CSS Reset
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
html {
  font-size: 62.5%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1rem 0;
}
table th,
table td {
  padding: 10px 8px;
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid #dddddd;
}
table th {
  color: #000000;
  border-bottom: none;
  font-weight: 600;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4d4d4d;
  font-weight: 600 !important;
  margin: 10px 0;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}
h1 {
  font-size: 28px;
} /* 28px */
h2 {
  font-size: 24px;
} /* 24px */
h3 {
  font-size: 21px;
} /* 21px */
h4 {
  font-size: 18px;
} /* 18px */
h5,
h6 {
  font-size: 15px;
} /* 16px */

p {
  margin-bottom: 10px;
}
p img {
  margin: 0;
}
p:empty {
  display: none;
}
em,
i {
  font-style: italic;
}
strong,
b {
  font-weight: bold;
}
small {
  font-size: 10px;
}

/*	Blockquotes  */
blockquote,
blockquote p {
  font-size: 14px;
  color: #4d4d4d;
}
blockquote p {
  margin: 0;
}
blockquote blockquote {
  font-size: 1rem;
}
blockquote {
  margin: 0 0 1.5rem;
  padding: 10px 15px;
  border-left: 3px solid #f99719;
  background: #cce4fc;
}
blockquote cite {
  display: block;
  font-size: 12px;
  color: #555;
  margin-top: 10px;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a,
blockquote cite a:visited,
blockquote cite a:visited {
  color: #555;
}

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 0px;
  height: 0;
}

/* #Links */
a {
  transition: color 50ms linear;
  -moz-transition: color 50ms linear;
  -webkit-transition: color 50ms linear;
  -o-transition: color 50ms linear;
}
a,
a:visited {
  text-decoration: none;
  outline: 0;
}
p a,
p a:visited {
  line-height: inherit;
}
a:focus,
a:hover {
  text-decoration: none !important;
}

/* #Lists */
ul,
ol {
  margin-bottom: 10px;
}
ol {
  list-style: decimal;
}
ul.square {
  list-style: square outside;
}
ul.circle {
  list-style: circle outside;
}
ul.disc {
  list-style: disc outside;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 10px 0 10px 30px;
}
ul ul li,
ul ol li,
li {
  margin-bottom: 0;
  list-style-position: inside;
}
.entry-content ul,
.entry-content ol {
  margin-left: 25px;
}
ul.error {
  margin: 10px 0;
  background: #ffd7d7;
  border: 1px solid #f93636;
  padding: 5px 10px;
  border-radius: 4px;
}
ul.message {
  margin: 10px 0;
  background: #a2f998;
  border: 1px solid #1cc109;
  padding: 5px 10px;
  border-radius: 4px;
}
/* Images */

img {
  height: auto;
  vertical-align: middle;
  border: 0;
}
iframe,
object,
embed {
  max-width: 100%;
}
.title-fgn {
  position: relative;
}
.title-fgn:before {
  position: absolute;
  content: "";
  width: 100px;
  height: 2px;
  background: #e23675;
  left: 50%;
  bottom: 0;
  transform: translateY(100%) translateX(-50%);
}
.left {
  float: left;
}
.right {
  float: right;
}
.entry-content h2 {
  font-size: 16px;
  font-size: 1.6rem;
}
.entry-content h3,
.entry-content h4 {
  font-size: 14px;
  font-size: 1.4rem;
}
p.center {
  text-align: center;
}
.alignright {
  float: right;
  margin: 5px 0px 5px 5px;
}

.alignleft {
  float: left;
  margin: 5px 5px 5px 0px;
}

.aligncenter {
  margin: 0 auto;
  display: block;
}
.wp-caption {
  text-align: center;
  padding-top: 5px;
  background: #f5f5f5;
  max-width: 100%;
}
.wp-caption-text {
  text-align: center;
}

.clear {
  clear: both;
}

/* Grid */
.columns .full {
  width: 100%;
  clear: both;
}
.columns .col-2 {
  float: left;
  width: 49%;
}
.columns .col-2:nth-child(2n) {
  float: right;
}
.columns .col-2.right {
  float: right;
}
.columns .col-3 {
  float: left;
  margin: 0 2% 2% 0;
  width: 32%;
}
.columns .col-4 {
  float: left;
  margin: 0 2% 2% 0;
  width: 23.5%;
}
.columns .col-5 {
  float: left;
  margin: 0 1.25% 1.25% 0;
  width: 19%;
}
.columns .col-6 {
  float: left;
  margin: 0 1.4% 1.4% 0;
  width: 15.5%;
}
.columns .col-7 {
  float: left;
  margin: 0 1.5% 1.5% 0;
  width: 13%;
}
.columns .col-8 {
  float: left;
  margin: 0 1% 1% 0;
  width: 11.5%;
}
.columns .col-3:nth-child(3n + 3),
.columns .col-4:nth-child(4n + 4),
.columns .col-5:nth-child(5n + 5),
.columns .col-6:nth-child(6n + 6),
.columns .col-7:nth-child(7n + 7),
.columns .col-8:nth-child(8n + 8) {
  margin-right: 0 !important;
}
.columns .col-3:nth-child(3n + 4),
.columns .col-4:nth-child(4n + 5),
.columns .col-5:nth-child(5n + 6),
.columns .col-6:nth-child(6n + 7),
.columns .col-7:nth-child(7n + 8),
.columns .col-8:nth-child(8n + 9) {
  clear: left;
}
.table {
  display: table;
  margin: auto;
  width: 100%;
}
.table .row {
  display: table-row;
}
.table .cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px;
}
.messages .mes {
  padding: 5px 10px;
  border-radius: 2px;
  background: #eee;
}
.messages .mes.success {
  color: #01c101;
  border: 1px solid #89b755;
  background: #f6ffec;
}
.messages .mes.error {
  color: #f00;
  border: 1px solid #f00;
}
/* Form */
form input[type="text"],
form input[type="number"],
form input[type="password"],
form textarea,
form select,
form input[type="email"],
form input[type="tel"] {
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  border: none;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 3px;
  font-size: 13px;
}
form#commentform input[type="text"],
form#commentform input[type="password"],
form#commentform textarea,
form#commentform select,
form#commentform input[type="email"] {
  clear: left;
  background: #f6f6f6;
  border: 1px solid #ccc;
  float: left;
}
form#commentform textarea {
  height: auto;
  width: 100%;
}
form input[type="text"]:focus,
form input[type="password"]:focus,
form textarea:focus,
form input[type="email"]:focus,
form select:focus {
}
form input[type="submit"] {
  background: -moz-linear-gradient(#6c6a6a, #545353);
  background: -webkit-linear-gradient(#6c6a6a, #545353);
  background: -o-linear-gradient(#6c6a6a, #545353);
  background: linear-gradient(#6c6a6a, #545353);
  border: 0;
  padding: 8px 10px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 3px;
}
#service-suport .slides li form a.benefit {
  padding: 7px 10px;
  font-size: 13px;
  font-size: 1.3rem;
}
form input[type="submit"]:hover {
  background: -moz-linear-gradient(#8c6a6a, #745353);
  background: -webkit-linear-gradient(#8c6a6a, #745353);
  background: -o-linear-gradient(#8c6a6a, #745353);
  background: linear-gradient(#8c6a6a, #745353);
}
form#commentform .comment-form-comment {
  width: 75%;
  margin-right: 5%;
  float: left;
}
form#commentform .form-submit {
  width: 20%;
  /*float:right;
	padding-top: 30px;*/
}
form#commentform input[type="submit"] {
  font-family: Arial, sans-serif;
  color: #b62e3b;
  border: 1px solid #ccc;
  font-size: 14px;
  text-transform: uppercase;
  background: none;
  font-weight: 600;
  transition: 0.4s all ease;
}
form#commentform input[type="submit"]:hover {
  color: #fff;
  border: 1px solid #b62e3b;
  background: #b62e3b;
}
form .medium {
  width: 50%;
}
form .large {
  width: 90%;
}

.logo_mobile {
  display: none;
}
/* Parallax */
.parallax {
  position: relative;
  overflow: hidden;
}
.parallax:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ddd;
  z-index: -2;
}
.parallax .layer_back {
  display: block;
  position: absolute;
  z-index: -1;
}
.parallax .layer_back img {
  display: block;
  height: auto;
  max-height: none;
  margin: auto;
  width: 100%;
}
.parallax .background_layer {
  height: 100%;
  overflow: hidden;
}
/* Body Start */
body {
  font-family: Arial, sans-serif;
  background: #efefef
    /*url('../defaultImages/bk.jpg') center top no-repeat fixed;*/ !important;
  background-size: cover;
  color: #333333;
  line-height: 1.8 !important;
  font-size: 14px;
  font-size: 1.4rem;
}
html,
body {
  min-width: 320px;
}
body.page-template-qh_page_accout {
  /*width: 1263px;*/
}
.fix-width {
  margin: auto;
}
.fix-width-study-tour {
  margin: auto;
}
.fix-width-other {
  margin: auto;
}
.no-padding {
  padding: 0px;
}

/*                                             Header
================================================================================ */
/* Header */
.branding {
}
#topmenu {
  background: #fff;
  border-bottom: 3px solid rgba(35, 141, 205, 0.5);
  font-size: 13px;
  font-size: 1.3rem;
}
#topmenu ul.account {
  list-style: none;
  float: right;
  margin: 0;
}
#topmenu ul.account > li {
  float: left;
  margin: 6px 0;
}
#topmenu ul.account > li > a {
  height: 24px;
  line-height: 24px;
  display: block;
  padding: 0 20px;
  color: #bdbab4;
  font-weight: bold;
}
#topmenu ul.account > li.active > a,
#topmenu ul.account > li:not(.language):hover > a {
  background: #ffd100;
  color: #535353;
}
#topmenu ul.account > li.search {
  padding-right: 15px;
  border-right: 1px solid #fecd41;
}
#topmenu ul.account > li.language {
  background: #fff;
}
#topmenu ul.account > li.language a:first-child {
}
#topmenu ul.account > li.language > a {
  background-position: 5px center;
  color: #999;
  font-weight: 600;
  padding: 0 15px 0 30px;
  width: auto !important;
  display: block;
  float: left;
  font-size: 13px;
}
#topmenu ul.account > li.language a.active,
#topmenu ul.account > li.language a:hover {
  color: #4d4d4d;
  background-color: #fff;
}
.top-menu {
  margin: 0;
  float: left;
}
.top-menu li {
  list-style: none;
  display: inline-block;
  float: left;
}
.top-menu li > a {
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  color: #a0a0a0;
  display: block;
}
.top-menu li:not(.socials) {
  height: 24px;
  line-height: 24px;
  margin-top: 5px;
  color: #4d4d4d;
}
.top-menu li:not(.socials) a {
  color: #4d4d4d;
}
.top-menu li:not(.socials) a:hover {
  color: #fecd41;
}
.top-menu li:not(.socials) .fa {
  color: #fecd41;
  margin-right: 5px;
}
.top-menu li.socials a {
  background: #fecd41;
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
  padding: 0;
  margin: 2px 7px 0 0;
  color: #fff;
  text-align: center;
  border: 1px #fecd41 solid;
}
.top-menu li > a:hover,
.top-menu li.current-menu-item > a {
  background: #fff;
  color: #fecd41;
}
.top-menu li ul {
  display: none;
}
/* Search */
#topmenu .search-form {
  border-radius: 2px;
  font-size: 0;
  border: 1px solid #fecd41;
  background: #fff;
  height: 22px;
  line-height: 22px;
}
#topmenu .search-form:hover,
.search-form.focus {
}
#topmenu .search-form input[type="text"] {
  background: transparent;
  width: 150px;
  display: inline-block;
  border: 0;
  padding: 0px 5px;
  height: 22px;
  margin: 0;
  line-height: 1.3;
  color: #4d4d4d;
}
#topmenu .search-form input[type="text"]:focus {
}
#topmenu .search-form button[type="submit"] {
  width: 22px;
  border: 0;
  height: 22px;
  line-height: 20px;
  cursor: pointer;
  background: transparent;
  color: #fecd41;
  float: right;
}
#topmenu .search-form button[type="submit"] .fa {
  font-size: 15px;
  font-size: 1.5rem;
}
#topmenu .search-form button[type="submit"]:hover {
  background: #fecd41;
  color: #fff;
}

.branding {
  background: #ebebeb url("../defaultImages/bg_head.png") no-repeat 28% -15px;
}
.branding .banner {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .branding {
    position: relative;
  }
}

#divbanner {
  position: fixed;
  z-index: 999999;
  top: 0px;
}

.divbackground {
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
}
.divcontentbanner {
  z-index: 999999;
  width: 1120px;
  height: 430px;
  top: calc(-205px + 50%);
  right: calc(-550px + 50%);
  position: fixed;
  background-color: #f54989;
}
.divheader {
  width: 96%;
  height: 35%;
  margin-left: 1%;
  margin-top: 1%;
  background-color: white;
  position: relative;
}

.imgheader {
  height: 80%;
  float: left;
  padding-top: 15px;
  padding-left: 30px;
}
.pheader {
  width: 80%;
  padding-left: 15%;
  padding-top: 10px;
  font-size: 34px;
  font-weight: bold;
  color: #f54989;
  text-align: center;
  line-height: 40px;
}

a.aheader {
  position: absolute;
  top: 15px;
  right: 15px;
}
.fa-close {
  color: #f54989;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 40px;
}
.fa-close:hover {
  color: #4d4d4d;
}
.divmain {
  width: 100%;
  height: 60%;
  text-align: center;
}
div.divmain > p:nth-child(1) {
  color: white;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 36px;
  font-weight: bold;
}
div.divmain > a {
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  font-size: 36px;
  font-weight: bold;
}
div.divmain > p:nth-child(3) {
  color: white;
  padding-top: 20px;
  padding-right: 100px;
  font-size: 22px;
}

.a_quote_ovhc {
  position: absolute;
  top: 260px;
  right: -100px;
}
.img_quote_ovhc {
  height: 200px;
}

.amain {
  color: #f54989;
  background-color: rgb(255, 217, 46);
}
.amain:hover {
  color: #f54989;
  background-color: rgba(238, 238, 238, 1);
}

.registry {
  text-align: center;
  position: fixed;
  top: 35%;
  z-index: 99999;
  left: 0px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
}
.registry a {
  display: table-cell;
  vertical-align: middle;
  width: 88px;
  height: 88px;
  background: #fce186;
  border-radius: 44px;
  color: #4d4d4d;
  padding: 0px;
  line-height: 1.3;
  font-weight: 800;
  transition: 0.4s all ease;
  font-size: 13px;
}
/*
.registry a{
	display: block;
	background: #fce186;
	border-radius: 0 0px 5px 5px;
	color:#4D4D4D;
	padding: 5px 10px;
	line-height: 1.3;
    font-weight: 800;
	transition: 0.4s all ease;
	font-size: 13px;
}
*/
.registry a:hover {
  color: #fff;
  background: #f54989;
}
#divAdRight {
  position: fixed;
  top: 35%;
  overflow: hidden;
  right: 0px;
  z-index: 3;
  width: 141px;
}
#divAdRight > a > img {
  width: 140px;
}
.AdRightfixed {
  display: none;
}
.logo-img {
  margin: 16px 0;
  display: inline-block;
  vertical-align: middle;
  width: 10%;
}

.logo-img .name {
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #f54989;
  display: inline-block;
  vertical-align: middle;
  font-size: 40px;
  margin-left: 10px;
}
.logo-img .name .small {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
}
.logo-img img {
  display: inline-block;
  vertical-align: middle;
}
.group {
  float: left;
  width: 86%;
}
.group .logo-img {
  width: 22%;
  float: left;
  margin: 0;
}
.group .logo-img img {
  width: 26%;
  display: inline-block;
  vertical-align: middle;
}
.group a .site-title,
.group a .site-title span {
  color: #fff;
  font-size: 18px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
.group a .site-title {
  width: 74%;
  display: inline-block;
  vertical-align: middle;
}
.group a .site-title span {
  font-family: Arial, sans-serif;
  font-weight: lighter;
}
.group #primary-menu {
  width: 85%;
  float: left;
}

.group #primary-menu ul li a {
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
}
.site-information {
  display: inline-block;
  vertical-align: middle;
  width: 69%;
}
.site-information .socials {
  width: 63%;
  float: left;
  position: relative;
  text-align: right;
  padding-right: 2%;
}
.site-information .socials a {
  background: #b1b1b1;
  color: #fff;
  border-radius: 5px;
  padding: 7px 9px;
  font-size: 15px;
  margin-left: 8px;
  transform: 0.4s all ease-in;
}
.site-information .socials a:hover {
  background: #ccc;
}
.site-information .socials:before {
  background: #d3d3d3 none repeat scroll 0 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 65%;
}
.site-information .socials a i {
  margin: auto;
  text-align: center;
}
.site-information .search {
  width: calc(18% - 1px);
  float: left;
  padding: 0 1.5%;
  border-left: 1px solid #d3d3d3;
}
.site-information .language {
  width: calc(12.5% - 1px);
  float: left;
  padding-left: 1.5%;
  border-left: 1px solid #d3d3d3;
}

.site-information .language select {
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  color: #999;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 30px;
  border: 1px #ddd solid;
  height: 30px;
  width: calc(100% - 2px);
  padding-left: 10px;
}
.site-information .search form input[type="text"] {
  border-radius: 5px 0 0 5px;
  float: left;
  width: calc(80% - 1px);
  font-size: 12px;
  color: #ccc;
  border: 1px #ddd solid;
  border-right: 0;
  margin-bottom: 0;
}
.site-information .search form button {
  border-radius: 0 5px 5px 0;
  float: left;
  width: calc(20% - 1px);
  background: #fff url("../defaultImages/icon_search.png") no-repeat center
    center;
  border: 1px #ddd solid;
  border-left: 0;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.site-title {
  margin: 0px 0;
  text-transform: uppercase;
}
.banner-re .site-title a {
  color: #f54989;
  transition: 0.3s all ease;
  font-size: 50px;
  font-size: 5rem;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  float: left;
  letter-spacing: 4px;
  line-height: 40px;
}
.banner-re .site-title a span {
  color: #f54989;
  transition: 0.3s all ease;
  font-size: 30px;
  font-size: 3rem;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  letter-spacing: 0;
}
.site-title a:hover {
}
.site-description {
  color: #f54989;
  text-align: right;
  font-weight: 100;
  text-transform: uppercase;
  position: absolute;
  top: 52px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  left: 40px;
}

.banner-re {
  display: inline-block;
  vertical-align: middle;
  width: 20%;
}
/* MENU */
#primary-menu {
  width: 80%;
}
#primary-menu ul {
  list-style: none;
  margin: 0;
}
#primary-menu li {
  margin: 0;
}
#primary-menu a {
  color: #4d4d4d;
}
nav#primary-menu ul li {
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  font-size: 1.6rem;
}
nav#primary-menu > ul > li {
  float: left;
}
nav#primary-menu > ul > li > a {
  display: inline-block;
  text-decoration: none;
  height: 55px;
  line-height: 52px;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
  color: #fff;
  font-family: Arial, sans-serif;
  padding: 0 10px;
  font-weight: bold;
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  position: relative;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: color;
  vertical-align: middle;
}
.group nav#primary-menu > ul > li > a {
  padding: 0 11px;
}

nav#primary-menu > ul > li > a:before {
  background: #ebebeb none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleY(0);
  transform-origin: 50% 0 0;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  z-index: -1;
}
nav#primary-menu > ul > li > a:hover,
nav#primary-menu > ul > li > a:focus,
nav#primary-menu > ul > li > a:active,
nav#primary-menu > ul > li.current-menu-ancestor > a {
  color: #f54989;
}
nav#primary-menu > ul > li > a:hover::before,
nav#primary-menu > ul > li > a:focus::before,
nav#primary-menu > ul > li > a:active::before,
nav#primary-menu > ul > li.current-menu-ancestor > a::before {
  transform: scaleY(1);
}

nav#primary-menu > ul > li:hover > a,
nav#primary-menu > ul > li.current_page_item > a,
nav#primary-menu > ul > li.current-menu-ancestor > a,
nav#primary-menu > ul > li.current-menu-item > a,
nav#primary-menu > ul > li.current-menu-parent > a {
  color: #f54989;
  background: #ebebeb;
}
nav#primary-menu > ul > li:last-child > a {
  border-right: 0;
}
/* Submenus */
nav#primary-menu ul ul {
  background: #fff;
  min-width: 180px;
  display: none;
  position: absolute;
  top: 100%;
  z-index: 10;
  margin: 0;
}
nav#primary-menu li:hover > ul {
  display: block;
}
#primary-menu ul ul li {
  margin: 0;
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 5px 0;
}
nav#primary-menu ul ul li:hover,
nav#primary-menu ul ul li.current_page_item,
nav#primary-menu ul ul li.current-menu-ancestor,
nav#primary-menu ul ul li.current-menu-item,
nav#primary-menu ul ul li.current-menu-parent {
  background: #fecd41;
}
nav#primary-menu ul ul li:last-child {
  border-bottom: 0;
}
nav#primary-menu ul ul li a {
  padding: 0px 15px;
  display: block;
  text-decoration: none;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  color: #4d4d4d;
}
nav#primary-menu ul ul ul {
  padding-top: 0px;
  left: 98%;
  top: 0;
}
.menuright {
  position: absolute;
  top: 0;
  right: 0;
}
#login-menu {
  float: right;
}
#login-menu ul {
  list-style: none;
  margin: 0;
}
.span_login:before {
  content: "\f007";
  font-family: "FontAwesome";
  color: #f54989;
  margin-right: 5px;
  font-size: 15px;
}
#menu-menu-login > li {
  position: relative;
}

#menu-menu-login .sub-menu {
  position: absolute;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  right: 10px;
  width: 250px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  box-shadow: 0px 5px 3px -5px #777;
}

#menu-menu-login .sub-menu:before {
  content: "";
  border: 8px solid transparent;
  border-bottom: 8px solid #fff;
  height: 0px;
  width: 0px;
  top: -16px;
  right: 20%;
  position: absolute;
}
aside.qtranxs_widget h3 {
  display: none;
}
aside.qtranxs_widget ul li.active {
  display: none;
}
aside.qtranxs_widget ul li a {
  color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-left: 5px;
  font-family: 11px;
  text-transform: uppercase;
  line-height: 52px;
  font-weight: bold;
}
aside.qtranxs_widget ul li {
  float: left;
  margin: 0;
}
#login-menu > ul > li {
  text-align: right;
}
#login-menu > ul > li > span {
  background: #fce186;
  color: #4d4d4d;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  line-height: 52px;
  cursor: pointer;
}
#login-menu > ul > li > span:after {
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #f54989;
  margin-left: 15px;
  font-size: 12px;
}

#login-menu > ul > li > a {
  background: #fce186;
  color: #4d4d4d;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  line-height: 52px;
  cursor: pointer;
  margin-right: 5px;
}
#login-menu > ul > li > a:after {
  color: #f54989;
  margin-left: 15px;
  font-size: 18px;
}
/* Submenus */
#login-menu ul ul {
  background: #fff;
  min-width: 180px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  margin: 0;
}
#login-menu ul ul li a {
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: #4d4d4d;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  transition: 0.4s all ease;
}
#login-menu ul ul li a:hover {
  background: #999;
  color: #fff;
}
#login-menu ul ul li:last-child a {
  border-bottom: none;
}
#login-menu li:hover > ul {
  display: block;
}
/*SLIDER*/
#ht-slider .flexslider {
  background: none;
  position: relative;
  border: none !important;
  border-radius: 0 !important;
}
#ht-slider .text {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.35);
  padding: 25px 0;
}
#ht-slider .text .fix-width span.first {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  text-transform: uppercase;
  padding-right: 15px;
}
#ht-slider .text .fix-width span.excerpt {
  font-family: Arial, sans-serif;
  font-weight: lighter;
  font-size: 28px;
  color: #fff;
  text-transform: uppercase;
  padding-left: 15px;
  border-left: 1px solid #fff;
}
#ht-slider .main-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}
#slider.nivoSlider {
  margin-bottom: 0;
  width: auto;
  box-shadow: none;
  overflow: visible;
}
#ht-slider .theme-default .nivoSlider a {
  top: calc(50% - 23px);
}
/* MAIN */
#container {
  padding-top: 25px;
  border-bottom: 0px;
  background: #fff;
}

/* BREADCRUMS */
#crumbs {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 10px;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}
#crumbs .delimiter {
  margin: 0 5px;
}
#crumbs {
  font-size: 24px;
  font-size: 2.4rem;
  color: #fff;
}
#crumbs a {
  color: #ff0000;
}
#crumbs .breadcrumb > span {
  padding: 0 5px;
}
/* Home */
#horiz_container_outer .service-category {
}
#horiz_container_outer .service-category .wap {
  position: relative;
  margin: 0 30px;
}
#horiz_container_outer .service-category .excerpt {
  position: absolute;
  /*background: #F54989;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(1, 0, 0, 0.3);
  transition: all 2s;
  opacity: 0;
  z-index: 9;
  border: #fff solid 9px;
  padding: 20px;
}
#horiz_container_outer .service-category .excerpt .row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  position: relative;
  padding-left: 30px;
}
#horiz_container_outer .service-category .excerpt .row:last-child {
  border-bottom: none;
}
/*#horiz_container_outer .service-category .excerpt .row:nth-child(1){
    background: url('../defaultImages/s1.png') no-repeat center left;
}
#horiz_container_outer .service-category .excerpt .row:nth-child(2){
    background: url('../defaultImages/s2.png') no-repeat center left;
}
#horiz_container_outer .service-category .excerpt .row:nth-child(3){
    background: url('../defaultImages/s3.png') no-repeat center left;
}
#horiz_container_outer .service-category .excerpt .row:nth-child(4){
    background: url('../defaultImages/s5.png') no-repeat center left;
}
#horiz_container_outer .service-category .excerpt .row:nth-child(5){
    background: url('../defaultImages/s4.png') no-repeat center left;
}
#horiz_container_outer .service-category .excerpt .row:nth-child(6){
    background: url('../defaultImages/s6.png') no-repeat center left;
}
*/
#horiz_container_outer .service-category .excerpt .title {
  color: #fff;
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: 65%;
  float: left;
}
#horiz_container_outer .service-category .excerpt .quote {
  background: #6a6a6a;
  color: #fff;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 10px;
  padding: 3px 3px;
  border-radius: 5px;
  float: right;
  text-transform: uppercase;
  transition: 0.4s all ease;
}
#horiz_container_outer .service-category .excerpt .quote:hover {
  background: #fff;
  color: #f54989;
}
#horiz_container_outer .service-category:hover .excerpt {
  opacity: 1;
}
#horiz_container_outer .entry-title {
  text-align: center;
}
#horiz_container_outer .entry-title {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #4d4d4d;
  text-transform: uppercase;
}
#horiz_container_outer .service-category .thumb {
  background: #fff;
  padding: 9px;
  font-size: 0;
  display: block;
}
#horiz_container_outer .service-category .thumb img {
  height: 338px;
  width: 100%;
}
#horiz_container_inner .flexslider {
  background: #efefef;
  border: #efefef;
}
.home .fix-width.columns {
  padding-bottom: 1px;
  padding-top: 10px;
}
.section-title:after {
  background: #f54989;
  content: "";
  display: block;
  height: 4px;
  margin: auto;
  width: 58px;
}
.nz #help-suport .section-title:after,
.ca #help-suport .section-title:after,
.us #help-suport .section-title:after {
  background: #f54989;
}
.section-title {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  color: #4d4d4d;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.postid-427 .section-title,
.postid-1313 .section-title,
.postid-580 .section-title,
.postid-1722 .section-title,
.postid-228 .section-title {
  margin-top: 40px;
  margin-bottom: 40px;
}
#goto .section-title {
  text-transform: uppercase;
}
.event-news .section-title {
  margin-bottom: 30px;
  margin-top: 0px;
  font-size: 22px;
  font-size: 2.2rem;
}

h3.other-services-title::after {
  background: #f54989;
  content: "";
  display: block;
  height: 4px;
  width: 150px;
}
.other-services-video {
  float: right;
  padding-top: 70px;
  padding-bottom: 70px;
}
.inner_video {
  padding: 10px;
  background-color: #ffffff4a;
  border-radius: 5px;
  height: 320px;
}
.other-services {
  float: left;
  padding-bottom: 40px;
  /*width: 45%;
    margin-left: 20%;
 */
}
iframe.iframe_video {
  height: 300px;
  width: 450px;
}
h3.other-services-title {
  padding-left: 170px;
}
a.other-service:hover {
  color: #f54989;
}
a.other-service {
  padding-left: 70px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
a.other-service:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 48px;
  width: 100px;
  padding: 5px;
}
/* a.other-service-No1:before {
	background: url(images/MARKETING.png) no-repeat center center;
}
a.other-service-No1:hover:before {
	background: url(images/MARKETINGh.png) no-repeat center center;
}
a.other-service-No2:before {
	background: url(images/STUDYTOUR.png) no-repeat center center;
}
a.other-service-No2:hover:before {
	background: url(images/STUDYTOURh.png) no-repeat center center;
}
a.other-service-No3:before {
	background: url(images/DESIGN.png) no-repeat center center;
}
a.other-service-No3:hover:before {
	background: url(images/DESIGNh.png) no-repeat center center;
}
a.other-service-No4:before {
	background: url(images/CRM.png) no-repeat center center;
}
a.other-service-No4:hover:before {
	background: url(images/CRMh.png) no-repeat center center;
} */
#reviews .section-title:after {
  height: 0;
}
#reviews .section-title {
  color: #4d4d4d;
  margin-top: 0;
  margin-bottom: 20px;
}
.services .desc {
  color: #4d4d4d;
  text-align: center;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  width: 50%;
  margin: auto;
  margin-top: 20px;
}
.services .wrap-content {
  position: relative;
  padding: 10px 0 50px;
}
.services .listing-services {
  background: transparent;
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 20px 0 15px;
}
#services .listing-services .service {
  float: left;
  margin-right: 0px;
  width: calc((100% - (40px * 3)) / 4);
  margin-right: 40px;
  position: relative;
}
#services .listing-services .service:before {
  left: 10px;
  right: 10px;
  top: 50%;
  bottom: 50%;
  transition: all 0.5s ease-in-out 0s;
  content: "";
  display: block;
  position: absolute;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  width: auto;
  z-index: 1;
}
#services .listing-services .service:after {
  left: 50%;
  right: 50%;
  top: 10px;
  bottom: 10px;
  transition: all 0.5s ease-in-out 0s;
  content: "";
  display: block;
  position: absolute;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  z-index: 1;
  height: auto;
}
#services .listing-services .service:hover:before {
  top: 10px;
  bottom: 10px;
}
#services .listing-services .service:hover:after {
  left: 10px;
  right: 10px;
}
#services .listing-services .service .thumb {
  display: block;
  font-size: 0;
  position: relative;
  height: 100%;
}
#services .listing-services .service .thumb:before {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  content: "";
  height: 100%;
}
#services .listing-services .service .thumb:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
}
#services .listing-services .service:hover .thumb:after {
  visibility: visible;
  opacity: 1;
}
#services .listing-services .service .thumb img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}
#services .listing-services .service .entry-title {
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  position: absolute;
  left: 10px;
  width: calc(100% - 40px);
  bottom: 10px;
  margin: 0px;
  transition: all 0.5s ease-in-out 0s;
  z-index: 2;
}
#services .listing-services .service:hover .entry-title {
  bottom: 20px;
  left: 20px;
}
.list_show .apart {
  float: left;
  width: calc(100% / 3);
}
#services .listing-services .service .entry-title a {
  color: #fff;
  padding: 10px 10px;
  position: relative;
  display: inline-block;
  font-weight: bold;
}

#services .listing-services .service .entry-title a span:before {
  content: "\f105";
  font-size: 13px;
  font-size: 1.3rem;
  margin-right: 5px;
  font-weight: 100;
  display: inline-block;
  line-height: 1;
  float: left;
  margin-top: 1px;
}

#services .listing-services .service .entry-title a:before {
  content: "";
  width: 20px;
  top: 0px;
  bottom: 20px;
  position: absolute;
  left: calc(100% + 0px);
}

#services .listing-services .service:nth-child(2n + 1) .entry-title a,
#services .listing-services .service:nth-child(2n + 1) .entry-title a:before {
  background-color: rgba(35, 141, 205, 0.9);
}

#services .listing-services .service:nth-child(2n + 2) .entry-title a,
#services .listing-services .service:nth-child(2n + 2) .entry-title a:before {
  background-color: rgba(221, 26, 4, 0.9);
}
#services .listing-services .service .entry-title a:after {
  position: absolute;
  bottom: 0px;
  left: calc(100% + 0px);
  height: 0;
  width: 0;
  content: "";
  border: 20px solid transparent;
}

#services .listing-services .service:nth-child(2n + 1) .entry-title a:after {
  border-left: 20px solid #166799;
  border-top: 0px;
}

#services .listing-services .service:nth-child(2n + 2) .entry-title a:after {
  border-left: 20px solid #ba1907;
  border-top: 0px;
}

#services .listing-services .service .entry-title a:hover {
  text-decoration: underline;
}
#services .listing-services .service .excerpt {
  color: #777;
  margin-top: 5px;
  font-size: 13px;
  line-height: 1.5;
  text-align: justify;
  display: none;
}
#services .read-more {
  margin: 20px auto;
  text-align: center;
  display: none;
}
#services .read-more > a {
  background: #fff none repeat scroll 0 0;
  border-radius: 4px;
  color: #333;
  display: inline-block;
  padding: 7px 30px;
}
#services .read-more > a:hover {
  background: #2c96a1;
  color: #fff;
}
#services .wrap-content .flex-direction-nav a {
  color: #205b69;
  margin: 0;
}
#services .wrap-content:hover .flex-prev {
  opacity: 0.7;
  left: 5px;
}
#services .wrap-content:hover .flex-next {
  opacity: 0.7;
  right: 5px;
}
#services .wrap-content:hover .flex-next:hover,
#services .wrap-content:hover .flex-prev:hover {
  opacity: 0.7;
}
#services .flex-control-nav {
  bottom: 0;
}
#services .flex-control-paging li a {
  border-radius: 0;
  height: 15px;
  width: 15px;
  background: #a8d47d;
}
#services .flex-control-paging li a:hover,
#services .flex-control-paging li a.flex-active {
  background: #205b69;
}
.event-news {
  background: #fff;
  padding: 40px 0 40px;
  border: 8px solid #fff;
  border-left: none;
  border-right: none;
}
.event-news .section-title {
  text-align: left;
}
.event-news .section-title:after {
  margin-left: 0;
}
#events .listing-events {
}
#events .listing-events .entry,
.archive-events .event {
  position: relative;
}
#events .listing-events .old-events .entry,
.archive-events .event {
  width: 49.5%;
  margin-left: 1%;
  margin-top: 1%;
  float: left;
}

#events .listing-events .old-events .entry:nth-child(2n + 1),
.archive-events .event:nth-child(2n + 1) {
  clear: left;
  margin-left: 0;
}
#events .listing-events .entry .thumb,
.archive-events .event .thumb {
  display: block;
  overflow: hidden;
}
#events .listing-events .entry .thumb img,
.archive-events .event .thumb img {
  display: block;
  transition: 0.5s all ease-in 0s;
  width: 100%;
}
#events .listing-events > .entry:hover .thumb img,
.archive-events .event:hover .thumb img {
  transform: scale(1.05, 1.05);
}
#events .listing-events .entry .entry-meta,
.archive-events .event .entry-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  transition: 0.5s all ease-in-out;
  margin: 0;
  background: rgba(248, 155, 28, 0.9);
}
#events .listing-events > .entry .entry-meta,
.archive-events .event .entry-meta {
  min-height: 60px;
  background: rgba(248, 155, 28, 0.9);
}
#events .listing-events .entry:hover .entry-meta,
.archive-events .event:hover .entry-meta {
  background: rgba(248, 155, 28, 1);
}
#events .listing-events .datetime,
.archive-events .event .datetime {
  left: 0;
  position: absolute;
  margin: 0;
  font-size: 13px;
  font-size: 1.3rem;
  bottom: 100%;
  background: rgba(35, 141, 205, 0.9);
  padding: 0 5px;
}
#events .listing-events > .entry .datetime,
.archive-events .event .datetime {
  width: 80px;
  bottom: 0;
  text-align: center;
  top: 0;
  padding: 0;
}
#events .listing-events .old-events .entry .datetime .time:after {
  content: ",";
  display: inline-block;
  margin-right: 5px;
}
#events .listing-events > .entry .datetime .time,
.archive-events .event .datetime .time {
  padding: 0 5px;
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
  border-bottom: 1px solid #eee;
}
#events .listing-events .entry .entry-title,
.archive-events .event .entry-title {
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
  padding: 5px;
  font-size: 15px;
  font-size: 1.5rem;
}
#events .listing-events > .entry .entry-title,
.archive-events .event .entry-title {
  width: calc(100% - 100px);
  position: relative;
  left: 80px;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 10px;
  min-height: 40px;
}
#events .listing-events > .entry .entry-title:before,
.archive-events .event .entry-title:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  min-height: 40px;
}
#events .listing-events .entry .entry-title a,
.archive-events .event .entry-title a {
  color: #fff;
  transition: 0.3 all ease-in-out;
  display: inline-block;
  vertical-align: middle;
}
#events .listing-events .entry .entry-title a:hover,
.archive-events .event .entry-title a:hover {
  text-decoration: underline;
}
#events .listing-events .old-events .entry .entry-title a {
  font-weight: 400;
}
#events .listing-events .old-events .entry .excerpt-content:hover {
  background: rgba(44, 150, 161, 1);
}
#events .listing-events .entry .description,
.archive-events .event .description {
  line-height: 1.4;
  color: #4d4d4d;
}
#events .listing-events .old-events .entry .description {
  display: none;
}

.news .entry {
  margin-bottom: 10px;
}
.news .entry .thumb {
  width: calc(40% - 16px);
  border: 8px solid #fff;
  display: block;
  float: left;
  margin-right: 30px;
  height: 153px;
  overflow: hidden;
}
.news .entry .entry-meta {
  width: calc(60% - 30px);
  float: right;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 13px;
  color: #4d4d4d;
}
.news .entry .thumb img {
  display: block;
  height: 100%;
}
.news .entry .entry-title {
  line-height: 15px;
  margin: 0;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.news .entry .entry-title a {
  color: #f54989;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.news .entry .thumb:hover {
  border: 8px solid #fce186;
}
.news .entry .entry-title a:hover {
  text-decoration: underline;
}

#reviews {
  background: #e0ecec
    url("https://oshcstudents.com.au/wp-content/uploads/2016/01/Banner1.jpg")
    no-repeat center center;
  background-size: cover;
}

#click .background_layer {
  background: rgba(255, 255, 255, 0.3);
}
#reviews .background-opacity {
  background: rgba(255, 255, 255, 0.22);
}
#click .content {
  text-align: center;
  padding: 90px 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: #fff;
  text-transform: uppercase;
}
#click .content code {
  color: #f54989;
}
#click .content p {
  margin-bottom: 0;
}
#reviews .wrap-content {
  position: relative;
  padding: 50px 0 50px;
}
.btn_click {
  font-size: 18px;
  background: #f54989;
  padding: 7px 22px;
  color: #fff;
  border: 1px #f54989 solid;
  border-radius: 5px;
  transition: 0.4s all ease;
}
.single-service .btn_click {
  background: #f11313;
  border: 1px #f11313 solid;
}
.btn_click:hover {
  background: none;
  border-width: 3px;
}

#reviews .flexslider {
  border: none;
  background: transparent;
  margin: 0;
}
#reviews .flexslider .entry {
  text-align: justify;
  width: calc(100% - 60px);
  margin-right: 0;
  padding: 0 40px;
}
#reviews .flexslider .entry .content {
  font-size: 14px;
  font-size: 1.4rem;
  position: relative;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  color: #fff;
  float: right;
  width: calc(100% - 176px);
  padding-left: 30px;
  line-height: 25px;
  text-align: left;
}
#reviews .flex-viewport {
  padding: 50px 0px;
  background: #3333335c;
  width: 55%;
  border-radius: 10px;
}
#reviews .wrap-content .flex-direction-nav .flex-next {
  left: calc(55% - 50px);
}
#reviews .listing-reviews .review .thumb {
  background: #fff none repeat scroll 0 0;
  border-radius: 50%;
  display: block;
  float: left;
  height: 100px;
  overflow: hidden;
  padding: 8px;
  width: 100px;
  margin-left: 30px;
}
#reviews .listing-reviews .review .thumb img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
#reviews .listing-reviews .review .profile {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
  margin-top: 10px;
  padding-top: 10px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
}
#reviews .wrap-content .flex-direction-nav a::before {
  font-size: 20px;
  font-size: 2rem;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
}
#reviews .wrap-content .flex-direction-nav a {
  color: #fff;
  border: none;
  border-radius: 50%;
  background: #3333335c;
}
#reviews .wrap-content:hover .flex-prev {
  left: 15px;
  opacity: 0.7;
}
#reviews .wrap-content:hover .flex-next {
  opacity: 0.7;
  right: 15px;
}
#reviews .wrap-content:hover .flex-next:hover,
#reviews .wrap-content:hover .flex-prev:hover {
  opacity: 0.7;
}
#reviews .flex-control-nav {
  bottom: -10px;
}
.groupnote {
  background: #fff;
  padding: 10px 0 30px;
}
.home #service-suport::before {
  background: #efefef !important;
}
.howto {
  padding: 10px 0 0;
  font-size: 0;
}
.howto .text {
  border-bottom: none !important;
  font-size: 14px;
}
.steps {
  padding: 30px 0;
  background: #fff;
}
.steps .desc {
  color: #414141;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 24px;
  margin: 20px auto auto;
  text-align: center;
  width: 50%;
  line-height: 30px;
}
.steps .wap {
  float: left;
  width: calc(90% / 7);
  margin-right: calc(10% / 6);
}
.steps .step_bottom .wap {
  float: none !important;
  width: calc((100% - 60px) / 5);
  margin-right: 60px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 40px;
}
.steps .step_bottom .wap:last-child {
  margin-right: 0;
}
.steps .step_bottom {
  text-align: center;
}
.steps .wap .note {
  padding: 3px;
  background: #fff;
  border: 1px #ddd dashed;
  border-radius: 20px;
  text-align: center;
  position: relative;
}
.steps .wap .note:after {
  position: absolute;
  left: 50%;
  height: 40px;
  border-left: 1px #ccc dashed;
  top: 100%;
  content: "";
}
.steps .step_bottom .wap .note::after {
  top: -40px;
}
.steps .step_bottom .wap .oval {
  top: -19px;
}
.steps .wap .oval {
  position: relative;
  background: #fff;
  padding: 3px;
  border-radius: 50%;
  border: 1px dashed #ddd;
  width: 38px;
  height: 38px;
  margin: auto;
  top: 19px;
  z-index: 999;
}
.steps .wap .oval:before {
  content: "";
  position: absolute;
  /* background: #f4f4f4 url('../defaultImages/icon_truly.png') no-repeat center center; */
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 4px;
  left: 4px;
}
/* .steps .wap:nth-child(1) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_truly.png') no-repeat center center;
}
.steps .wap:nth-child(2) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_member.png') no-repeat center center;
}
.steps .wap:nth-child(3) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_sui.png') no-repeat center center;
}
.steps .wap:nth-child(4) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_sim.png') no-repeat center center;
}
.steps .wap:nth-child(5) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_bank.png') no-repeat center center;
}
.steps .wap:nth-child(6) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_car.png') no-repeat center center;
}
.steps .wap:nth-child(7) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_gra.png') no-repeat center center;
}
.steps .step_bottom .wap:nth-child(1) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_sach.png') no-repeat center center;
}
.steps .step_bottom .wap:nth-child(2) .oval:before{
    background: #f4f4f4 url('../defaultImages/icon_hai.png') no-repeat center center;
} */
.steps .wap:nth-child(7) {
  margin-right: 0;
}
.steps .wap a {
  display: block;
  background: #f5f5f5 url("../defaultImages/bg-row.png") repeat;
  padding: 0 25px;
  color: #616161;
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  border-radius: 20px;
  line-height: 22px;
  height: 90px;
  position: relative;
}
.steps .step_bottom .wap a {
  padding: 0 35px;
}
.steps .wap a p {
  margin: 30px 0;
  vertical-align: middle;
  display: inline-block;
}
.step_mid {
  background: #fce186;
  padding: 3px 0;
  position: relative;
  display: block;
  margin-top: 40px;
}
.step_mid .wapm {
  border-top: 1px dashed #fff;
  border-bottom: 1px dashed #fff;
  padding: 20px 0;
  display: block;
  background: #fce186;
}
.step_mid .pink {
  background: #de5676 url("../defaultImages/bg-row.png") repeat;
  float: left;
  margin-right: 2px;
  width: calc(25% + 18px);
  position: relative;
}
/*.step_mid .pink:after{
        content:'';
        height:0px; width:0px;
        border-left:26px solid #DE5676;
        border-top:26px solid transparent;
        border-bottom:26px solid transparent;
        float:left;
        position: absolute;
}*/
.step_mid .pink:nth-child(1) {
  text-align: right;
}
.step_mid .pink:nth-child(2) {
  width: calc(50% - 40px);
  text-align: center;
}
.step_mid .pink:nth-child(3) h3 {
  border-right: none;
  border-left: none;
}
.step_mid .pink:nth-child(2) h3 {
  border-right: none;
  border-left: none;
}
.step_mid .pink:nth-child(1) h3 {
  border-left: none;
  border-right: none;
}
.step_mid .pink:nth-child(1):after,
.step_mid .pink:nth-child(2):after {
  content: "";
  /* background: #fce186  url('../defaultImages/header_right.png') no-repeat right center; */
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
  height: 51px;
}
.step_mid .pink:nth-child(2):before,
.step_mid .pink:nth-child(3):before {
  content: "";
  /* background: #fce186  url('../defaultImages/header_left.png') no-repeat right center; */
  position: absolute;
  left: 0;
  top: 0;
  width: 27px;
  height: 51px;
}
.step_mid .pink:nth-child(3) {
  margin-right: 0;
  text-align: left;
}
.step_mid .pink h3 {
  border: 4px #fff solid;
  color: #fff;
  font-size: 24px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  margin: 0;
  text-transform: uppercase;
  padding: 0 50px;
}
.howto .note,
#help-suport .note {
  width: 10%;
  margin-right: 0%;
  text-align: center;
  position: relative;
  padding: 25px 20px;
}
.section-content {
  margin: auto;
  padding: 0 70px;
}
.steps .section-content {
  margin: auto;
  margin-top: 0px;
}
#template-guardian_service #help-suport .section-content {
  padding: 0px;

  margin: auto;
  margin-top: 50px;
}
.howto .note,
#help-suport .note {
  color: #4d4d4d;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.4s ease;
  background: #fff url("../defaultImages/bg-row.png") repeat;
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  display: block;
  position: relative;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: color;
}

.steps-0 {
  background: #fce186;
  padding: 22px;
  text-align: center;
}

.steps-0 .desc {
  font-size: 19px;
  font-size: 1.9rem;
  text-transform: uppercase;
  color: #333;
  width: 56%;
  margin: auto;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  line-height: 1.6;
}

.howto .note:before,
#help-suport .note:before {
  background: #f54989;
  border-radius: 5px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0);
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  z-index: -1;
}
.nz #help-suport .note:before,
.ca #help-suport .note:before,
.us #help-suport .note:before {
  background: #f54989;
}

#help-suport .note {
  /* background: #757575 url('../defaultImages/bg-row2.png') repeat; */
  border: medium none;
  color: #fff;
  font-size: 17px;
  font-size: 1.7rem;
  text-transform: uppercase;
}
.guardian {
  background: #f0f;
}
.wrap-map {
  position: relative;
  height: 300px;
  width: 100%;
}
.wrap-map .search-box {
  padding: 20px;
  background: #f54989;
  width: 20%;
  position: absolute;
  left: 20%;
  top: 40px;
}
#map {
  height: 100%;
}
.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#pac-input {
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.pac-container {
  font-family: Arial, sans-serif;
}

#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}

#type-selector label {
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 300;
}
.wrap-gruadian {
  background: #fff;
  padding: 70px 0;
}
.wrap-gruadian ul {
  list-style: none;
  margin-top: 50px;
}
.wrap-gruadian ul li {
  width: calc(48% - 45px);
  margin-right: 2%;
  padding-left: 45px;
  float: left;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  font-size: 18px;
  background: url("../defaultImages/icon_yes.png") no-repeat top left;
}
.wrap-gruadian ul li:nth-child(2n + 2) {
  margin-right: 0;
  margin-left: 2%;
}
.wrap-gruadian ul li:nth-child(2n + 1) {
  clear: left;
}
.howto .note:hover,
#help-suport .note:hover {
  color: #fff;
}
.howto .note:hover:before,
#help-suport .note:hover:before {
  transform: scale(1);
}
.howto .note:last-child,
#help-suport .note:last-child {
  margin-right: 0 !important;
}
.groupnote .section-content .haft,
/*#more-content-ovhc .section-content .haft{
    margin-top: 74px;
}*/

.groupnote .section-content .haft .note,
#more-content-ovhc .section-content .haft,
#more-content-ovhc .section-content .haft .note,
#more-content-oshc .section-content .haft,
#more-content-oshc .section-content .haft .note {
  /*width: calc((90% - 6px) / 5);
    float:left;
    padding: 0px 1%;*/
  margin-top: 0;
  position: relative;
  /*border-left:1px solid #ddd;*/
}
.groupnote .section-content .haft .note:last-child,
#more-content-ovhc .section-content .haft .note:last-child {
  /*border-right:1px solid #ddd;*/
}
.groupnote .section-content .haft .note a:before,
#more-content-ovhc .section-content .haft .note a:before,
#more-content-oshc .section-content .haft .note a:before {
  content: "";
  height: 64px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0px;
  width: 100%;
  transition: 0.7s ease all;
}
/* .groupnote .section-content .haft .note:nth-child(1) a:before{
    background: url('../defaultImages/why1.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(2) a:before{
    background: url('../defaultImages/why2.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(3) a:before{
    background: url('../defaultImages/why3.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(4) a:before{
    background: url('../defaultImages/why4.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(5) a:before{
    background: url('../defaultImages/why5.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(1) a:before,
#more-content-oshc .section-content .haft .note:nth-child(1) a:before{
    background: url('../defaultImages/visa4.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(2) a:before,
#more-content-oshc .section-content .haft .note:nth-child(2) a:before{
    background: url('../defaultImages/visa2.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(3) a:before,
#more-content-oshc .section-content .haft .note:nth-child(3) a:before{
    background: url('../defaultImages/visa3.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(4) a:before,
#more-content-oshc .section-content .haft .note:nth-child(4) a:before{
    background: url('../defaultImages/visa1.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(5) a:before,
#more-content-oshc .section-content .haft .note:nth-child(5) a:before{
    background: url('../defaultImages/visa5.png') no-repeat center center;
} */
.groupnote .section-content .haft .note h4,
#more-content-ovhc .section-content .haft .note h4,
#more-content-oshc .section-content .haft .note h4 {
  color: #4d4d4d;
  text-align: center;
  text-transform: uppercase;
  line-height: 17px;
  width: 68%;
  margin: auto;
  padding-top: 85px;
  font-size: 12px;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
.groupnote .section-content .haft .note:hover h4,
#more-content-ovhc .section-content .haft .note:hover h4,
#more-content-oshc .section-content .haft .note:hover h4 {
  color: #f54989;
}
.groupnote .section-content .haft .note:hover a:before,
#more-content-ovhc .section-content .haft .note:hover a:before,
#more-content-oshc .section-content .haft .note:hover a:before {
  transform: rotateY(360deg);
}
.btn_list {
  margin-bottom: 10px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 1.3rem;
  text-transform: uppercase;
  text-align: center;
}
.btn_list span {
  border-top: 1px solid #f54989;
  border-bottom: 1px solid #f54989;
  padding: 5px 0;
}
.list_show {
  background: #f2f2f2;
  padding: 40px 0;
  color: #4d4d4d;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  display: none;
}
.list_show h4.title {
  text-align: center;
}
#service-suport {
  padding: 10px 0;
  background: #ebebeb;
  position: relative;
  z-index: 1;
}
.postid-226 #service-suport {
  padding-bottom: 0;
}

.nz #service-suport {
  background: #90ed74;
}

.us #service-suport,
.ca #service-suport {
  background: #d0ea41;
}

#service-suport:before {
  position: absolute;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  z-index: -1;
}
#service-suport.guardian_service:before {
  top: 22%;
}
#service-suport .apply-now {
  text-align: center;
  padding: 40px 0 30px;
}
.guardian_service_submit {
  background: #f54989 !important;
  color: #fff;
  text-transform: uppercase;
  font-family: Arial, sans-serif !important;
  font-weight: bold !important;
  font-size: 16px;
  transition: 0.4s all ease;
}
.guardian_service_submit:hover {
  background: #787672 !important;
}

#service-suport.accommodation {
  padding-bottom: 0;
}
#service-suport.accommodation::before {
  /* top:22%;*/
}
#flex,
#accommodation {
  border: none;
  border-radius: 0;
  background: none;
  margin: auto;
}
#service-suport .section-title::after {
  /* height: 0;*/
}

#service-suport #help-suport .section-title::after {
  height: 4px;
}
#service-suport .section-title {
  margin: 55px 0 40px;
  color: #4d4d4d;
}
#help-suport {
  display: none;
}
/*#template-ovhc #service-suport .section-title{
	margin: 20px 0 35px;
}*/
.single .us #service-suport .section-title,
.single .ca #service-suport .section-title {
  color: #6e6e6e;
  text-transform: uppercase;
}
#template-guardian_service #service-suport .wrap-gruadian .section-title {
  margin: 20px 0 40px;
  color: #484848;
}
.nz #service-suport .section-title {
  color: #399c1c;
}
a#price_list {
  position: absolute;
  top: -70px;
  padding: 12px 32px;
  z-index: 9999;
  background-color: #3c8dd9;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 30px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  width: 200px;
  left: calc(50% - 100px);
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 2px;
}
/*.postid-81 a#price_list{
	margin-top: 10px;
	background-color: #F54989;
	top:85px;
}*/
.us #flex,
.ca #flex {
  position: relative;
}
#service-suport #flex .slides li,
#main #flex .slides li {
  margin-right: 0;
  text-align: center;
  float: left;
  display: block;
}
/*.postid-427 #main #flex .slides li{
	width:calc((100% - 2px)/4);
}*/
.home #accommodation .slides li {
  width: calc(90% / 3) !important;
  margin-right: 5% !important;
}
.home #accommodation .slides li:last-child {
  margin-right: 0 !important;
}
#accommodation .slides li {
  /* width: calc((100% - 2px)/3) !important;*/
  margin-right: 0 !important;

  opacity: 1 !important;
  text-align: center;
  float: left;
}
#accommodation1 .slides li {
  width: calc(100% / 2) !important;
  opacity: 1 !important;
  margin-right: 0 !important;
  text-align: center;
  float: left;
}
#accommodation .slides li a {
  display: block;
}
#accommodation1 .slides li a {
  display: block;
}

#service-suport .slides li a:hover,
#service-suport .slides li a.active {
  color: #f54989;
  position: relative;
}
#service-suport #accommodation1 .slides li a:hover,
#service-suport #accommodation1 .slides li a.active {
  color: #f54989;
  position: relative;
}
#service-suport #accommodation .slides li a::after {
  border-bottom: 10px solid #f0e59e;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: 100%;
  content: "";
  height: 0;
  left: calc(50% - 20px);
  right: 0;
  width: 0;
  position: absolute;
  transition: 0.4s all ease;
  opacity: 0;
}
#service-suport #accommodation1 .slides li a::after {
  border-bottom: 10px solid #f0e59e;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: 100%;
  content: "";
  height: 0;
  left: calc(50% - 20px);
  right: 0;
  width: 0;
  position: absolute;
  transition: 0.4s all ease;
  opacity: 0;
}

#service-suport #accommodation .slides li a.active::after {
  top: 83%;
  opacity: 1;
}
#service-suport .slides li .text {
  padding: 40px 0;
  background: #f0e59e;
}
.postid-580 #service-suport .slides li .text {
  background: #f0ece7;
  border-bottom: none;
  padding: 40px 8%;
}
.postid-580 #service-suport .slides li .text table td {
  border-bottom: 1px solid #cbda78;
}
.postid-580 #service-suport #accommodation .slides li a::after {
  border-bottom: 10px solid #f0ece7;
}
#service-suport .map {
  padding: 50px 5% 10px;
  position: relative;
  background-color: white;
}
#service-suport .map h3 {
  margin: -45px auto 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
#service-suport .map h3 span {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 24px;
}

#service-suport .map img {
  width: 100%;
  border-radius: 23px;
}
#service-suport .slides li form {
  margin: 0 10px;
  border: 1px solid #fce186;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  padding: 20px 30px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
#flex .main {
  margin: 0 10px;
  border: 1px solid #fce186;
  border-radius: 10px;
  background: #fff;
  text-align: left;
  padding: 40px 13%;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
}
.us #flex .main,
.ca #flex .main,
.nz #flex .main {
  padding: 20px 10px 10px;
}
.postid-580 .us #flex .main-2,
.postid-1722 .ca #flex .main-2 {
  border: none;
  margin: 0;
  padding: 0px;
}
.us #flex .main .row ul,
.ca #flex .main .row ul {
  list-style: none;
}
.block3 {
  max-width: 1200px;
  padding: 40px 0;
}
.postid-580 .us .block3,
.postid-1722 .ca .block3 {
  padding: 0;
  background: none;
  padding-bottom: 40px;
  width: 95%;
  margin: auto;
}
.postid-427 #flexpart .flexslider,
.postid-1313 #flexpart .flexslider {
  margin: 0px;
}
.postid-427 .us .block3,
.postid-1313 .ca .block3 {
  width: 95%;
  margin: 0 auto;
}
.postid-580 .us .block3 .block,
.postid-1722 .ca .block3 .block {
  background: #3c8dd9;
  min-height: 300px;
}
.postid-580 .us .block3 .block:nth-child(2),
.postid-1722 .ca .block3 .block:nth-child(2) {
  background: #3c8dd9;
}
.postid-580 .us .block3 .block:hover,
.postid-1722 .ca .block3 .block:hover {
  background: #fff;
}
.block3 .block {
  width: calc(100% - 20px);
  background: #d0ea41;
  float: left;
  border-radius: 5px;
  text-align: center;
  margin: 0px 10px;
  transition: 0.5s all ease;
  min-height: 300px;
  position: relative;
}

.block3 .block:hover {
  background: #fff;
}
.block3 .block h3 {
  color: #fff;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin: 20px 20px;
  line-height: 1.9;
  transition: 0.5s all ease;
}
.postid-580 .block3 .block h3,
.postid-1722 .block3 .block h3 {
  line-height: 1.9;
}
.block3 .block:hover h3 {
  color: #4d4d4d;
}
.block3 .block a {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.block3 .block a span {
  border-radius: 5px;
  background: #fff;
  color: #4d4d4d;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 10px;
  transition: 0.5s all ease;
}
.block3 .block:hover a span {
  background: #3c8dd9;
}
.block3 .block:nth-child(2):hover a span {
  background: #3c8dd9;
}
.us #flex .main .row ul li:before,
.ca #flex .main .row ul li:before {
  content: "";
  /* background: url('../defaultImages/bullet.png') no-repeat center left; */
  position: absolute;
  width: 11px;
  height: 11px;
  margin-left: -20px;
  top: 8px;
}
.us #flex .main .row ul li,
.ca #flex .main .row ul li {
  padding-left: 20px;
  position: relative;
}
.us #flex .main .us_service,
.ca #flex .main .ca_service {
  background: #f0ece7;
  padding: 30px 0 0;
  margin: 0;
  position: relative;
}
.postid-580 .us #flex .main .us_service,
.postid-1722 .ca #flex .main .ca_service {
  background: none;
  padding: 0px 0 0;
  margin: 0 0;
  position: relative;
}
.postid-1722 .ca #flex .main .row:nth-child(2n + 2) {
  padding-left: 5%;
  padding-right: 2%;
  border: none;
}
.us_oshc {
  text-align: center;
}
.us_oshc img {
  margin-bottom: 60px;
  margin-top: 20px;
}
.us #flex .main .us_service:before,
.ca #flex .main .ca_service:before {
  content: "";

  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f0ece7;
  left: calc(50% - 10px);
  top: -10px;
}
.postid-580 .us #flex .main .us_service:before,
.postid-1722 .ca #flex .main .ca_service:before {
  border: none;
}
.postid-580 #accommodation .slides li,
.postid-1722 #accommodation .slides li {
  width: calc(100% / 2) !important;
}
.us #flex .main .us_service h3.section-title,
.ca #flex .main .ca_service h3.section-title {
  color: #3d3d3d;
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
}
.us_service .us_oshc h3.section-title:before {
  /*border-bottom: 10px solid #f0ece7;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    height: 0;
    left: calc(50% - 10px);
    position: absolute;
    bottom:0;
    width: 0;*/
}
.us_service .us_oshc h3.section-title,
.ca_service .us_oshc h3.section-title {
  margin-bottom: 10px !important;
  /*border-bottom: 3px solid #f0ece7 !important;*/
  position: relative;
  padding-bottom: 15px;
}
#flexpart .slides li form .clm-title-item {
  padding-top: 0 !important;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 16px;
  color: #9aad34;
  /*text-align: left;*/
  -webkit-filter: none !important; /* Chrome, Safari, Opera */
  filter: none !important;
  text-transform: none !important;
}
#flexpart .slides li form {
  background: none;
  border: none !important;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  margin: 0 10px;
  padding: 0px 10px;
  /*text-align: left;*/
}
#flexpart .slides li form .cnt {
  border-top: 1px solid rgba(154, 173, 52, 0.5);
  border-bottom: 1px solid rgba(154, 173, 52, 0.5);
  padding: 20px 0;
}

#flexpart .slides li form input[type="submit"] {
  background: #d0ea41 none repeat scroll 0 0 !important;
  border: #d0ea41 1px solid !important;
  border-radius: 20px !important;
  box-shadow: none;
  color: #fff !important;
  display: inline-block;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px !important;
  position: relative;
  text-transform: uppercase;
  transform: translateZ(0px);
  transition: transform 0.3s ease 0s;
  vertical-align: middle;
  padding: 5px 15px;
  margin: 20px 0px;
}
.nz #flex .main {
  border: 1px solid #90ed74;
}
.us #flex .main,
.ca #flex .main {
  border: 1px solid #d0ea41;
}
.nz #flex .main .title,
.nz #flex .main strong {
  padding: 0 20px;
}
.nz #flex .main strong.bg {
  background: #f6f6f6;
  border-radius: 30px;
  line-height: 35px;
  height: auto;
  display: block;
}
.nz #flex .main .title h4,
.ca #flex .main .title h4,
.us #flex .main .title h4 {
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 16px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
}
.nz #flex .main .title h4:before {
  content: "";
  height: 0;
  border-bottom: solid #90ed74 3px;
  width: 65px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.us #flex .main .title h4:before,
.ca #flex .main .title h4:before {
  border-bottom: solid #d0ea41 3px;
  content: "";
  height: 0;
  width: 65px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.nz #flex .main ul {
  list-style: none;
}
.nz #flex .main ul:not(.list1) li {
  position: relative;
}
.nz #flex .main ul:not(.list1) li:before {
  content: "";
  width: 3px;
  height: 3px;
  background: #4d4d4d;
  border-radius: 50%;
  position: absolute;
  top: 14px;
  left: 0;
}
.nz #flex .main ul.list1 li {
  clear: left;
  line-height: 35px;
  padding: 0 20px;
}
.nz #flex .main ul:not(.list1) li {
  clear: left;
  line-height: 30px;
  padding: 0 20px;
}
.nz #flex .main ul.list1 {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.postid-226 .nz #flex .main ul.list1 {
  border-bottom: none;
}
.postid-226 .nz #flex .main .mid {
  margin-top: 20px;
}
/*.postid-226 .nz #flex .main .mid:before{
	border-bottom: 10px solid #f0ece7;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top:0px;
    content: "";
    height: 0;
    left: 0;
    margin: auto;
    right: 0;
    width: 0;

}*/

.nz #flex .main ul.list1 li:nth-child(2n) {
  background: #f6f6f6;
  border-radius: 30px;
}

.nz #flex .main .row.r {
  width: 65%;
  float: left;
  padding-right: 0%;
  padding-left: 0%;
  border: none;
}
.nz #flex .main .row.l {
  width: 25%;
  float: right;
  color: #ffb05b;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  border: none;
  padding: 0;
}

.nz #flex .main ul.list1 li:first-child .row.l {
  color: #4d4d4d;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
}
#service-suport.spec:before {
  top: 30%;
}
.nz #service-suport .slides li form {
  border: 1px solid #90ed74;
  padding: 0;
}
.nz #service-suport .slides li form .service-item {
  padding: 20px 30px;
}
.nz #service-suport .slides li form .nz {
  height: 226px;
  overflow: hidden;
}
.nz #service-suport .slides li form .nz img {
  width: 100%;
  border-radius: 10px 10px 0 0px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.4s all linear;
}
.nz #service-suport .slides li:hover form .nz img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.ca #flex .main .row:nth-child(2),
.ca #flex .main .row:nth-child(6) {
  padding-left: 2%;
  padding-right: 8%;
  width: calc(50% + 15px);
  border-left: 1px solid #f0ece7;
  float: right;
}
.us #flex .main .row:nth-child(1),
.us #flex .main .row:nth-child(3),
.ca #flex .main .row:nth-child(1),
.ca #flex .main .row:nth-child(3) {
  clear: left;
}
.us #flex .main .line,
.ca #flex .main .line {
  clear: left;
  height: 0;
  border-bottom: 1px solid #f0ece7;
  margin: 0 8%;
  position: relative;
}
.us #flex .main .line:before,
.ca #flex .main .line:before {
  border-bottom: 10px solid #f0ece7;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  height: 0;
  left: calc(50% - 10px);
  position: absolute;
  top: -10px;
  width: 0;
}

.us #service-suport .slides li form,
.ca #service-suport .slides li form {
  border: 1px solid #d0ea41;
}

.category .archive-content .line {
  clear: left;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}
.category .archive-content .line:last-child {
  border-bottom: none;
}
#service-suport.accommodation .slides li form {
  margin-bottom: 10px;
}
#service-suport .slides li form .clm-title-item {
  font-size: 18px;
  text-transform: uppercase;
  padding-top: 70px;
  transition: 0.5s all ease;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
.nz #service-suport .slides li form .clm-title-item {
  padding-top: 0;
}
#template-airport_pickup.nz #service-suport .slides li form .clm-title-item {
  padding-top: 70px;
}
#service-suport .slides li form:hover .clm-title-item {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.telephone#service-suport .slides li form .clm-title-item {
  font-size: 16px;
  padding-top: 85px;
  min-height: 80px;
  line-height: 35px;
}
.telephone#service-suport .slides li form .clm-content-item {
  min-height: 0;
}
/*#service-suport .slide:nth-child(1) form .clm-title-item{
        background: url('../defaultImages/single.png') no-repeat center top;
}
#service-suport .slide:nth-child(1) form:hover .clm-title-item{
        background: url('../defaultImages/singleh.png') no-repeat center top;
}
#service-suport .slide:nth-child(2) form .clm-title-item{
        background: url('../defaultImages/dual.png') no-repeat center top;
}
#service-suport .slide:nth-child(2) form:hover .clm-title-item{
        background: url('../defaultImages/dualh.png') no-repeat center top;
}
#service-suport .slide:nth-child(3) form .clm-title-item{
        background: url('../defaultImages/family.png') no-repeat center top;
}
#service-suport .slide:nth-child(3) form:hover .clm-title-item{
        background: url('../defaultImages/familyh.png') no-repeat center top;
}
#service-suport .slide:nth-child(4) form .clm-title-item{
        background: url('../defaultImages/group.png') no-repeat center top;
}
#service-suport .slide:nth-child(4) form:hover .clm-title-item{
        background: url('../defaultImages/grouph.png') no-repeat center top;
}*/
#service-suport .slides li form .clm-content-item {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  min-height: 106px;
}
.nz #service-suport .slides li form .clm-content-item {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  min-height: 146px;
}
#service-suport .slides li form input[type="submit"],
#service-suport .slides li form input[type="button"],
.nz #service-suport .main .row a,
#service-suport .slides li form a.benefit,
#service-suport .slides li form a.quote {
  background: none;
  color: #f54989;
  font-family: Arial, sans-serif;
  font-weight: bold;
  border: 1px solid #fce186;
  transition: 0.4s all ease;
  border-radius: 0;
  border-left: none;
  border-right: none;
  text-transform: uppercase;
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  display: inline-block;
  position: relative;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: middle;
  font-size: 1.3rem;
  margin: 0 20px;
}

#service-suport .slides li form input[type="button"] {
  padding: 8px 10px;
}
#service-suport .slides li form a.quote {
  padding: 5px 10px;
}
.nz #service-suport .slides li form input[type="submit"],
.nz #service-suport .main .row a,
.nz #service-suport .slides li form a.benefit,
.nz #service-suport .slides li form a.quote {
  color: #ffb05b;
  border: 1px solid #90ed74;
  border-left: none;
  border-right: none;
}
.nz #service-suport .main .row p {
  text-align: center !important;
  line-height: 35px;
}
.nz #service-suport .main .row p a {
  line-height: 35px;
}
.nz #service-suport .slides li form input[type="submit"],
.nz #service-suport .slides li form input a.benefit {
  margin: 0 20px;
}

#service-suport .slides li form input[type="submit"]:hover,
.nz #service-suport .main .row a:hover,
#service-suport .slides li form a.benefit:hover {
  transform: translateY(10px);
}
.postid-210 #service-suport .slides li form a.benefit {
  padding: 4px 10px;
  margin-top: 10px;
}
.postid-210 #service-suport .slides li form input[type="submit"] {
  margin-top: 10px;
}
/* .groupnote .section-content .haft .note:nth-child(1):hover a:before{
    background: url('../defaultImages/why1h.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(2):hover a:before{
    background: url('../defaultImages/why2h.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(3):hover a:before{
    background: url('../defaultImages/why3h.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(4):hover a:before{
    background: url('../defaultImages/why4h.png') no-repeat center center;
}
.groupnote .section-content .haft .note:nth-child(5):hover a:before{
    background: url('../defaultImages/why5h.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(1):hover a:before,
#more-content-oshc .section-content .haft .note:nth-child(1):hover a:before{
    background: url('../defaultImages/visa1h.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(2):hover a:before,
#more-content-oshc .section-content .haft .note:nth-child(2):hover a:before{
    background: url('../defaultImages/visa2h.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(3):hover a:before,
#more-content-oshc .section-content .haft .note:nth-child(3):hover a:before{
    background: url('../defaultImages/visa3h.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(4):hover a:before,
#more-content-oshc .section-content .haft .note:nth-child(4):hover a:before{
    background: url('../defaultImages/visa4h.png') no-repeat center center;
}
#more-content-ovhc .section-content .haft .note:nth-child(5):hover a:before,
#more-content-oshc .section-content .haft .note:nth-child(5):hover a:before{
    background: url('../defaultImages/visa5h.png') no-repeat center center;
} */
#help-suport {
  background: #f2f2f2;
  padding: 20px 0 20px;
}
#help-suport .section-title {
  margin: 20px 0;
}
#quick-quote {
  background: #fff;
  padding: 30px 0;
}
#quick-quote .quoke {
  position: relative;
}
#quick-quote .quote-content {
  width: calc(49% - 8px);
  float: left;
  margin-right: 10px;
  background: #fff;
  border-radius: 20px;
  border: 1px dashed #ccc;
  padding: 3px;
}
#quick-quote .quote-content,
#quick-quote .quote-content ul {
  min-height: 190px;
}
#quick-quote .quote-image {
  border-radius: 20px;
  width: 100%;
  min-height: 190px;
  height: 100%;
  color: #fff;
}
#quick-quote .quote-image p {
  padding: 20px;
  font-size: 18px;
}
/*
#quick-quote.telephone .quote-content{
    width: calc(42% - 8px);
}
#quick-quote.telephone .quote-content:nth-child(2){
    width: calc(56% - 8px);
}
#quick-quote.telephone .text,{
    left: calc(43% - 52px);
}
*/
#quick-quote .quote-content ul {
  /*min-height: 300px;*/
  background: #f54989;
  padding: 10px;
  border-radius: 20px;
  margin: 0;
  list-style: none;
}
.uc #quick-quote.airport_pickup .quote-content ul,
.uc #quick-quote.guardian_service .quote-content ul {
  /*min-height:220px;*/
}

.uc #quick-quote.accommodation .text {
  display: none;
}
.nz #quick-quote .quote-content ul {
  background: #ffb05b;
  /*min-height:280px;*/
}
.nz #quick-quote.airport_pickup .quote-content ul {
  min-height: 200px;
}
.us #quick-quote .quote-content ul,
.ca #quick-quote .quote-content ul {
  background: #ffb05b;
  min-height: 200px;
}
#quick-quote .quote-content ul li {
  color: #fff;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  font-size: 20px;
  line-height: 30px;
  padding: 10px 25px;
  /*background: url('../defaultImages/icon_bullet.png') no-repeat center left;*/
}
#quick-quote .quote-content ul li:first-child {
  padding-top: 0;
}
#quick-quote .quote-content ul li:last-child {
  padding-bottom: 0;
}

#quick-quote .quote-image img {
  width: 100%;
  border-radius: 20px;
}

#quick-quote .quick-quote-goto {
  cursor: pointer;
  border-radius: 50%;
  background: #fbde1b url("../defaultImages/bg-row.png") repeat;
  border: 4px dashed #fff;
  padding: 3px;
  display: block;
  text-align: center;
  width: 100px;
  height: 80px;
  line-height: 30px;
  color: #4d4d4d;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding-top: 23px;
  transition: 0.7s all ease-in;
}
#template-airport_pickup #quick-quote .quick-quote-goto,
.postid-226 #quick-quote .quick-quote-goto {
  display: none;
}
.nz #quick-quote .quick-quote-goto {
  background: #76de56 url("../defaultImages/bg-row.png") repeat;
}
.us #quick-quote .quick-quote-goto,
.ca #quick-quote .quick-quote-goto {
  background: #76de56 url("../defaultImages/bg-row.png") repeat;
}
#quick-quote .quick-quote-goto:hover {
  transform: rotateX(360deg);
}
#quick-quote .text {
  position: absolute;
  top: calc(50% - 52px);
  left: calc(50% - 52px);
}
#oshc-more-content {
  background: #fff;
  padding: 30px 0 30px;
}

#oshc-more-content .oshc-clm {
  background: #efefef;
  border-radius: 8px;
  width: 48.5%;
  font-family: Arial, sans-serif;
  font-weight: bold;
  position: relative;
}
#oshc-more-content .paraopacity {
  clear: left;
  display: none;
  transition: 0.4s all ease;
}
#oshc-more-content .oshc-clm.open-content .paraopacity {
  display: block;
}
#oshc-more-content .oshc-clm .read-more-oshc {
  background: url("../defaultImages/icon_more.png") no-repeat center center;
  position: absolute;
  bottom: -22px;
  height: 44px;
  width: 44px;
  right: 44px;
}
#oshc-more-content .oshc-clm.open-content .read-more-oshc {
  /* background: url('../defaultImages/icon_more_n.png') no-repeat center center; */
}
#oshc-more-content .oshc-clm .oshc-clm-title {
  padding: 25px 45px 20px;
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
}
#oshc-more-content .oshc-clm-content {
  padding: 0 45px 20px;
  font-family: Arial, sans-serif;
  font-weight: lighter;
  font-size: 14px;
}
#oshc-more-content .oshc-clm-content ul {
  list-style: none;
}
#oshc-more-content .oshc-clm-content ul li {
  padding-bottom: 8px;
}
#oshc-more-content .oshc-clm-left {
  float: left;
}
#oshc-more-content .oshc-clm-content .part {
  width: 49%;
  float: left;
}
#oshc-more-content .oshc-clm-content .part:nth-child(2) {
  float: right;
}
#oshc-more-content .oshc-clm-left .oshc-clm-content ul li {
  background: url("../defaultImages/icon_yes.png") no-repeat left center;
  padding-left: 50px;
}
#oshc-more-content .oshc-clm-right {
  float: right;
}
#oshc-more-content .oshc-clm-right .oshc-clm-content ul li {
  background: url("../defaultImages/icon_no.png") no-repeat left center;
  padding-left: 50px;
}
.ht-video-show-thumb:not(.play):before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  background: url("../defaultImages/play_media.png") center center no-repeat;
  cursor: pointer;
}
.ht-video-show-thumb .iframe {
}
.ht-video-show-thumb object,
.ht-video-show-thumb embed {
  width: 100%;
}
.customers {
  background: #fff;
  padding: 40px 0 35px;
  position: relative;
}
.customers .customer {
  width: calc(50% - 31px);
  float: left;
  background: none;
  border-right: 1px solid #ccc;
  border-radius: 0;
  padding-right: 30px;
  margin: 0;
}
.customers .customer:last-child {
  float: right;
  padding-right: 0;
  padding-left: 30px;
  border-right: 0;
}
.customers h3 {
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #4d4d4d;
  position: relative;
  letter-spacing: 2px;
  margin-top: 0;
}
.customers h3:before {
  content: "";
  background: #fff url("../defaultImages/header_cus.png") repeat;
  width: 25px;
  height: 10px;
  margin-right: 8px;
  position: absolute;
  top: 5px;
}
.customers h3 span {
  padding-left: 30px;
}
.customers .flex-direction-nav a:before {
  font-size: 20px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}
.customers .flex-direction-nav a {
  height: 30px;
  width: 30px;
  text-align: center;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}
.flex-direction-nav a {
  color: #838383 !important;
}
.customers .slides .item {
  list-style: none;
  margin-right: 0px;
  float: left;
  padding: 0;
}
.customers .slides .item .thumb {
  background: #fff;
  display: block;
  font-size: 0;
  text-align: center;
  height: 50px;
}
.customers .slides .item .thumb:before {
  height: 100%;
  width: 0;
  display: inline-block;
  content: "";
  vertical-align: middle;
}
.customers .slides .item .thumb img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  margin: auto;
  display: inline-block;
  vertical-align: middle;
  /*
	-webkit-filter: none;
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);

	*/
  -webkit-filter: grayscale(100%);
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.customers .slides .item .thumb:hover img {
  -webkit-filter: grayscale(100%);
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.customers .flex-direction-nav .flex-prev {
  left: auto;
  right: calc(100% - 39px);
  opacity: 0;
  margin: -15px 0 0;
}
.customers .flex-direction-nav .flex-next {
  right: auto;
  left: calc(100% - 15px);
  text-align: right;
  margin: -15px 0 0;
}
/* Archive */
.archive.taxonomy {
}
.archive.taxonomy article .thumb {
}
/* PAGE */
.intro-banner.parallax,
.intro-banner-2.parallax {
  width: 100%;
}
.single .intro-banner-2.parallax {
  height: 67px;
}
.single .intro-banner.parallax,
.page .intro-banner.parallax {
  height: 307px;
}
.intro-banner.parallax .layer_back,
.intro-banner-2.parallax .layer_back {
  width: 100%;
}
.intro-banner.parallax .background_layer,
.intro-banner-2.parallax .background_layer {
  text-align: center;
  color: #fff;
}
.intro-banner.parallax .background_layer .entry-title,
.single-project .intro-banner.parallax .entry-title,
.intro-banner-2.parallax .background_layer .entry-title,
.single-project .intro-banner-2.parallax .entry-title {
  color: #f89918;
  font-size: 35px;
  font-size: 3.5rem;
  font-weight: 400;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 1),
    1px -1px 0 rgba(255, 255, 255, 1), -1px 1px 0 rgba(255, 255, 255, 1),
    1px 1px 0 rgba(255, 255, 255, 1);
}
.intro-banner #crumbs,
.intro-banner-2 #crumbs {
  display: block !important;
  width: 100%;
  padding: 12px 20px;
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 0;
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
}
.intro-banner #crumbs .delimiter:before,
.intro-banner-2 #crumbs .delimiter:before {
  content: "";
  height: 32px;
  border-left: 1px solid #fff;
  width: 0;
  margin: 0 20px;
}
.intro-banner #crumbs a,
.intro-banner-2 #crumbs a {
  color: #fff;
}
.intro-banner #crumbs span:first-child a,
.intro-banner-2 #crumbs span:first-child a {
  color: #fff;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
.intro-banner #crumbs a:hover,
.intro-banner-2 #crumbs a:hover {
  color: #fff;
}

.page-template-page-home-about .intro-banner-2.parallax,
.archive .intro-banner-2.parallax {
  height: 250px;
}
.page-template-page-home-about .intro-banner.parallax,
.archive .intro-banner.parallax {
  height: 300px;
}
.page-template-page-home-about .intro-banner.parallax .layer_back,
.archive .intro-banner.parallax .layer_back {
}
.page-template-page-home-about .intro-banner.parallax .background_layer {
  padding-top: 50px;
}
.home-about-us .blocks {
  margin-bottom: 30px;
}
.home-about-us .blocks.small-block.columns {
  display: none;
}
.home-about-us .block {
  margin-bottom: 0;
}
.home-about-us .block .title,
.project-box .heading {
  position: relative;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
  padding-bottom: 10px;
  color: #4d4d4d;
}
.home-about-us .block .title:before,
.project-box .heading:before {
  background-color: #ddd;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.home-about-us .block .title:after,
.project-box .heading:after {
  background-color: #f54989;
  bottom: -1px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 80px;
}
.home-about-us .block .content {
  text-align: justify;
}
#our-leaders {
  margin: 0px 0 60px;
  position: relative;
}
#our-leaders .title {
  margin-bottom: 20px;
}
#our-leaders .flex-control-nav a {
  background: #ccc;
}
#our-leaders .flex-control-nav a.flex-active {
  background: #ffd100;
}
#our-leaders .listing-leaders {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
#our-leaders .listing-leaders .leader {
  float: left;
  margin-right: 40px;
  position: relative;
  width: calc((100% - 40px * 3) / 4);
}
#our-leaders .listing-leaders .leader .name {
  text-align: center;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 600;
}
#our-leaders .listing-leaders .leader img.thumb {
  display: block;
  width: calc(100% - 2px);
  padding: 1px;
  background: #eee;
  margin-bottom: 10px;
}
#our-leaders .listing-leaders .leader .profile .name {
  font-size: 15px;
  font-size: 1.5rem;
  display: block;
}
#our-leaders .listing-leaders .leader .profile .office {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 600;
  color: #999;
  text-align: center;
  display: block;
}
#our-leaders .flex-direction-nav a:before {
  font-size: 15px;
}
#our-leaders .flex-direction-nav a {
  opacity: 1;
  height: 20px;
  width: 20px;
  line-height: 20px;
  padding: 5px 2px;
  border: 1px solid #ddd;
  text-align: center;
  top: 7px;
  right: 10px;
  margin: 0;
}
#our-leaders .flex-direction-nav .flex-prev {
  right: 40px;
  left: auto;
}
#our-leaders .flex-direction-nav a:hover {
  color: #ffb600;
}

.template-contact-us .entry-title {
  margin-bottom: 10px;
}
.template-contact-us .entry-content form {
  /* background: url("images/bg_service.png") repeat scroll 0 0 #ddd; */
  border-radius: 4px;
  margin: 10px auto;
  padding: 10px;
}
.template-contact-us .image-office {
  float: left;
  width: 40%;
}
.template-contact-us .image-office img {
  width: 100%;
}
.template-contact-us .maps {
  float: right;
  width: 60%;
}
.template-contact-us .maps iframe {
  width: 100% !important;
}
.template-contact-us .office:not(:last-child) {
  margin-bottom: 20px;
}
.template-contact-us .office .name {
  margin: 0;
}
.template-contact-us .office .info li {
  list-style: none;
}
.template-contact-us .office .info li:before {
  display: inline-block;
  font-family: "FontAwesome";
  color: #f89918;
  margin-right: 15px;
}
.template-contact-us .office .info .address:before {
  content: "\f041";
}
.template-contact-us .office .info .tel:before {
  content: "\f095";
}
.template-contact-us .office .info .fax:before {
  content: "\f1ac";
}
.template-contact-us .office .info .email:before {
  content: "\f0e0";
}

.template-contact-us .entry-content .apart {
  margin-top: 10px;
}
.template-contact-us .entry-content form .row {
  margin: 20px 0;
}
.template-contact-us .entry-content form label {
  background: #238dcd;
  border-radius: 4px 0 0 4px;
  color: #fff;
  float: left;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 15%;
}
.template-contact-us .entry-content form input {
  border: 0 none;
  border-radius: 0 4px 4px 0;
  height: 40px;
  line-height: 40px;
  padding: 0 1%;
  width: 83%;
}
.template-contact-us .entry-content form .request label {
  height: 100px;
  line-height: 100px;
}
.template-contact-us .entry-content form textarea {
  border: 0 none;
  border-radius: 0 4px 4px 0;
  height: 100px;
  padding: 0 1%;
  resize: vertical;
  min-width: 83%;
  max-width: 83%;
}
.template-contact-us .entry-content form input[type="submit"] {
  background: none repeat scroll 0 0 #505050;
  border-radius: 2px;
  box-shadow: 0 0 2px #ccc;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  text-transform: uppercase;
  width: auto;
}
.template-contact-us .entry-content form input[type="submit"]:hover {
  background: #238dcd;
}
.share-social {
  margin-top: 20px;
  padding-top: 15px;
  /* background: url("images/bg_about_us_title.png") repeat-x scroll left top; */
}
.share-social .LikeFacebook > span {
  margin-right: 15px;
}
/* Users */
.account-container {
  background: #fff;
  padding: 5px 10px;
  margin: 10px auto;
}
.account-page .account-container > .entry-title {
  color: #555;
  margin: 10px 0;
  font-weight: 400;
}
.account-page .account-container > .description-title {
  font-size: 12px;
  font-size: 1.2rem;
  color: #999;
}
.account-page:not(.profile) .account-container.login > .entry-title {
  text-align: center;
  border-bottom: 0;
  margin-bottom: 0;
}
.account-page .account-container #login-form {
  display: table;
  margin: 20px auto;
  padding: 30px 20px;
  background: #fff;
  border: 1px solid #eee;
  background: #fff;
  min-width: 250px;
}
.account-page .account-container #login-form .icon-title {
  text-align: center;
}
.account-page .account-container #login-form .icon-title .fa {
  background: none repeat scroll 0 0 #e1e1e1;
  border-radius: 50%;
  color: #bdbdbd;
  font-size: 80px;
  height: 80px;
  line-height: 100px;
  overflow: hidden;
  width: 80px;
}
.account-page .account-container #login-form input[type="text"],
.account-page .account-container #login-form input[type="password"] {
  width: calc(100% - 22px);
}
.account-page .account-container #login-form input[type="submit"] {
  width: 100%;
}
.account-page .account-container .login-note {
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0;
}
.account-page .account-container #account-form .remember {
  float: left;
}
.account-page .account-container #account-form .lost-password {
  float: right;
}
.account-page .account-container .register-page .field .title {
  text-align: left;
  padding-right: 10px;
}
.account-page .account-container #login-form input[type="text"],
.account-page .account-container #login-form input[type="password"] {
  height: 40px;
  line-height: 40px;
}
/* CATEGORY */
.archive .archive-title {
  color: #f89b1c;
  font-size: 3.5rem;
  font-weight: 400;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 1),
    1px -1px 0 rgba(255, 255, 255, 1), -1px 1px 0 rgba(255, 255, 255, 1),
    1px 1px 0 rgba(255, 255, 255, 1);
}
.archive .archive-content > .entry {
  margin-bottom: 3%;
  line-height: 1.4;
}
.archive .archive-content > .entry a:hover {
  text-decoration: underline;
}
.archive .archive-content > .entry .thumb {
  position: relative;
  display: block;
  height: 183px;
  overflow: hidden;
}
.archive .archive-content > .entry .thumb .small {
  position: absolute;
  background: rgba(217, 63, 99, 0.8);
  color: #fff;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 12px;
  padding: 0 15px;
  line-height: 20px;
  bottom: 12px;
  left: 23px;
  z-index: 11;
}
.archive .archive-content > .entry .thumb:after {
  content: "";
  position: absolute;
  transition: 0.4s all ease;
  bottom: 100%;
  left: 0;
  right: 0;
  top: 0%;
  z-index: 1;
}
.archive .archive-content > .entry:hover .thumb:after {
  background: rgba(74, 69, 66, 0.4);
  bottom: 0;
}
.archive .archive-content > .entry .thumb img {
  display: block;
  max-width: 100%;
}
.archive .archive-content > .entry .entry-title {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0;
  position: relative;
  padding: 15px 0 15px;
  margin-bottom: 10px;
}
.archive .archive-content > .entry .entry-title a {
  color: #414141;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.6;
  transition: 0.4s all ease;
}
.archive .archive-content > .entry .entry-title:after {
  content: "";
  width: 52px;
  border-bottom: 4px solid #da5b78;
  position: absolute;
  left: 0;
  height: 0;
  bottom: 0;
}
.archive .archive-content > .entry:hover .entry-title a {
  color: #da5b78;
  text-decoration: none;
}

.archive .archive-content > .entry .excerpt {
  font-size: 14px;
  font-size: 1.4rem;
  color: #414141;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  line-height: 1.5;
  transition: 0.4s all ease;
}
.archive .archive-content > .entry:hover .excerpt {
  color: #da5b78;
}
.archive .archive-content > .entry .readmore {
  text-align: right;
}
.archive .archive-content > .entry .readmore a {
  display: inline-block;
  color: #205b69;
  border: 1px solid #eee;
  padding: 3px 5px;
  border-radius: 3px;
}
#primary.archive-services {
  background: transparent;
}
.archive .archive-services > .entry {
  margin-top: 30px;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
}
.archive .archive-services .service:before {
  left: 10px;
  right: 10px;
  top: 50%;
  bottom: 50%;
  transition: all 0.5s ease-in-out 0s;
  content: "";
  display: block;
  position: absolute;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  width: auto;
  z-index: 1;
}
.archive .archive-services .service:after {
  left: 50%;
  right: 50%;
  top: 10px;
  bottom: 10px;
  transition: all 0.5s ease-in-out 0s;
  content: "";
  display: block;
  position: absolute;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  z-index: 1;
  height: auto;
}
.archive .archive-services .service:hover:before {
  top: 10px;
  bottom: 10px;
}
.archive .archive-services .service:hover:after {
  left: 10px;
  right: 10px;
}
.archive .archive-services > .entry:nth-child(1) {
  margin-top: 0px;
}
.archive .archive-services > .entry .thumb {
  background: #fff;
  /*width: 100px;*/
  text-align: center;
  display: block;
  margin: auto;
  font-size: 0;
  /*background: #fff;*/
  /*border-radius: 50%;*/
  position: relative;
}
.archive .archive-services > .entry .thumb::before {
  content: "";
  height: 100%;
  transition: all 0.3s ease-in-out 0s;
  width: 0;
  display: inline-block;
  vertical-align: middle;
}
.archive .archive-services .thumb:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
}
.archive .archive-services .service:hover .thumb:after {
  visibility: visible;
  opacity: 1;
}
.archive .archive-services > .entry .thumb img {
  display: inline-block;
  vertical-align: middle;
  max-height: 100%;
}
.archive .archive-services > .entry .excerpt {
  color: #777;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  text-align: justify;
  margin-top: 5px;
  display: none;
}
.archive .archive-services > .entry .entry-title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  position: absolute;
  left: 10px;
  margin: 0px;
  bottom: 10px;
  transition: all 0.5s ease-in-out 0s;
  z-index: 2;
  max-width: calc(100% - 40px);
}
.archive .archive-services > .entry:hover .entry-title {
  left: 20px;
  bottom: 20px;
}
.archive .archive-services > .entry .entry-title a {
  color: #fff;
  padding: 10px 10px 10px 10px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
}
.archive .archive-services > .entry .entry-title a span:before {
  content: "\f105";
  font-size: 13px;
  font-size: 1.3rem;
  margin-right: 5px;
  font-weight: 100;
  display: inline-block;
  line-height: 1;
  float: left;
  margin-top: 1px;
}
.archive .archive-services > .entry .entry-title a:before {
  content: "";
  width: 20px;
  top: 0px;
  bottom: 20px;
  position: absolute;
  left: 100%;
}
.archive .archive-services > .entry:nth-child(2n + 1) .entry-title a,
.archive .archive-services > .entry:nth-child(2n + 1) .entry-title a:before {
  background-color: rgba(35, 141, 205, 0.9);
}
.archive .archive-services > .entry:nth-child(2n + 2) .entry-title a,
.archive .archive-services > .entry:nth-child(2n + 2) .entry-title a:before {
  background-color: rgba(221, 26, 4, 0.9);
}
/*
.section-content .haft .note{
    float: left;
    width: calc(20% - 2px);
    background-repeat: no-repeat;
    background-position: top center;
    cursor: pointer;
    border-left: 1px solid #e9e9e9;
    text-align: center;
    padding: 0px 40px;
    box-sizing: border-box;
}

.section-content .haft .note:last-child{
    margin-right: 0;
}

.section-content .haft .note .title{
    margin-top: 83px;
    text-align: center;
    font-size: 15px;
    font-size: 1.5rem;
    font-family: "RobotoB";
    font-weight: 500;
    margin-bottom: 0px;
}

.section-content .haft .ovhc-item-0{
    background-image: url('../defaultImages/visa1.png');
}

.section-content .haft .ovhc-item-0:hover{
    background-image: url('../defaultImages/visa1h.png');
}

.section-content .haft .ovhc-item:hover .title{
    color: #F54989;
}*/

.archive .archive-services > .entry .entry-title a:after {
  position: absolute;
  bottom: 0px;
  left: 100%;
  height: 0;
  width: 0;
  content: "";
  border: 20px solid transparent;
}
.archive .archive-services > .entry:nth-child(2n + 1) .entry-title a:after {
  border-left: 20px solid #166799;
  border-top: 0px;
}
.archive .archive-services > .entry:nth-child(2n + 2) .entry-title a:after {
  border-left: 20px solid #ba1907;
  border-top: 0px;
}

.archive .archive-services > .entry .entry-title a:hover {
  text-decoration: underline;
}
.archive .archive-services > .entry .readmore {
  text-align: center;
  margin: 0;
}
.archive .archive-services > .entry .readmore a {
  background: #fff;
  border-radius: 3px;
  color: #333;
  display: inline-block;
  padding: 5px 10px;
  transition: 0.3s all ease-in-out;
}
.archive .archive-services > .entry .readmore a:hover {
  background: #205b69;
  color: #fff;
}
/* WIDGET - SIDEBAR */
#list-faq .faq {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin-top: 7px;
  padding: 7px 7px;
}

#list-faq .faq .question {
  float: none;
  color: #e1001a;
  position: relative;
}
#list-faq .faq.bg-color {
  background: #efefef;
}
#list-faq .faq .question:after {
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #ccc;
  position: absolute;
  right: 10px;
  transition: 0.4s all ease;
}
#list-faq .faq .question.close:after {
  transform: rotate(180deg);
}
.sidebar .menu-page {
  margin-top: -95px;
  position: relative;
  /* background: url("images/bg_service.png") repeat scroll 0 0 #ffd100; */
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.sidebar .menu-page > .title {
  height: 95px;
  line-height: 110px;
  padding: 0 30px;
  margin: 0;
  font-size: 36px;
  font-size: 3.6rem;
  color: #505050;
  text-transform: uppercase;
}
.sidebar .menu-page ul {
  list-style: none;
}
.sidebar .menu-page > ul {
  background: #505050;
}
.sidebar .menu-page > ul > li > a {
  display: block;
  padding: 10px 0 10px 30px;
  color: #fff;
  border-bottom: 1px solid #767676;
}
.sidebar .menu-page > ul > li > a:before {
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 10px;
  font-family: "FontAwesome";
  content: "\f178";
}
.sidebar .menu-page > ul > li:hover a,
.sidebar .menu-page > ul > li.current_page_item a,
.sidebar .menu-page > ul > li.current-menu-ancestor a,
.sidebar .menu-page > ul > li.current-menu-item a,
.sidebar .menu-page > ul > li.current-menu-parent a {
  color: #fccf01;
}
.sidebar .menu-page ul ul {
  display: none;
}
.sidebar .widget {
  background: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 3px;
}
.sidebar .widget .widget-title {
  color: #205b69;
  display: block;
  height: 28px;
  line-height: 28px;
  font-weight: 400;
  padding: 0;
  position: relative;
  margin: 0 0 10px;
  font-size: 18px;
  font-size: 1.8rem;
  border-bottom: 1px solid #999;
}
.sidebar .widget .widget-title a {
  color: #205b69;
  line-height: 28px;
}
.sidebar .widget .widget-content {
  padding: 5px 5px;
}
.sidebar .widget a:hover {
  text-decoration: underline;
}
.sidebar .widget .widget-content .support {
  display: table;
  width: 100%;
}
.sidebar .widget .widget-content .support li {
  display: table-row;
  float: left;
  list-style: none;
  padding: 0;
  margin-bottom: 10px;
  background: transparent;
}
.sidebar .widget .widget-content .support li a {
  display: table-cell;
  padding-right: 5px;
  vertical-align: middle;
}
.sidebar .widget .widget-content .support a img {
  display: block;
}
.sidebar .widget .widget-content .support span {
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
}
.sidebar .widget.ht-show-posts .entry {
  border-bottom: 0;
  padding: 0;
}
.sidebar .widget.ht-show-posts .entry:last-child {
  margin: 0;
}
.sidebar .widget .widget-content ul li {
  /* background: url(images/icon-arrow.png) left 9px no-repeat; */
  padding: 3px 0 3px 10px;
  border-bottom: 1px solid #eee;
}
.sidebar .menu-project {
  /* background: #F7DF42 url("images/bg_service.png"); */
  padding-bottom: 10px;
}
.intro-banner + #container .sidebar .menu-project {
  margin-top: -100px;
}
.sidebar .menu-project .menu-title {
  height: 60px;
  line-height: 60px;
  color: #01458c;
  text-transform: uppercase;
  padding: 0 20px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  font-size: 30px;
  font-size: 3rem;
  margin: 0;
}
.sidebar .menu-project ul {
  /* background: #205B69 url("images/bg_service.png"); */
}
.sidebar .menu-project ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  text-transform: uppercase;
}
.sidebar .menu-project > ul > li > a {
  color: #fff;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 5px 10px 5px 20px;
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
}
.sidebar .menu-project > ul > li > a:hover,
.sidebar .menu-project > ul > li.current-menu-item > a,
.sidebar .menu-project > ul > li.current-menu-ancestor > a,
.sidebar .menu-project > ul > li.current-menu-parent > a {
  color: #f7c51e;
  text-decoration: none;
}
.sidebar .menu-project > ul > li:not(:last-child) {
  border-bottom: 1px solid #1e7e91;
}
.sidebar .menu-project > ul > li > a:before {
  color: #fff;
  margin-right: 7px;
  font-family: "FontAwesome";
  content: "\f054";
  display: block;
  float: left;
  font-size: 10px;
  margin-top: 4px;
}
.sidebar .menu-project > ul > li:hover > a:before,
.sidebar .menu-project > ul > li.current_page_item > a:before,
.sidebar .menu-project > ul > li.current-menu-ancestor > a:before,
.sidebar .menu-project > ul > li.current-menu-item > a:before,
.sidebar .menu-project > ul > li.current-menu-parent > a:before,
.sidebar .menu-project > ul > li.current-product-ancestor > a:before {
  color: #f7c51e;
}
.sidebar .menu-project ul li ul {
  display: none;
  margin: 0px 10px 10px 30px;
  border: 0 none;
  display: block;
  left: 0;
  padding: 0;
  position: relative;
  top: 100%;
  white-space: normal;
}
.sidebar .menu-project ul li ul li {
  padding: 2px 5px;
}
.sidebar .menu-project ul li ul li:not(:last-child) {
  border-bottom: 1px dashed #226e7f;
}
.sidebar .menu-project ul li ul li a {
  color: #fff;
  font-size: 13px;
  font-size: 1.3rem;
  display: block;
}
.sidebar .menu-project ul li ul li a:before {
  content: "\f0da";
  display: inline-block;
  font-size: 13px;
  font-size: 1.3rem;
  margin-right: 5px;
  font-family: "FontAwesome";
  color: #f7c51e;
}
.sidebar .menu-project ul li ul li:hover,
.sidebar .menu-project ul li ul li.current_page_item,
.sidebar .menu-project ul li ul li.current-menu-ancestor,
.sidebar .menu-project ul li ul li.current-menu-item,
.sidebar .menu-project ul li ul li.current-menu-parent,
.sidebar .menu-project ul li ul li.current-product-ancestor {
  background: #226e7f;
}
.sidebar .textwidget {
  text-align: center;
}
/* SINGLE */
h1.entry-title {
  font-size: 30px;
  font-size: 3rem;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 1.4;
  color: #4a4542;
  position: relative;
  padding-bottom: 15px;
}
h1.entry-title:after {
  border-bottom: 4px solid #da5b78;
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 75px;
}
.single .intro-banner .layer_back,
.page-template-contact-form .intro-banner .layer_back {
}

.accommodation .button-price {
  background-color: #fce186;
  text-align: center;
  padding-bottom: 160px;
}
.postid-434 .button-price,
.postid-1727 .button-price,
.postid-81 .button-price {
  text-align: center;
  padding-bottom: 40px;
}
.postid-81 a#price_list {
  position: static;
}

.accommodation .button-price a,
.postid-434 .button-price a,
.postid-1727 .button-price a,
.postid-81 a#price_list {
  background-color: #f54989;
  color: #fff;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 10px 20px;
  border-radius: 4px;
}

.accommodation #flex {
  margin-top: -120px;
}

.single-project .intro-banner.parallax,
.page-template-contact-form .intro-banner.parallax {
}
.single .countrys,
.page .countrys,
.category .countrys {
  background: none;
  border: none;
  border-radius: 0;
  margin-bottom: 5px;
  margin: auto;
  width: 50%;
}
.single .countrys,
.page .countrys {
  padding: 30px 0 5px;
}
.category .countrys {
  width: 100%;
  padding-bottom: 0px;
}
.category .countrys .service-category {
  margin-bottom: 25px;
}
.category .countrys .service-category.active {
  margin-bottom: 0;
}
.single .countrys .slides,
.page .countrys .slides,
.category .countrys .slides {
  text-align: center !important;
}
.single .countrys .service-category,
.page .countrys .service-category,
.category .countrys .service-category {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  margin-right: 2px !important;
  opacity: 1 !important;
  width: auto !important;
}
.single .countrys .service-category a,
.page .countrys .service-category a,
.category .countrys .service-category a {
  display: block;
  padding: 5px 20px;
  background: #545454;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-family: opensans;
  font-size: 14px;
  border-radius: 20px;
  transition: 0.4s all ease;
}
.single .countrys .service-category.flex-active-slide:after,
.page .countrys .service-category.flex-active-slide:after {
  content: "";
  height: 0px;
  width: 0px;
  border-bottom: 10px solid #f54989;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -30px;
  left: 0;
  right: 0;
}
.category .countrys .service-category.active:after {
  content: "";
  height: 0px;
  width: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 0px;
  left: 0;
  right: 0;
}
.single .nz .countrys .service-category.flex-active-slide:after,
.page .nz .countrys .service-category.flex-active-slide:after {
  border-bottom: 10px solid #ffb05b;
}
.single .us .countrys .service-category.flex-active-slide:after,
.page .us .countrys .service-category.flex-active-slide:after,
.single .ca .countrys .service-category.flex-active-slide:after,
.page .ca .countrys .service-category.flex-active-slide:after {
  border-bottom: 10px solid #3c8dd9;
}
.single .countrys .service-category a:hover,
.page .countrys .service-category a:hover,
.single .countrys .service-category.flex-active-slide a,
.page .countrys .service-category.flex-active-slide a,
.category .countrys .service-category a:hover,
.category .countrys .service-category.active a {
  background: #f54989;
}
.single .nz .countrys .service-category a:hover,
.page .nz .countrys .service-category a:hover,
.single .nz .countrys .service-category.flex-active-slide a,
.page .nz .countrys .service-category.flex-active-slide a {
  background: #ffb05b;
}
.single .us .countrys .service-category a:hover,
.page .us .countrys .service-category a:hover,
.single .us .countrys .service-category.flex-active-slide a,
.page .us .countrys .service-category.flex-active-slide a,
.single .ca .countrys .service-category a:hover,
.page .ca .countrys .service-category a:hover,
.single .ca .countrys .service-category.flex-active-slide a,
.page .ca .countrys .service-category.flex-active-slide a {
  background: #3c8dd9;
}
.single .excerpt_countrys,
.page .excerpt_countrys {
  background: #f54989;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}
.single .nz .excerpt_countrys,
.page .nz .excerpt_country {
  background: #ffb05b;
}
.single .us .excerpt_countrys,
.page .us .excerpt_countrys,
.single .ca .excerpt_countrys,
.page .ca .excerpt_countrys {
  background: #3c8dd9;
}
.single .excerpt_countrys .title,
.page .excerpt_countrys .title {
  display: inline-block;
  padding: 5px 20px;
  background: #fff;
  text-align: center;
  color: #545454;
  text-transform: none;
  font-family: opensansL;
  font-size: 14px;
  border-radius: 20px;
  vertical-align: middle;
  margin: 30px auto;
  transition: 0.4s all ease;
}
.single .excerpt_countrys .title:hover,
.excerpt_countrys .title:hover,
.single .excerpt_countrys .title.active,
.excerpt_countrys .title.active {
  background: #545454;
  color: #fff;
}
.excerpt_countrys .service-category {
  text-align: center;
}
.single #content,
.page #content {
  background: #fff;
  padding: 55px 15%;
}
.single #content.accommodation,
.single .nz #content,
.single .us #content,
.single .ca #content,
.page .nz #content {
  padding: 70px 0;
  text-align: center;
}
#service-suport.accommodation .slides li form .clm-content-item {
  min-height: 150px;
}
.single #content.accommodation .ctn_left p,
.page #content.accommodation .ctn_left p,
.single #content.guardian_service .ctn_left p,
.page #content.guardian_service .ctn_left p {
  background: none;
  border: none;
}

.page #wrap {
  background-color: #fff;
}

.page #wrap .fix-width {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 20px 10px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.page #service-suport .title-register {
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
}

.clm-show-view-more {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  line-height: 60px;
}

#show-view {
  background: #fff;
  border: none;
  border-radius: 0;
  margin: 0;
}
.show-view {
  background: #f2f2f2;
}
#show-view .para {
  /*width: calc(63% - 30px);*/
  width: 100%;
  float: left;
  padding-right: 30px;
}
#show-view .para:nth-child(2) {
  padding-left: 30px;
  padding-right: 0;
  border-left: 1px #d8cc88 solid;
  width: calc(37% - 31px);
}
#show-view .para {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #4a4a4a;
}
#show-view .para ul {
  font-family: Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  line-height: 32px;
  color: #4d4d4d;
  list-style: none;
  margin-left: 0;
}
/* #show-view .para ul li{
    padding-left:30px;
    background: url('../defaultImages/icon_yess.png') no-repeat center left;
} */
#show-view .show-view-item {
  float: left;
}
.single #content .quick,
.page #content .quick {
  text-align: center;
  background: #f54989;
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 24px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  width: 17%;
  float: right;
  cursor: pointer;
}
.single .uc #content .quick {
  padding: 40px 3%;
}
.single .nz #content .quick,
.single .us #content .quick,
.single .ca #content .quick,
.page .nz #content .quick {
  background: #90ed74;
  padding: 40px 3%;
}
.postid-83 #content .quick {
  padding: 40px 3%;
}
.single .us #content .quick,
.single .ca #content .quick,
.page .us #content .quick,
.page .ca #content .quick {
  background: #d0ea41;
}
.single #content .quick1 {
  background: #f54989;
  color: #fff;
  padding: 0 5%;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 24px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  width: 9%;
  float: right;
  cursor: pointer;
}
.single .us #content .quick1,
.single .ca #content .quick1 {
  background: #90ed74;
  padding: 40px 3%;
}
.single .us #content .quick1,
.single .ca #content .quick1 {
  background: #d0ea41;
}
.single #content.telephone,
.page #content.telephone {
  padding: 110px 0 63px;
}
.single #content.telephone .image,
.page #content.telephone .image {
  background: none;
  color: #fff;
  font-size: 24px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  float: left;
  padding-left: 20px;
  border-left: 1px #ccc solid;
  width: calc(22% - 21px);
}
.single #content.telephone .image {
  width: calc(19% - 21px);
}
.single #content.telephone .ctn_left p {
  color: #000000;
  padding-left: 120px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
.single #content.telephone .ctn_left,
.page #content.telephone .ctn_left {
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  width: calc(78% - 20px);
  float: left;
  margin-right: 20px;
  text-align: right;
}
.single #content.telephone .ctn_left p:first-child,
.page #content.telephone .ctn_left p:first-child {
  background: none;
}
.page #content.telephone .ctn_left p {
  border-bottom: 1px dashed #ccc;
  margin: 0;
  padding-bottom: 10px;
  padding-top: 70px;
  padding-left: 400px;
}
.single #content .ctn_left p:last-child,
.page #content .ctn_left p:last-child {
  border-bottom: none;
}
#template-banking .ctn_left p {
  background: none !important;
}
.hvr-float-shadow {
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  display: inline-block;
  position: relative;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: transform;
  vertical-align: middle;
}
.hvr-float-shadow::before {
  background: rgba(0, 0, 0, 0)
    radial-gradient(
      ellipse at center center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    )
    repeat scroll 0 0;
  content: "";
  height: 10px;
  left: 5%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  width: 90%;
  z-index: -1;
}
.hvr-float-shadow:hover,
.hvr-float-shadow:focus,
.hvr-float-shadow:active {
  transform: translateY(-5px);
}
.hvr-float-shadow:hover::before,
.hvr-float-shadow:focus::before,
.hvr-float-shadow:active::before {
  opacity: 1;
  transform: translateY(5px);
}
.single #content .ctn_left,
.page #content .ctn_left {
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  width: calc(81% - 40px);
  float: left;
  margin-right: 40px;
}
.single #template-default.us #content .ctn_left,
.single #template-default.ca #content .ctn_left {
  font-size: 20px !important;
  font-family: Arial, sans-serif !important;
  font-style: normal !important;
  font-variant: normal !important;
}
.single #content.guardian_service .ctn_left,
.page #content.guardian_service .ctn_left {
  font-family: Arial, sans-serif;
  font-weight: bold;
}
.single #content .ctn_left p,
.page #content .ctn_left p {
  border-bottom: 1px dashed #ccc;
  margin: 0;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-left: 90px;
}
.single .nz #content .ctn_left p,
.single .us #content .ctn_left p,
.single .ca #content .ctn_left p,
.page .nz #content .ctn_left p {
  border-bottom: none;
}
.single #content .ctn_left p:first-child,
.page #content .ctn_left p:first-child {
  background: url("../defaultImages/ct_1.png") no-repeat left center;
}
.single #content .ctn_left p:nth-child(2),
.page #content .ctn_left p:nth-child(2) {
  background: url("../defaultImages/ct_2.png") no-repeat left center;
}
/* .single #content .ctn_left p:last-child,
.page #content .ctn_left p:last-child,
.single #content .ctn_left.427 p:last-child
{
    border-bottom: none;
} */
.single #content.guardian_service,
.page #content.accommodation,
.page #content.guardian_service {
  padding: 40px 0;
}
.single #content.accommodation .ctn_left p,
.single #content.guardian_service .ctn_left p,
.single .nz #content .ctn_left p,
.page #content.accommodation .ctn_left p,
.page #content.guardian_service .ctn_left p,
.page .nz #content .ctn_left p,
.single .us #content .ctn_left p,
.single .ca #content .ctn_left p {
  background: none;
}

.single .us #content .ctn_left,
.single .ca #content .ctn_left,
.single #content.accommodation .ctn_left,
.single #content.guardian_service .ctn_left,
.single .nz #content .ctn_left,
.page #content.accommodation .ctn_left,
.page #content.guardian_service .ctn_left,
.page .nz #content .ctn_left {
  /*float:right;*/
  text-align: right;
  width: 80%;
  margin-right: 0px;
}
.postid-581 .us #content .ctn_left,
.postid-1730 .ca #content .ctn_left,
.postid-578 .nz #content .ctn_left,
.postid-82 .uc #content .ctn_left,
.postid-84 .uc #content .ctn_left {
  width: 100%;
}
.postid-578 .nz #content .ctn_left,
.postid-82 .uc #content .ctn_left,
.postid-581 .us #content .ctn_left,
.postid-1730 .ca #content .ctn_left {
  margin: 37px 0px;
}

/**/
.single #template-default.us #content .ctn_left:not(.427),
.single #template-default.ca #content .ctn_left:not(.1313) {
  float: right;
  text-align: right;
  width: 85%;
  margin-right: 0;
}
.single .nz #content .ctn_left,
.page .nz #content .ctn_left,
.single .us #content .ctn_left {
  /*margin-right: 40px;*/
}
/* .single .us #content .ctn_left.427, .single .ca #content .ctn_left.1313{
	margin-right: 40px !important;
} */
.single .us #content .ctn_left ul,
.single .ca #content .ctn_left ul {
  list-style: none;
}

.single #content.guardian_service .ctn_left p,
.page #content.accommodation .ctn_left p,
.page #content.guardian_service .ctn_left p {
  font-family: Arial, sans-serif;
  font-weight: bold;
  padding-right: 45px;
}
.single :not(#template-default) #content.accommodation .ctn_left p {
  font-family: Arial, sans-serif !important;
  font-style: normal !important;
  font-variant: normal !important;
}
.single #content.accommodation .ctn_left ul,
.single #content.guardian_service .ctn_left ul,
.page #content.accommodation .ctn_left ul,
.page #content.guardian_service .ctn_left ul {
  list-style: none;
}
/* .single #content.accommodation .ctn_left ul li,
.page #content.accommodation .ctn_left ul li{
    background: url('../defaultImages/icon_muiten.png') no-repeat right center;

    padding-right: 45px;
}
.single #content.guardian_service .ctn_left ul li,
.page #content.guardian_service .ctn_left ul li
{
    background: url('../defaultImages/icon_bullet_circle.png') no-repeat right center;
    padding-right: 35px;
}

.single .nz #content .ctn_left ul li,
.page .nz #content .ctn_left ul li{
    background: url('../defaultImages/icon_muitenc.png') no-repeat right center;
	padding-right: 45px;
}
.single #template-default.us #content .ctn_left ul li, .single #template-default.ca #content .ctn_left ul li{
	background: url('../defaultImages/icon_muitenx.png') no-repeat right center;
	padding-right: 45px;
} */
.single #content.accommodation .ctn_left ul li,
.single .nz #content .ctn_left ul li,
.page .nz #content .ctn_left ul li,
.single #template-default.us #content .ctn_left ul li,
.single #template-default.ca #content .ctn_left ul li {
  font-size: 18px !important;
  font-family: Arial, sans-serif;
  font-style: italic;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 2px;
  color: #545454;
}
.single .nz #content .ctn_left ul,
.page .nz #content .ctn_left ul {
  list-style: none;
}
#more-content-ovhc,
#more-content-oshc {
  background: #f6f6f6;
  padding-bottom: 30px;
}
#more-content-ovhc .flexslider,
#more-content-oshc .flexslider {
  background: #f6f6f6;
  border: none;
}
#more-content-ovhc .section-title,
#more-content-oshc .section-title {
  margin: 0;
  padding: 40px 0 40px;
}
.entry .post_date {
  display: none;
}
.entry .entry-content {
  text-align: justify;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 16px;
  line-height: 1.7;
  color: #414141;
}
.entry .entry-content img {
  max-width: 100%;
}
.post-related {
  margin-top: 15px;
}
.entry-related {
  clear: both;
  background: #f5f5f5;
}
.entry-related .archive-title {
  color: #b62e3b;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 40px;
  text-shadow: 0;
}
.entry-related .archive-title span {
  margin: 0;
  background: #fff;
  padding: 10px 15px;
  line-height: 40px;
}
.entry .socials {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  list-style: outside none none;
  margin: 10px 0;
  padding: 5px 0;
  width: 100%;
}
.single .info-event {
  margin-bottom: 10px;
}
.single .info-event .thumb-event {
  width: 42%;
  float: left;
  padding: 1%;
  border: 1px solid #eee;
}
.single .info-event .thumb-event img {
  display: block;
}
.single .info-event .meta-event {
  width: 53%;
  float: right;
  list-style: none;
  margin: 0;
}
.single .info-event .meta-event li {
  padding: 5px 0;
  border-bottom: 1px dashed #ddd;
}
.single .info-event .meta-event .entry-title {
  border: 0;
  margin: 0;
}
.single .info-event .meta-event .time,
.single .info-event .meta-event .date {
  color: #f89b24;
  font-size: 18px;
  font-size: 1.8rem;
}
.single .info-event .meta-event .address {
  color: #01458e;
  font-weight: 600;
}
.single .info-event .meta-event .price {
  padding: 2px 10px;
  border-radius: 3px;
  color: #f89b24;
  background: #f5f5f5;
  font-weight: 600;
  font-size: 18px;
  font-size: 1.8rem;
}

#show-view {
  display: none;
}

/*==== Navi Page ====*/
.navi_page {
  list-style: none;
  margin: 20px 0;
}
.navi_page > a,
.navi_page > span {
  float: left;
  padding: 5px 10px;
  border: 1px solid #ffd100;
  font-size: 12px;
  color: #333;
  border-right: 0px;
}

.navi_page span:last-child,
.navi_page .last {
  border: 1px solid #fecd41;
}

.navi_page > a:hover,
.navi_page .current {
  background: #fecd41 !important;
  color: #fff !important;
}
/* Comment */
.comments_template {
}

.comments_template #reply-title {
  margin: 0 0 10px;
  font-size: 18px;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  color: #4d4d4d;
}

#commentform .required {
  float: left;
  margin-left: 20px;
  color: #f00;
}
#commentform .comment-notes .required {
  margin-left: 0;
}
.comments_template #respond {
  clear: both;
}
.comments_template .commentlist > li {
  border-bottom: none !important;
  margin-bottom: 10px;
}
.comments_template #commentform label {
  width: 70px;
  text-align: right;
  display: inline-block;
  padding-right: 5px;
  float: left;
}
.comments_template #commentform #subscribe-label,
.comments_template #commentform #subscribe-blog-label {
  float: none;
}
.comments_template .commentlist {
  list-style: none;
  margin: 0 0 10px;
}
.comments_template .commentlist > li {
  border-bottom: 1px solid #eee;
  padding: 5px;
  background: #f5f5f5;
}
.comments_template .commentlist > li:nth-child(even) {
  background: #dce8ef;
}
.comments_template .commentlist .children {
  border: 1px solid #ccc;
  border-radius: 3px;
}
.comments_template .commentlist .children li {
  border-bottom: 1px dashed #eee;
  margin-bottom: 5px;
  padding: 5px;
}
.comments_template .commentlist > li:last-child,
.comments_template .commentlist .children li:last-child {
  border: 0;
  margin-bottom: 0;
}
.comments_template .comment-meta {
  font-size: 12px;
  color: #555;
}
.comments_template .comment-meta a {
  color: #555;
}
.comments_template .reply {
}
.comments_template .reply a {
  color: #548724;
  display: inline-block;
  background: #ebf4fc;
  border-radius: 4px;
  padding: 3px 10px;
  border: 1px solid #ddd;
}
.comments_template #respond {
  background: #fff none repeat scroll 0 0;
  border-top: 2px solid #bbb;
  padding: 30px 0;
  font-family: Arial, sans-serif;
}
.parallax.intro-banner iframe {
  width: 100%;
  position: absolute;
  z-index: -1;
}
.home-about-us .ht-video-show-thumb {
  text-align: center;
  padding: 0;
  width: 100%;
  height: 200px;
  position: relative;
}
.home-about-us .ht-video-show-thumb .content-video {
  display: none;
}
.home-about-us .ht-video-show-thumb .ht-video-show-thumb iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.ht-video-show-thumb:not(.play)::before {
  background: rgba(255, 255, 255, 0.5) url("../defaultImages/play_media.png")
    no-repeat scroll center center;
  bottom: 0;
  content: "";
  cursor: pointer;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.ht-video-show-thumb {
  height: 281px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.ht-video-show-thumb object,
.ht-video-show-thumb embed {
  width: 100%;
  height: 100%;
}
#go_top {
  display: none;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: fixed;
  right: 10px;
  bottom: 55px;
  border-radius: 4px;
  box-shadow: 0 1px 2px #eee;
  z-index: 999;
}
/* FOOTER */

footer.footer {
  background: #e9e9e9 url("../defaultImages/bg-row.png") repeat;
  color: #4d4d4d;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  padding: 60px 0 0px;
}
footer.footer .information-company {
  padding-bottom: 25px;
}
footer.footer .title_footer {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #4d4d4d;
  margin-left: 20px;
}
.about .title_footer {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  width: 100%;
  float: right;
}
footer.footer .country {
  padding: 20px 0 0px;
}
footer.footer .country .un {
  position: relative;
  z-index: 1;
  margin-left: 20px;
}
footer.footer .country .locate {
  margin-top: 10px;
}
/* footer.footer .country .locate{
    background: url('../defaultImages/map.png') no-repeat left center;
    padding-left: 30px;
}
footer.footer .country .call{
    background: url('../defaultImages/call.png') no-repeat left center;
    padding-left: 30px;
}
footer.footer .country .mail{
    background: url('../defaultImages/thu.png') no-repeat left center;
    padding-left: 30px;
} */
footer.footer .country .un:after {
  content: "";
  border-bottom: 1px solid #b1b1b1;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  z-index: -1;
}
footer.footer .country .name {
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: bold;
  background: #f54989;
  padding: 3px 10px;
}
footer.footer .about .content {
  float: right;
  position: relative;
}
footer.footer .about .content:before {
  content: "";
  background: #fff;
  position: absolute;
  left: 20%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
footer.footer .about .content .video {
  float: left;
  position: relative;
  width: 35%;
}
footer.footer .about .content .text {
  float: right;
  width: 60%;
  z-index: 1;
  position: relative;
  padding: 22px 0 2px;
}
footer.footer .about .content .text p {
  margin-bottom: 20px;
}
footer.footer .about a.more,
footer.footer .about a.policy,
footer.footer .about a.terms,
footer.footer .about a.refund {
  float: left;
  background: #f54989;
  color: #fff;
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding: 8px;
  margin: 2px;
}
/*footer.footer  .office .info .address:before{content:"\f041", font-family:"FontAwesome";}
footer.footer  .office .info .tel:before{content:"\f095";font-family:"FontAwesome";}
footer.footer  .office .info .fax:before{content:"\f1ac";font-family:"FontAwesome";}
footer.footer  .office .info .email:before{content:"\f0e0";font-family:"FontAwesome";}*/
footer.footer .contact-form {
  display: none;
}
footer.footer .contact-form input[type="text"],
footer.footer .contact-form textarea {
  color: #fff;
  background: #196693;
  padding: 0 10px;
  width: calc(100%);
  border-radius: none !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}
footer.footer .contact-form textarea {
  padding: 5px 10px;
  min-height: 100px;
  max-height: 100px;
  font-size: 13px;
}

footer.footer form.apart {
  padding-bottom: 10px;
}

footer.footer .contact-form input[type="text"]:hover,
footer.footer .contact-form input[type="text"]:focus,
footer.footer .contact-form textarea:hover,
footer.footer .contact-form textarea:focus {
  background: #fff;
  color: #333;
}
footer.footer .contact-form input[type="submit"] {
  color: #fff;
  background: rgba(0, 0, 0, 0) linear-gradient(#6c6a6a, #545353) repeat scroll 0
    0;
}
footer.footer .contact-form input[type="submit"]:hover {
  background: rgba(0, 0, 0, 0) linear-gradient(#f7ab47, #f89b1c) repeat scroll 0
    0;
  color: #fff;
}
footer.footer .bar-footer {
  padding: 10px 0;
  line-height: 25px;
  font-size: 15px;
  font-size: 1.5rem;
  background: #cdcdcd;
}
footer.footer .policy {
  float: right;
}
footer.footer .policy ul {
  margin: 0;
}
footer.footer .policy li {
  display: inline-block;
  color: #ffd100;
  list-style: none;
}
footer.footer .policy li a {
  color: #ccc;
  padding: 0 7px;
}
footer.footer .primary-menu-footer li ul {
  display: none;
}
footer.footer p {
  margin: 0;
}
footer.footer a:hover,
footer.footer #footer-menu ul li a:hover {
  color: #fecd41;
}
.fb-page {
  background: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 3px;
}
.container-search-form .search-form {
  /*width: 500px;*/
}
.container-search-form .search-form {
  border-radius: 0;
  font-size: 0;
  position: relative;
}
.container-search-form input[type="text"] {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #fff none repeat scroll 0 0;
  border-color: #ddd -moz-use-text-color #ddd #ddd;
  border-image: none;
  border-radius: 2px;
  border-style: solid none solid solid;
  border-width: 1px 0 1px 1px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 0 0 10px;
  vertical-align: middle;
  width: 230px;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #444;
}
.container-search-form input:hover,
.container-search-form .search-form button:hover,
.container-search-form input:focus,
.container-search-form .search-form button:focus {
  box-shadow: none;
}
.container-search-form .search-form button[type="submit"] {
  background: #0b90f6 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 0 2px 2px 0;
  bottom: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 26px;
  margin: auto;
  position: absolute;
  /*right: 0;*/
  top: 0;
  width: 40px;
}
.search .archive-title {
  color: #808080;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 10px auto 30px;
}
.bang {
  margin-top: 30px;
}
.bang .table {
  display: table;
}
.bang .table .row {
  display: table-row;
  float: none !important;
  padding: 0 !important;
  width: 100% !important;
}
.bang .title {
  color: #4d4d4d;
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.bang .title {
  color: #4d4d4d;
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 3px solid #f0ece7;
}
.bang .title:after {
  border-bottom: 10px solid #f0ece7;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -30px;
  content: "";
  height: 0;
  left: 0;
  right: 0;
  width: 0;
  margin: auto;
}

.bang .table-left .title,
.bang table th,
.bang .table-right .title {
  color: #4d4d4d;
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px !important;
}
.bang .table-left .title:after,
.bang .table-right .title:after {
  border-bottom: none;
  content: "";
}
.bang .table-left,
.bang .table-right {
  display: none;
}
.bang .table .col {
  display: table-cell;
}
.single-post section#click {
  display: none;
}
.comments_template .commentlist {
  border-top: 2px solid #bbb;
  padding: 30px 0;
  background: #fff;
  margin-top: 30px;
}
.comments_template .commentlist > li {
  background: #fff;
  padding: 0;
}
.comment-body img {
  float: left;
  width: 65px;
  margin-right: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.comment-author {
  float: right;
  width: calc(100% - 82px);
}
.comment-author {
  font-size: 12px;
  color: #bcbcbc;
  font-family: Arial, sans-serif;
}
.comment-author a {
  color: #bcbcbc;
}
.comment-body p {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #494949;
  margin-top: 10px;
  padding-top: 10px;
  position: relative;
}
.comment-body p:before {
  content: "";
  height: 5px;
  background: url("../defaultImages/bg-row.png") repeat center center;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.comments_template .commentlist > li:nth-child(2n) {
  background: #fff;
}
.wpcf7 {
  width: 50%;
  display: block;
  margin: 0 auto !important;
  background: #dcdcdc;

  border: 5px solid #a9a9a9;
}

.wpcf7-form {
  margin-left: 25px;

  margin-right: 25px;

  margin-top: 25px;
}

.wpcf7-textarea {
  width: 100%;
  height: 75px;
}
.wpcf7 input {
  width: 100%;
}
.wpcf7-text {
  width: 100%;
}
.wpcf7-submit {
  display: block;
  margin: 0 auto;

  background: #ff8c00;

  color: #ffffff;
}

#serach-school {
  margin: 0 10px;
  margin-bottom: 40px;
  display: none;
}
#serach-school .title-check {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-size: 2.4rem;
  line-height: 40px;
  text-align: center;
  color: #6e6e6e;
}
#main-qh-01 {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
}
#main-qh-01::after {
  clear: both;
  content: "";
  display: block;
}
#serach-school #dn_select_school {
  float: left;
  margin-right: 20px;
}
#serach-school #dn_select_school label {
  float: left;
  margin-right: 10px;
}
#serach-school #dn_select_school select {
  float: left;
}
#serach-school #select-schools {
  float: left;
  width: 45%;
}
#serach-school .search-school {
  float: left;
  width: 45%;
}
#serach-school .search-school input {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 17px;
  height: 35px;
  padding: 0 10px;
  width: 100%;
}
#serach-school .wrap-parent-school {
  float: left;
  position: relative;
  width: 100%;
}
#or {
  float: left;
  margin-top: 5px;
  text-align: center;
  width: 9%;
}
#serach-school .wrap-parent-school .parent-school {
  background-color: #fff;
  border: 1px solid #ddd;
  left: 0;
  list-style: outside none none;
  margin: 0;
  max-height: 400px;
  min-width: 94%;
  opacity: 0;
  overflow-y: scroll;
  padding: 10px;
  position: absolute;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  z-index: 9;
}
#serach-school .wrap-parent-school .parent-school::after {
  clear: both;
  content: "";
  display: block;
}
#serach-school .wrap-parent-school:hover .parent-school {
  opacity: 1;
  visibility: visible;
}
#serach-school .wrap-parent-school .parent-school .children-of {
  float: left;
  width: 50%;
}
#serach-school .wrap-parent-school .parent-school .children-of a {
  display: block;
  padding: 5px 0;
}
#serach-school .wrap-parent-school .parent-school .children-of a:hover {
  text-decoration: underline;
}
#serach-school #select-schools .label,
#serach-school .search-school .label {
  float: left;
  font-size: 1.3rem;
  font-weight: bold;
  margin-right: 10px;
}
#serach-school .wrap-parent-school .title-select {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #777;
  font-size: 17px;
  height: 35px;
  margin-bottom: 0;
  padding: 1px 30px 1px 10px;
  position: relative;
}
#serach-school .wrap-parent-school .title-select i {
  background-color: #ddd;
  height: 100%;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 18px;
}
.clearfix:after,
.fix-width:after,
form:after,
#topmenu:after,
.top-menu:after,
.branding .banner:after,
.main-menu:after,
nav#primary-menu ul:after,
#main:after,
.listing-events:after,
.widget-content:after,
.account-page .account-container #account-form:after,
article.entry:after,
.main-content:after,
footer.footer:after,
.home-about-us .blocks:after,
.archive-content:after,
.entry:after,
.single .info-event:after,
.single .entry-content:after,
.navi_page:after,
footer.footer .informations #footer-menu > ul:after,
.list-comments .footer-comment:after,
.search-content:after,
.section-content:after,
.groupnote .section-content .haft:after,
footer.footer #footer-menu ul:after,
#horiz_container_outer .service-category:after,
#horiz_container_outer .service-category .excerpt .row:after,
.step_top:after,
.step_mid:after,
.step_mid .wapm:after,
.steps .step_bottom:after,
.single #content:after,
#help-suport .section-content .how:after,
.oshc-clm-content:after,
.how:after,
#flex .main ul li:after,
#flex .main .title:after,
#flex .main:after,
.bang:after,
.comments_template .commentlist > li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

/* Template Study Tour */
#section_header .title {
  font-size: 24px;
  font-weight: bold;
  color: #f54989;
}
#section_header .navbar-toggle {
  width: 36px;
  height: 36px;
  position: relative;
  margin: 10px 0px;
  border: 0px;
  background: transparent;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#section_header .navbar-toggle:focus,
#section_header .navbar-toggle:hover {
  background: transparent;
}
#section_header .navbar-toggle span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #f54989;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#section_header .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 0px;
}

#section_header .navbar-toggle span:nth-child(1) {
  top: 0px;
}
#section_header .navbar-toggle span:nth-child(2),
#section_header .navbar-toggle span:nth-child(3) {
  top: 10px;
}
#section_header .navbar-toggle span:nth-child(4) {
  top: 20px;
}
#section_header .navbar-toggle.active span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}
#section_header .navbar-toggle.active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#section_header .navbar-toggle.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#section_header .navbar-toggle.active span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

#section_header .navbar-collapse {
  text-align: center;
  padding: 0px;
}
#section_header .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
  margin-top: auto;
  width: 100%;
}
#section_header .navbar-nav > li {
  width: 100%;
}
#section_header .navbar-nav > li > a {
  font-size: 19px;
  font-weight: bold;
  background: white;
  color: #606060;
  padding-top: 10px;
  padding-bottom: 10px;
}
#section_header .navbar-nav > li > a:hover,
#section_header .navbar-nav .active > a {
  background: #f54989;
  color: white;
}
#section_header .navbar-nav {
  margin-top: auto;
}
#section_video iframe,
#section_comments .image img,
#section_news .first img,
#section_us .subscribe img {
  width: 100%;
}
#section_program .block-info > p,
#section_program .program_title,
#section_us .block-info > p,
#section_news .block-info > p {
  font-size: 24px;
  font-weight: bold;
  color: #f54989;
}
#section_program .block-info img,
#section_us .choose_img {
  width: 40px;
}
#section_us .hr_title,
#section_news .hr_title {
  width: 100%;
}
#section_program .block-info ul {
  list-style: none;
  margin: 20px;
  margin-left: 0px;
}
#section_us.block-info ul {
  margin: 20px;
}
#section_program .block-info,
#section_program .program_title,
#section_program .program_content,
#section_us .block-info {
  margin: 10px;
}
#section_program .block-info ul > li {
  border-bottom: solid 1px;
  padding: 15px 10px;
}
#section_us .block-info ul > li {
  text-align: justify;
  list-style-position: outside;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
}
#section_us form#mc4wp-form-2 {
  position: absolute;
  bottom: 30px;
  width: calc(100% - 30px);
  text-align: center;
}
#section_program .block-info ul > li:last-child {
  border-bottom: none;
}
#section_program .block-info ul > li > a {
  font-size: 16px;
  color: #606060;
}
#section_program .block-info ul > li > a:hover,
#section_news .first:hover,
#section_news .first a:hover {
  color: #f54989;
}
#section_program .content {
  border-left: none;
}
#section_program .program_title {
  text-transform: uppercase;
}
#section_comments .comment_content {
  font-weight: bold;
}
#section_program .program_content,
#section_comments .comment_content,
#section_news .first p {
  text-align: justify;
}
#section_news .first p {
  line-height: 1.5;
}
#section_comments .image {
  padding: 0px;
}
#section_comments .bx-wrapper {
  border: none;
}
.section_background {
  background: white;
}
.section_background_gallery {
  background-image: url("https://oshcstudents.com.au/wp-content/uploads/2016/01/background_gallery.png");
  background-repeat: no-repeat, repeat;
  background-size: cover;
}
#section_video,
#section_program {
  padding-bottom: 30px;
}
#section_comments,
#section_us,
#section_news {
  padding-top: 30px;
}
#section_comments .comment_name {
  font-size: 24px;
  font-weight: bold;
  color: #f54989;
}
#section_comments .comment_title {
  font-size: 14px;
  color: #f54989;
  font-style: italic;
}
#section_comments .content {
  padding-top: 15px;
}
#section_us .content a {
  padding: 15px 10px;
  background: #f54989;
  color: white;
  font-weight: bold;
  border-radius: 15px;
}
#section_us .sign_up {
  text-align: center;
  padding: 25px 0px;
}
#section_news .date_views {
  color: #808080;
  font-size: 85%;
}
#section_news .row {
  margin: 0 -15px;
}
#section_comments .row,
#section_us .row,
#section_gallery .row,
#section_program .row {
  margin: 0;
}
#section_news .first-title,
#section_news .first-title > a,
#section_news .next-title,
#section_news .next-title > a {
  font-size: 14px;
  color: #414141;
}
#section_news .next-title,
#section_news .next-title > a {
  margin: 1px 0;
}
#section_gallery .bx-wrapper {
  moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background: none;
}
#section_gallery .list_img img {
  border: solid white 2px;
}
#section_gallery .albumslider img {
  border: solid white 1px;
}
#section_gallery {
  padding-top: 40px;
  padding-bottom: 1px;
}
#section_gallery .icon_gallery {
  position: absolute;
  z-index: 1;
  width: 20%;
}
#videoModal .modal-dialog {
  width: auto;
}

/* Responsive */
@media only screen and (min-width: 320px) {
  .modal-dialog {
    margin: 80px auto !important;
  }
}
@media only screen and (max-width: 359px) {
  .site-title {
    font-size: 12px;
    font-size: 1.2rem;
  }
  .logo-img {
    max-width: 50px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 399px) {
  #topmenu ul.account > li.language {
    padding: 0;
  }
  #topmenu ul.account > li.language > a {
    text-indent: -9999px;
    overflow: hidden;
    padding: 0 15px;
    width: 0 !important;
  }
  .menu-mobile {
    right: 10px;
  }
  #services .listing-services .service {
    width: 100%;
    margin-right: 30px;
  }
  #events .listing-events .old-events .entry .entry-title {
    font-size: 12px;
    font-size: 1.2rem;
  }
  #events .listing-events .old-events .entry .datetime {
    display: none;
  }
  .postid-210 #service-suport .slides li .service-id-1 .clm-content-item {
    min-height: 190px;
  }
}

@media only screen and (min-width: 400px) {
}
@media only screen and (max-width: 479px) {
  .modal-body {
    overflow: scroll;
  }
  .modal-body .img-responsive {
    width: 800px;
    max-width: 1000px;
  }
  .logo-img {
    max-width: 70px;
    margin-right: 5px;
  }
  .site-title {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .site-description {
    font-size: 13px;
    font-size: 1.3rem;
    margin-right: 0px;
  }
  #service-suport.accommodation .slides li form .clm-content-item {
    min-height: 200px;
  }
  #template-airport_pickup #service-suport .slides li form a.quote {
    margin-top: 10px;
  }
  #template-airport_pickup
    #service-suport
    .slides
    li
    form
    input[type="submit"] {
    margin: 10px 20px 0px;
  }
}

@media only screen and (min-width: 480px) {
  #customer .slides .customer {
    margin-right: 20px;
    width: calc((100% - 40px - (20px * 2)) / 3);
  }
  .archive .archive-services .service {
    width: 48%;
    margin-left: 4%;
    float: left;
  }
  .archive .archive-services .service:nth-child(2n + 1) {
    clear: left;
    margin-left: 0;
  }
  .archive .archive-services .service:nth-child(1),
  .archive .archive-services .service:nth-child(2) {
    margin-top: 0;
  }
}
@media only screen and (max-width: 579px) {
  iframe.iframe_video {
    width: 100%;
  }
}

@media only screen and (min-width: 580px) {
  footer.footer #footer-menu {
    width: 58%;
    margin-right: 2%;
    float: left;
  }
  footer.footer .list-offices {
    width: 20%;
    float: left;
  }
}

@media only screen and (min-width: 1750px) {
  .other-services {
    margin-left: 22%;
  }
}
@media only screen and (max-width: 639px) {
  #topmenu li.search {
    display: none;
  }
  .site-title {
    font-size: 17px;
    font-size: 1.7rem;
  }
  .site-description {
    font-size: 26px;
    font-size: 2.6rem;
  }
  #services .listing-services .service {
    width: calc((100% - 30px) / 2);
    margin-right: 30px;
  }
}
@media only screen and (min-width: 640px) {
  .site-title {
    font-size: 23px;
    font-size: 2.3rem;
  }
  .site-description {
    font-size: 23px;
    font-size: 2.3rem;
  }

  #customer .slides .customer {
    margin-right: 30px;
    width: calc((100% - 40px - (30px * 2)) / 3);
  }

  footer.footer .copyright {
    width: 50%;
    color: #4d4d4d;
    font-size: 12px;
    vertical-align: middle;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }
  footer.footer .copyright.right {
    text-align: right;
    font-size: 12px;
    text-transform: none;
    font-family: Arial, sans-serif;
    font-weight: lighter;
    color: #111;
  }
  .home-about-us .our-story {
    width: 65%;
    float: left;
  }
  .home-about-us .video {
    float: right;
    width: 30%;
  }
  .home-about-us .large-block > .block {
    width: 48%;
    float: left;
    margin-left: 4%;
  }
  .home-about-us .large-block > .block:nth-child(2n + 1) {
    margin-left: 0;
  }
  .sidebar .widget {
    width: calc(48% - 10px);
    margin-left: 4%;
    float: left;
  }
  .sidebar .widget:nth-child(2n + 1) {
    margin-left: 0;
    clear: left;
  }
  .archive .archive-content > .entry {
    height: 400px;
    width: 23%;
    margin-right: 11px;
    float: left;
  }

  .sidebar .archive .archive-content > .entry {
    width: 100%;
    margin-right: 0;
    float: none;
    margin-bottom: 30px;
  }
  .sidebar .archive .archive-title {
    color: #fff;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 0px;
    text-transform: uppercase;
    text-shadow: none;
    margin-bottom: 25px;
  }
  .sidebar .archive .archive-title span {
    background: #f54989;
    padding: 10px 15px;
    position: relative;
    font-size: 12px;
  }
  .sidebar .archive .archive-title span:after {
    border-bottom: 19px solid transparent;
    border-left: 19px solid #f54989;
    border-top: 18px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: -19px;
    top: 0;
    width: 0;
  }
  .title_back {
    padding-left: 19px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 0px;
    text-transform: uppercase;
    text-shadow: none;
    margin-bottom: 25px;
  }
  .title_back a {
    background: #d2d2d2;
    padding: 10px 15px;
    position: relative;
    font-size: 12px;
    color: #414141;
  }
  .title_back a:after {
    border-bottom: 19px solid transparent;
    border-right: 19px solid #d2d2d2;
    border-top: 18px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    left: -19px;
    top: 0;
    width: 0;
  }
  /* delete 9/9/2016 by Bach
	.archive .archive-content > .entry:nth-child(4n+1){
        clear: both;
    }*/
  .template-contact-us .entry-content .apart {
    width: 48%;
    float: left;
    margin-top: 10px;
    margin-left: 4%;
  }
  .template-contact-us .entry-content .apart {
    width: calc(48% - 20px);
  }
  .template-contact-us .entry-content .apart:nth-child(2n + 1) {
    margin-left: 0;
    clear: left;
  }
}
@media only screen and (max-width: 679px) {
  footer.footer .bar-footer {
    padding-bottom: 40px;
    text-align: center;
  }
  .nz_ac,
  .us_ac,
  #service-suport .map,
  .h4_ac,
  .bang,
  .us_oshc {
    display: none;
  }
  #divAdRight {
    width: 131px;
  }
  #divAdRight > a > img {
    width: 130px;
  }
}
#wrap table td.center_apply {
  vertical-align: middle;
  text-align: center;
}
#wrap table.border.stacktable.small-only td.st-key {
  font-weight: bold;
}
#wrap table.border.stacktable.small-only th {
  color: #4d4d4d;
}
@media only screen and (max-width: 859px) {
  #wrap .list-title {
    display: none;
  }
  /*
	#wrap table td.center_apply{
		display: block;
		text-align: right;
	}
	#wrap table td.center_apply:first-child{
		text-align: center;
	}
	#wrap table td.center_apply:before {
		vertical-align: middle;
		content: attr(data-label);
		float: left;
		font-weight: bold;
    }*/

  #topmenu ul.account > li.language a {
    padding-right: 5px;
  }
  #topmenu ul.account > li.language a.active {
    display: none;
  }
  #services .listing-services .service {
    width: calc((100% - 30px * 2) / 3);
    margin-right: 30px;
  }
  .single #content {
    padding: 80px 1%;
  }
  .single #content.telephone .image {
    width: 100%;
    border-left: none;
    padding-left: 0px;
    text-align: center;
  }
  .single #content.telephone .ctn_left {
    width: auto;
  }
  .single #content.telephone {
    padding: 30px 10px;
  }
  #template-airport_pickup #service-suport .slides li form .clm-content-item {
    min-height: 150px;
  }
}
@media only screen and (min-width: 860px) {
  #videoModal .modal-dialog {
    width: 800px;
  }

  #topmenu ul.account > li.language > a {
    border-left: 1px solid #dbdbdb;
  }
  #topmenu ul.account > li.language a.active,
  #topmenu ul.account > li.language a:hover {
    border-left-color: #bfe896;
  }
  #topmenu ul.account > li.language a.active + a,
  #topmenu ul.account > li.language a + a {
    border-left-color: #bfe896;
  }
  #topmenu ul.account > li.language a.active + a:hover,
  #topmenu ul.account > li.language a:hover + a.active {
    border-left-color: #baebf4;
  }
  #topmenu ul.account > li.language a:first-child {
    border-left: 0;
  }
  #customer .slides .customer {
    margin-right: 30px;
    width: calc((100% - 40px - (30px * 4)) / 5);
  }
  footer.footer #footer-menu {
    width: 40%;
    float: left;
  }
  footer.footer .list-offices {
    float: left;
    width: 20%;
  }
  footer.footer .about {
    width: 75%;
    float: right;
    margin-left: 3%;
  }
}
@media only screen and (max-width: 959px) {
  .modal-body {
    overflow: scroll;
  }
  .modal-body .img-responsive {
    max-width: 1000px;
  }

  #secondary {
    margin-top: 20px;
  }
  nav#primary-menu {
    display: none;
  }
  .menu-mobile {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    background: #205b69;
    color: #fff;
    font-size: 22px;
    font-size: 2.2rem;
    margin: auto;
    cursor: pointer;
    border-radius: 2px;
    z-index: 2;
  }
  #mobile-menu {
    z-index: 16000002;
  }
  .menu-mobile:hover {
    background: #fecd41;
  }
  .overlay-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease;
  }
  .show-menu-mobile .overlay-background {
    opacity: 1;
    visibility: visible;
  }
  #mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 260px;
    background: #d8d8d8;
    font-size: 15px;
    font-size: 1.5rem;
    transition: 0.5s all ease;
    opacity: 0;
    visibility: hidden;
  }
  .show-menu-mobile #mobile-menu {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  #mobile-menu .close-menu-mobile {
    position: absolute;
    right: 100%;
    top: 0;
    padding: 0 15px;
    height: 45px;
    line-height: 40px;
    background: #f54989;
    color: #fff;
    font-size: 22px;
    font-size: 2.2rem;
    transition: 0.3s all ease;
    cursor: pointer;
  }
  #mobile-menu a {
    color: #292929;
    text-transform: uppercase;
  }
  #mobile-menu ul {
    list-style: none;
    margin: 0;
  }
  #mobile-menu > ul > li > a {
    padding: 7px 15px;
    display: block;
    transition: 0.3s all ease;
  }
  #mobile-menu .sub-menu {
    background-color: #fff;
    padding-left: 20px;
  }
  #mobile-menu .sub-menu a {
    font-size: 12px;
  }
  .news.events {
    display: none;
  }
  .news .entry .entry-meta,
  .news .entry .thumb {
    width: auto;
  }
  /* HEADER */
  .branding .banner {
    height: 185px;
  }
  .banner-re {
    display: none;
  }
  .site-information .socials::before {
    background-color: transparent;
  }
  div.search {
    display: none;
  }
  .language {
    display: none;
  }

  .menu-main {
    position: relative;
  }
  .menuright {
    width: auto;
    top: auto;
    bottom: 0;
    background-color: #f54989;
    padding: 0 7px;
  }
  #login-menu {
    width: auto;
    float: none;
  }
  .menu-mobile {
    top: auto;
    right: 195px;
    bottom: 7px;
  }
  .site-information .socials {
    width: auto;
    float: none;
  }
  .site-information {
    display: block;
    float: right;
    margin-top: 20px;
  }
  .group {
    display: none;
  }
  .logo_mobile {
    display: inline block;
  }
  .single .logo_mobile,
  .page .logo_mobile,
  .archive .logo_mobile {
    display: inline block;
  }
}
@media only screen and (max-width: 959px) {
  .single #primary:not(.fix-width) .tag {
    display: none;
  }
  .single .countrys .service-category a,
  .page .countrys .service-category a,
  .category .countrys .service-category a {
    font-size: 12px;
  }
}

@media only screen and (min-width: 960px) {
  .single .logo_mobile,
  .page .logo_mobile,
  .archive .logo_mobile {
    display: none;
  }
  .single #primary:not(.fix-width),
  .page #primary:not(.fix-width) {
    margin-left: 30px;
    width: 73%;
    float: left;
  }
  .page.page-id-14614 #primary:not(.fix-width) {
    width: 100% !important;
  }
  .single #secondary,
  .page #secondary {
    width: 23%;
    float: right;
    margin-top: 0;
  }
  .single #primary:not(.fix-width) > article {
    width: 83%;
    float: right;
  }
  .single #primary:not(.fix-width) > article .entry-tags {
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    color: #797979;
  }
  .single #primary:not(.fix-width) > article .entry-tags a {
    color: #aaaaaa;
  }
  .single #primary:not(.fix-width) .tag_mobile {
    display: none;
  }

  .single #primary:not(.fix-width) .tag {
    width: 12%;
    float: left;
    text-align: center;
    margin-top: 73px;
  }
  .single #primary:not(.fix-width) .tag .time {
    background: #f54989;
    color: #fff;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-variant: normal;
    font-size: 16px;
    padding: 20px 15px;
  }
  .single #primary:not(.fix-width) .tag .date {
    font-size: 60px;
    line-height: 0.8;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding-bottom: 20px;
  }
  .single #primary:not(.fix-width) .tag .month {
    font-weight: 600;
  }
  .single #primary:not(.fix-width) .tag .info {
    background: #d2d2d2;
    color: #414141;
    padding: 20px 15px;
    font-size: 16px;
    margin-top: 4px;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-variant: normal;
  }
  /* .single #primary:not(.fix-width) .tag .info .cmt{
    	background: url('../defaultImages/tag_cmt.png') no-repeat center top;
    	padding-top: 17px;
    }
    .single #primary:not(.fix-width) .tag .info .view{
    	background: url('../defaultImages/tag_view.png') no-repeat center top;
    	padding-top: 17px;
    	margin-top: 10px;
    } */
  .menu-mobile {
    display: none;
  }
  .menu-main {
    background: #f54989;
  }
  .menu-main .fix-width {
    position: relative;
  }
  nav#primary-menu > ul > li:hover > a,
  nav#primary-menu > ul > li.current_page_item > a,
  nav#primary-menu > ul > li.current-menu-ancestor > a,
  nav#primary-menu > ul > li.current-menu-item > a,
  nav#primary-menu > ul > li.current-menu-parent > a {
  }
  .news {
    width: 49%;
    float: left;
    padding-right: 1%;
  }
  .news.events {
    width: 49%;
    float: right;
    padding-right: 0;
    padding-left: 1%;
  }
  #customer .slides .customer {
    margin-right: 30px;
    width: calc((100% - 40px - (30px * 5)) / 6);
  }
  .archive .archive-services .service {
    width: 31%;
    margin-left: 3.5%;
  }
  .archive .archive-services .service:nth-child(2n + 1) {
    clear: none;
    margin-left: 3.5%;
  }
  .archive .archive-services .service:nth-child(3n + 1) {
    clear: left;
    margin-left: 0;
  }
  .archive .archive-services .service:nth-child(1),
  .archive .archive-services .service:nth-child(2),
  .archive .archive-services .service:nth-child(3) {
    margin-top: 0;
  }
  .sidebar .widget {
    width: auto;
    float: none;
    margin-left: 0;
  }
  #mobile-menu {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  #service-suport #flex .slides li {
    float: none;
    width: 100%;
  }
  .postid-79 #service-suport #flex .slides li,
  .postid-81 #service-suport #flex .slides li {
    width: 298px !important;
  }
  #oshc-more-content .oshc-clm {
    width: 100%;
    margin-bottom: 50px;
  }
  #services .wrap-content {
    padding: 10px 10px 50px;
  }
  #reviews .flexslider .entry {
    margin-right: 20px;
  }
  #service-suport.accommodation .slides li form .clm-content-item {
    min-height: 150px;
  }
  #service-suport #accommodation .slides li a::after {
    border-bottom: none;
  }
}
.mb-3 {
  margin-bottom: 30px;
}
.mt-3 {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .border-left {
    border-left: 1px solid #eee;
  }
  .border-right {
    border-right: 1px solid #eee;
  }
}
@media (min-width: 1200px) {
  .border-left {
    border-left: 1px solid #eee;
  }
  .border-right {
    border-right: 1px solid #eee;
  }
}
@media only screen and (min-width: 992px) {
  /*template study tour*/
  #section_program .content {
    border-left: solid 1px #f54989;
  }
  #section_program .col-md-4 {
    padding-right: 0;
  }
  #section_program .col-md-7 {
    width: 61.33333333%;
  }
  #myModal .modal-lg {
    width: fit-content;
  }
}
@media only screen and (min-width: 1024px) {
  /* Body Start */
  html {
    min-width: 980px;
  }
  .fix-width-study-tour {
    width: 960px;
    min-width: 960px;
  }
  .fix-width {
    min-width: 980px;
  }
  .fix-width-other {
    width: 980px;
  }
  #logo img {
    max-width: 400px;
  }
  #reviews .flexslider .entry {
  }
}

@media only screen and (max-width: 1179px) {
  .fix-width {
    padding: 0 10px;
  }
  #container {
    padding-left: 10px;
    padding-right: 10px;
  }
  #service-suport.accommodation .slides li form .clm-content-item {
    min-height: 200px;
  }
}
@media only screen and (min-width: 1180px) {
  .fix-width {
    width: 1200px;
  }
  .site-title {
    font-size: 23px;
    font-size: 2.3rem;
  }
  .site-description {
    font-size: 25px;
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 1400px) {
  .fix-width-other {
    width: 80%;
  }
  .other-services {
    margin-left: 22%;
  }
}
@media only screen and (max-width: 1399px) {
  .group nav#primary-menu > ul > li > a {
    padding: 0 8px;
  }
  .fix-width-other {
    width: calc(((100%-60px) / 100) * 80);
  }
  a.other-service {
    font-size: 14px;
  }
  #reviews .flex-viewport {
    padding: 20px 0px;
  }
  #reviews .wrap-content {
    padding: 40px 0 40px;
  }
  iframe.iframe_video {
    height: 210px;
    width: 315px;
  }
  .inner_video {
    height: 210px;
  }
  .other-services {
    padding-bottom: 20px;
    padding-left: 250px;
  }
  #reviews .flexslider .entry .content {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 1300px) {
  .other-services {
    padding-left: 140px;
  }

  .other-services {
    width: calc(100%-140px);
    padding-bottom: 10px;
  }
  h3.other-services-title {
    padding-left: 0px;
  }
  h3.other-services-title::after {
    display: block;
  }
  a.other-service {
    padding-left: 0px;
    font-size: 16px;
  }
  a.other-service:before {
    width: 70px;
  }

  .other-services-video {
    width: 100%;
  }
  .inner_video {
    width: 50%;
    margin: 0 auto;
    height: 320px;
  }
  iframe.iframe_video {
    width: 100%;
    height: 300px;
  }
}
@media only screen and (max-width: 859px) {
  .divcontentbanner {
    width: 350px;
    height: 140px;
    top: calc(-70px + 50%);
    right: calc(-175px + 50%);
  }
  .divheader {
    width: 96%;
    height: 30%;
    margin-left: 6px;
    margin-top: 6px;
  }

  .divheader img {
    width: 101%;
  }
  .imgheader {
    height: 80%;
    float: left;
    padding-top: 5px;
    padding-left: 10px;
  }
  .pheader {
    width: 80%;
    padding-left: 15%;
    padding-top: 8px;
    font-size: 11px;
    font-weight: bold;
    color: #f54989;
    text-align: center;
    line-height: 14px;
  }
  a.aheader {
    top: 3px;
    right: 10px;
  }
  .fa-close {
    font: normal normal normal 5px/1 FontAwesome;
    font-size: 16px;
  }
  div.divmain > p:nth-child(1) {
    padding-top: 7px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  div.divmain > a {
    padding: 5px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 4px;
    font-size: 12px;
  }
  div.divmain > p:nth-child(3) {
    padding-top: 7px;
    padding-right: 33px;
    font-size: 7px;
  }
  .a_quote_ovhc {
    position: absolute;
    top: 95px;
    right: -40px;
  }
  .img_quote_ovhc {
    height: 70px;
  }
}
@media only screen and (min-width: 860px) and (max-width: 1179px) {
  .divcontentbanner {
    width: 550px;
    height: 220px;
    top: calc(-110px + 50%);
    right: calc(-275px + 50%);
  }
  .divheader {
    width: 96%;
  }
  .imgheader {
    padding-top: 8px;
    padding-left: 20px;
  }
  .pheader {
    width: 80%;
    padding-left: 15%;
    padding-top: 20px;
    font-size: 17px;
    font-weight: bold;
    color: #f54989;
    text-align: center;
    line-height: 20px;
  }
  a.aheader {
    top: 15px;
    right: 20px;
  }
  .fa-close {
    font: normal normal normal 7px/1 FontAwesome;
    font-size: 24px;
  }
  div.divmain > p:nth-child(1) {
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
  }
  div.divmain > a {
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    font-size: 18px;
  }
  div.divmain > p:nth-child(3) {
    padding-top: 10px;
    padding-right: 50px;
    font-size: 11px;
  }
  .a_quote_ovhc {
    position: absolute;
    top: 150px;
    right: -50px;
  }
  .img_quote_ovhc {
    height: 100px;
  }
}
.quick_quote {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #efeeee;
  border-radius: 5px;
  width: 66px;
  z-index: 11111111;
}
#menu-quick-quote {
  padding-left: 0;
  list-style: none;
  margin: 0;
}
#menu-quick-quote > .menu-item > a {
  font-size: 0;
}
#menu-quick-quote .sub-menu {
  padding-left: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(100%) translateY(-50%);
  list-style: none;
  text-align: center;
  display: none;
  transition: display 0.3s;
}
#menu-quick-quote > li {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px #fff solid;
  position: relative;
}
#menu-quick-quote > li:hover {
  background: #d82165;
}
#menu-quick-quote > li:hover > ul.sub-menu {
  display: block;
}
#menu-quick-quote > li:last-child {
  border-bottom: none;
}
@media only screen and (min-width: 960px) and (max-width: 1023px) {
  nav#primary-menu > ul > li {
    font-size: 12px;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1179px) {
  #primary-menu {
    right: 10px;
  }
  nav#primary-menu > ul > li > a {
    padding: 0 5px;
  }
}
.single .site-information,
.page .site-information,
.archive .site-information {
  float: right;
}
@media only screen and (max-width: 768px) {
  #menu-menu-check-service-price {
    margin: 0 auto !important;
    width: 100% !important;
    padding: 0 20px !important;
  }
  .customers .customer:last-child {
    padding-left: 0;
  }
  .copyright {
    font-size: 12px;
  }
  .customers .customer {
    width: 100%;
  }
  .modal-body {
    overflow: scroll;
  }
  .modal-body .img-responsive {
    width: 1000px;
    max-width: 1000px;
  }

  /* HEADER */
  .branding .banner {
    height: 185px;
  }
  .banner-re {
    display: none;
  }
  .site-information .socials::before {
    background-color: transparent;
  }
  div.search {
    display: none;
  }
  .language {
    display: none;
  }

  .menu-main {
    position: relative;
  }
  .menuright {
    width: auto;
    top: auto;
    bottom: 0;
    background-color: #f54989;
    padding: 0 7px;
  }
  #login-menu {
    width: auto;
    float: none;
  }
  .menu-mobile {
    top: 1em;
  }
  .site-information .socials {
    width: auto;
    float: none;
  }
  .site-information {
    display: block;
    float: right;
    margin-top: 20px;
  }

  /*Other services*/
  .other-services {
    padding-left: 0px;
    width: 100%;
  }
  h3.other-services-title {
    text-align: center;
    padding-left: 0px;
  }
  h3.other-services-title::after {
    display: block;
    margin: 0 auto;
  }
  a.other-service {
    padding-left: 0px;
  }
  a.other-service:before {
    width: 41px;
  }
  .inner_video {
    width: calc(100% - 20px);
  }
  /* a.other-service-No1:before {
		background: url(images/MARKETINGmobie.png) no-repeat center center;
	}
	a.other-service-No1:hover:before {
		background: url(images/MARKETINGhmobie.png) no-repeat center center;
	}
	a.other-service-No2:before {
		background: url(images/STUDYTOURmobie.png) no-repeat center center;
	}
	a.other-service-No2:hover:before {
		background: url(images/STUDYTOURhmobie.png) no-repeat center center;
	}
	a.other-service-No3:before {
		background: url(images/DESIGNmobie.png) no-repeat center center;
	}
	a.other-service-No3:hover:before {
		background: url(images/DESIGNhmobie.png) no-repeat center center;
	}
	a.other-service-No4:before {
		background: url(images/CRMmobie.png) no-repeat center center;
	}
	a.other-service-No4:hover:before {
		background: url(images/CRMhmobie.png) no-repeat center center;
	} */
  .inner_video {
    height: 240px;
  }
  iframe.iframe_video {
    height: 240px;
  }
  /* SLIDER */
  #ht-slider {
    display: none;
  }

  /* UNTI
    .registry { display: none; }*/

  /* MENU */
  /*#mobile-menu .sub-menu .sub-menu { display: none; }*/
  #mobile-menu {
    z-index: 16000002;
  }

  .groupnote .section-content .haft .note,
  #more-content-ovhc .section-content .haft .note,
  #more-content-oshc .section-content .haft .note {
    width: 100%;
    border-left: none;
  }
  .groupnote .section-content .haft .note,
  #more-content-ovhc .section-content .haft .note,
  #more-content-oshc .section-content .haft .note {
    margin-bottom: 20px;
  }

  .steps-0 .desc {
    width: 100%;
  }

  .steps .wap {
    float: left;
    margin-right: 10px;
    width: 48%;
  }
  .steps .wap:nth-child(2n) {
    margin-right: 0;
  }
  .steps .wap:last-child {
    width: 100%;
    margin-right: 0;
  }
  .step_mid {
    display: none;
  }
  .steps .step_bottom .wap .note:after {
    top: auto;
  }
  .steps .step_bottom .wap {
    margin-right: 0;
    width: 60%;
  }
  .steps .step_bottom .wap:last-child {
    width: 60%;
    margin-top: 0;
  }
  .steps .step_bottom .wap:last-child .note:after {
    border-left: none;
  }

  .home #accommodation .slides li {
    width: 98% !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }

  #reviews .listing-reviews .review .thumb {
    float: none;
    margin: 0 auto;
  }
  #reviews .flexslider .entry .content {
    float: none;
    width: 100%;
    padding: 0;
    font-size: 12px;
  }

  #reviews .flexslider .entry {
    margin-right: 0;
  }
  #reviews .flex-viewport {
    width: calc(100% - 50px);
    padding: 0;
    margin-left: 20px;
  }
  #reviews .wrap-content .flex-direction-nav .flex-next {
    left: calc(100% - 50px);
  }
  /*
    #horiz_container li { width: 100%; }
	*/
  footer.footer .about .content {
    width: 100%;
    overflow: hidden;
  }
  footer.footer .about .content .text {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
  }
  footer.footer .about .content::before {
    left: 0;
  }
  footer.footer .about .content .video {
    float: none;
    margin: auto;
    width: 100%;
  }
  footer.footer .list-offices {
    float: none;
    width: 100%;
  }
  /*
	#horiz_container li { width: 33.33333333%; }
    #horiz_container_outer .service-category .wap { margin: 0 15px; }
    #horiz_container { height: auto !important;width: 100% !important; }
    #horiz_container_outer { height: auto !important; }
    #horiz_container li { float: none !important;width: 100% !important;max-width: 400px;margin: auto !important; }
	*/
  #horiz_container_outer .service-category .wap {
    margin: 0 0px;
  }
  #horiz_container_outer .service-category .excerpt .title {
    font-size: 11px;
    width: 61%;
  }
  .search-mobile {
    position: relative;
  }
  .search-mobile input[type="text"] {
    width: 96%;
    margin: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .search-mobile button {
    background: #fff url("../defaultImages/icon_search.png") no-repeat scroll
      center center;
    border: none;
    height: 17px;
    position: absolute;
    right: 10px;
    top: 6px;
    width: 21px;
  }

  .mobile-language ul {
    text-align: center;
  }
  .mobile-language ul li {
    display: inline-block !important;
    margin: 0 5px !important;
    float: none;
  }

  footer.footer .about a.more,
  footer.footer .about a.policy,
  footer.footer .about a.terms,
  footer.footer .about a.refund {
    display: block;
    text-align: center;
    float: none;
    margin-top: 10px;
  }

  .group {
    display: none;
  }
  .logo_mobile {
    display: inline block;
  }
  .parallax .layer_back {
    display: none;
  }
  .intro-banner.parallax .background_layer {
    display: none;
  }
  .breadcrumb span {
    display: block;
  }
  .breadcrumb .delimiter {
    display: none;
  }
  .intro-banner #crumbs {
    padding-left: 0;
    position: inherit;
  }
  .single .intro-banner-2.parallax {
    height: 110px;
  }
  .intro-banner-2 #crumbs {
    padding: 12px 0px;
  }
  .page-template-page-home-about .intro-banner.parallax,
  .archive .intro-banner.parallax {
    height: auto;
  }
  .single .intro-banner.parallax,
  .page .intro-banner.parallax {
    height: auto;
  }
  .single .countrys,
  .page .countrys,
  .category .countrys {
    width: 100%;
  }
  .single .countrys .service-category,
  .page .countrys .service-category,
  .category .countrys .service-category {
    float: none !important;
    padding: 0;
  }

  .single .excerpt_countrys .title,
  .page .excerpt_countrys .title {
    margin: 5px 0;
    width: 100%;
    display: block;
    padding: 0;
  }
  .single .countrys .service-category.flex-active-slide:after,
  .page .countrys .service-category.flex-active-slide:after {
    border: none;
    bottom: 0;
  }
  .single #template-airport_pickup #content .ctn_left,
  .single #template-guardian_service #content .ctn_left {
    width: 100%;
    margin-right: 0px;
  }
  .single #content,
  .page #content {
    padding: 30px 10px;
    background-size: auto !important;
  }
  .single #content .ctn_left,
  .page #content .ctn_left {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  .page #content .ctn_left p:first-child {
    background: url("../defaultImages/ct_1.png") no-repeat top center !important;
    padding-left: 0;
    padding-top: 75px;
  }
  .page #content .ctn_left p:nth-child(2) {
    background: url("../defaultImages/ct_2.png") no-repeat top center !important;
    padding-left: 0;
    padding-top: 75px;
  }
  .single #content.telephone .ctn_left p {
    padding-left: 0px;
  }
  #service-suport #flex .slides li {
    float: none;
    width: 100%;
  }
  .postid-79 #service-suport #flex .slides li,
  .postid-81 #service-suport #flex .slides li {
    width: 298px !important;
  }
  #service-suport .slides li form .clm-content-item {
    height: 50px;
  }
  #oshc-more-content .oshc-clm {
    width: 100%;
    margin-bottom: 50px;
  }
  #oshc-more-content .oshc-clm-left .oshc-clm-content ul li {
    padding-left: 35px;
  }
  #oshc-more-content .oshc-clm-content {
    padding: 0 10px;
  }
  #oshc-more-content .oshc-clm-content .part {
    width: 100%;
    float: none;
  }
  .section-content {
    padding: 0 5px;
  }
  #help-suport .note {
    width: 100%;
    display: block !important;
    padding: 0;
    text-align: center;
    margin-bottom: 10px;
  }
  .how {
    display: block !important;
  }
  .single .nz #content .quick,
  .single .us #content .quick,
  .single .ca #content .quick,
  .page .nz #content .quick,
  .single #content .quick,
  .page #content .quick {
    width: 200px;
    float: none;
    display: block;
    margin: auto;
    text-align: center;
  }
  .single #content.accommodation .ctn_left p,
  .single #content.guardian_service .ctn_left p,
  .single .nz #content .ctn_left p,
  .page #content.accommodation .ctn_left p,
  .page #content.guardian_service .ctn_left p,
  .page .nz #content .ctn_left p,
  .single .us #content .ctn_left p,
  .single .ca #content .ctn_left p {
    padding-left: 0;
  }
  /*.single .nz #content, .single .us #content, .page .nz #content{padding: 0 0;}*/
  .single .us #content .ctn_left,
  .single .ca #content .ctn_left,
  .single #content.accommodation .ctn_left,
  .single #content.guardian_service .ctn_left,
  .single .nz #content .ctn_left,
  .page #content.accommodation .ctn_left,
  .page #content.guardian_service .ctn_left,
  .page .nz #content .ctn_left {
    text-align: left;
    width: 100%;
  }
  .single .nz #content .ctn_left ul li,
  .page .nz #content .ctn_left ul li,
  .single #template-default.us #content .ctn_left ul li,
  .single #content.accommodation .ctn_left ul li,
  .page #content.accommodation .ctn_left ul li {
    background: none;
    padding-right: 0px;
  }
  #quick-quote .quote-content {
    width: 96%;
    float: none;
    margin-right: 0;
    min-height: auto;
  }
  #quick-quote .quote-content ul {
    padding: 10px;
  }
  #quick-quote .quote-content ul li {
    padding: 0;
    font-size: 18px;
  }
  #quick-quote .text {
    display: none;
  }
  .services .desc {
    width: 80%;
  }
  /*.steps-0, .steps, #reviews { display: none;}*/
  .howto .note,
  #help-suport .note {
    padding: 10px 20px;
  }
  .news .entry:last-child {
    display: none;
  }
  footer.footer {
    padding-top: 20px;
  }
  /*
    #horiz_container_outer .service-category .thumb img { max-width: 100%;height: auto; }
    #horiz_container_outer .service-category .excerpt { padding: 5px;overflow: auto; }
    #horiz_container_outer .service-category .excerpt .row { }
    #horiz_container_outer .service-category .excerpt .title { float: none;width: 100%; }
    #horiz_container_outer .service-category .excerpt .quote { float: none; }
	*/
  .customers .slides .item {
    margin-right: 0;
  }
  .customers .slides .item .thumb img {
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    -o-filter: none;
    filter: none;
  }
  /* quick-quote */
  /*
	#quick-quote.telephone .quote-content{
		width: 96%;
	}
	#quick-quote.telephone .quote-content:nth-child(2){
		width: 96%;
	}
	*/
  .uc #quick-quote.airport_pickup .quote-content ul,
  .uc #quick-quote.guardian_service .quote-content ul {
    min-height: auto;
  }
  .nz #quick-quote.airport_pickup .quote-content ul {
    min-height: auto;
  }
  .us #quick-quote .quote-content ul,
  .ca #quick-quote .quote-content ul,
  .nz #quick-quote .quote-content ul {
    min-height: auto;
  }
  #quick-quote .quote-content ul {
    min-height: auto;
  }

  /*Get A QUOTE*/

  /* Force table to not be like tables anymore */
  td .uc_768 {
    display: block;
  }
  td .uc_768 td {
    display: block;
    text-align: center;
    vertical-align: middle;
  }
  td .nz_768 td,
  td .us_768 td {
    display: block;
  }
  td {
    position: relative;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  td .uc_768 td span,
  td .nz_768 td span,
  td .us_768 td span {
    font-weight: normal;
  }
  td .nz_768 td,
  td .us_768 td {
    text-align: center;
  }
}

@media only screen and (max-width: 399px) {
  .single .us #content,
  .single .ca #content {
    padding: 45px 0;
  }
  .postid-434 .us #content {
    padding: 0 0;
  }
  #service-suport .slides li form .clm-content-item {
    height: auto;
  }
  .section-title {
    font-size: 2.1rem;
  }
}
@media only screen and (max-width: 479px) {
  #help-suport .note {
    padding: 10px 5px;
  }
  #options_td,
  #provider_td,
  td .uc_768 td {
    display: block;
  }
  #options_td {
    width: auto;
  }
  td .uc_768 {
    display: inline-flex;
  }
  td .nz_768 {
    display: block;
  }
  td .uc_768 table,
  td #provider_td table {
    margin: 0 auto;
  }
  td .uc_768 td,
  td .nz_768 td,
  td .us_768 td {
    text-align: center;
  }
  td .uc_768 td span,
  td .nz_768 td span,
  td .us_768 td span {
    font-weight: normal;
  }
}

#provider_td > table > tbody > tr > td > table > tbody > tr > td:nth-child(1) {
  width: 160px;
  margin-left: calc(50% - 170px);
  float: left;
}
#wrap
  .main-quote
  table
  td
  > table
  > tbody
  > tr
  > td.uc_oshc
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  border-radius: 8px;
  margin-right: calc(50% - 170px);
  float: right;
}
#wrap
  .main-quote
  table
  td
  > table
  > tbody
  > tr
  > td.uc_oshc
  > table
  > tbody
  > tr
  > td:nth-child(3),
#wrap
  .main-quote
  table
  td
  > table
  > tbody
  > tr
  > td.uc_oshc
  > table
  > tbody
  > tr
  > td:nth-child(4) {
  float: left;
  margin-left: calc(50% - 170px);
}
a.a_policy:before,
a.a_card:before,
a.a_guideline:before {
  height: 40px;
}
@media only screen and (max-width: 768px) {
  .quick_quote {
    bottom: 0;
    transform: translateY(0);
  }
}
@media only screen and (min-width: 768px) {
  /* Template STUDYTOUR*/

  #section_header .navbar-nav {
    margin-top: -35px;
  }
  #section_header .navbar-nav > li {
    width: 25%;
  }
  #section_header .navbar-nav > li > a {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  #section_us .hr_title,
  #section_news .hr_title {
    width: 50%;
  }
  #section_gallery .icon_gallery {
    width: 8%;
  }
  td .uc_768 td,
  td .nz_768 td,
  td .us_768 td {
    text-align: center;
    display: block;
  }
  td .uc_768 td span,
  td .nz_768 td span,
  td .us_768 td span {
    font-weight: normal;
  }
  #provider_td
    > table
    > tbody
    > tr
    > td
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    width: auto;
    margin-left: auto;
    float: none;
  }
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_oshc
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    border-radius: unset;
    margin-right: auto;
    float: none;
  }
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_oshc
    > table
    > tbody
    > tr
    > td:nth-child(3),
  #wrap
    .main-quote
    table
    td
    > table
    > tbody
    > tr
    > td.uc_oshc
    > table
    > tbody
    > tr
    > td:nth-child(4) {
    float: none;
    margin-left: auto;
  }
  a.a_policy:before,
  a.a_card:before,
  a.a_guideline:before {
    height: 35px;
  }
}

@media only screen and (max-width: 359px) {
  .news .entry .thumb {
    width: 100%;
    margin-right: 0;
    float: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .news .entry .thumb img {
    max-width: 100%;
    height: auto;
  }
  .news .entry .entry-meta {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .customers .customer {
    width: 100%;
    padding-right: 0;
    border: none;
  }
}

.page-service {
  /*background: url(images/page_service_bg.png) no-repeat center top;
    background-size: 100%;*/
  height: 600px;
}
.page-service-inner {
  /* background: url('images/page_service_bg.png') no-repeat center top; */
  background-size: 100% 600px;
  height: 600px;
  position: relative;
}

.usa {
  left: 21%;
  position: absolute;
  top: 34%;
  width: 70px;
}
.usa > a {
  display: block;
  text-indent: -9999px;
}
.usa:hover ul {
  display: block;
}

.canada {
  left: 20%;
  position: absolute;
  top: 26%;
  width: 50px;
}
.canada > a {
  display: block;
  text-indent: -9999px;
}
.canada:hover ul {
  display: block;
}

.aus {
  right: 24%;
  position: absolute;
  top: 68%;
  width: 75px;
}
.aus > a {
  display: block;
  text-indent: -9999px;
}
.aus:hover ul {
  display: block;
}
.aus ul {
  top: -80px !important;
}
.aus ul:after {
  top: 60px !important;
}

.newz {
  right: 20%;
  position: absolute;
  top: 77%;
  width: 75px;
}
.newz > a {
  display: block;
  text-indent: -9999px;
}
.newz:hover ul {
  display: block;
}
.newz ul {
  top: -80px !important;
}
.newz ul:after {
  top: 60px !important;
}

.page-service-inner ul {
  background-color: #f54989;
  padding: 10px 30px;
  position: absolute;
  left: 100%;
  top: 0;
  width: 200px;
  display: none;
}
.page-service-inner ul:after {
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f54989;
  border-top: 10px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  left: -10px;
  top: 10px;
  content: "";
}
.page-service-inner ul li {
  list-style: none;
  border-bottom: 1px solid #fe343b;
  padding: 5px 0;
}

.page-service-inner ul li:last-child {
  border-bottom: none;
}
.page-service-inner ul li a {
  color: #fff;
}

.img-responsive {
  max-width: 80%;
}
.imgs {
  text-align: center;
  padding: 30px;
}

@media only screen and (max-width: 768px) {
  .page-service-inner {
    background: none;
    height: auto;
  }
  .page-service {
    height: auto;
  }
  /*
	.aus:hover ul {display: none;}
	.usa:hover ul {display: none;}
	.newz:hover ul {display: none;}
	.canada:hover ul {display: none;}*/
  .usa,
  .aus,
  .newz,
  .canada {
    background-color: #f54989;
    display: block;
    left: auto;
    position: inherit;
    top: auto;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    margin-bottom: 5px;
    right: 0px;
  }
  .usa > a,
  .canada > a,
  .aus > a,
  .newz > a {
    display: block;
    text-indent: 0;
    color: #fff;
  }
  .page-service-inner ul {
    position: static;
    left: 100%;
    top: 0;
    width: 100%;
    display: none;
    padding: 10px 0;
  }
  .page-service-inner ul:after {
    border-bottom: none;
    border-right: none;
    border-top: none;
  }
  #login-menu > ul > li > a {
    margin-right: 0;
  }
  #login-menu > ul > li > span {
    padding: 10px 18px;
  }
  .nz #flex .main .row:nth-child(2) {
    width: 100%;
    float: none;
    border-right: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  .postid-228 #flex .main .row:nth-child(2) {
    width: 25%;
    float: right;
    border: none;
    padding: 0px;
  }
  .nz #flex .main .row {
    width: 100%;
    float: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  .us #flex .main .row:nth-child(2),
  .us #flex .main .row:nth-child(6),
  .ca #flex .main .row:nth-child(2),
  .ca #flex .main .row:nth-child(6) {
    padding-left: 0;
    padding-right: 2%;
    width: 100%;
    border-left: none;
    float: none;
  }
  .us #flex .main .row,
  .ca #flex .main .row {
    padding-right: 2%;
    padding-left: 0;
    width: 100%;
    border: none;
    float: none;
    padding-bottom: 0px;
  }
  .us #flex .main .line,
  .ca #flex .main .line {
    display: none;
  }
  .us #flex .main .row:nth-child(5),
  .us #flex .main .row:nth-child(3),
  .ca #flex .main .row:nth-child(5),
  .ca #flex .main .row:nth-child(3) {
    padding-bottom: 0px;
  }
  .postid-580 .us #flex .main .row,
  .postid-1722 .ca #flex .main .row {
    padding-left: 2%;
    padding-right: 0%;
    text-align: left;
    width: 100%;
    float: none;
    border: none;
    padding-bottom: 0px;
  }
  .postid-580 .us #flex .main .row:nth-child(2n + 2),
  .postid-1722 .ca #flex .main .row:nth-child(2n + 2) {
    padding-left: 2%;
    padding-right: 0%;
    width: 100%;
    float: none;
    border: none;
  }
  /*.postid-427 #main #flex .slides li{
		width:100%;
	}*/
  .postid-427 #flexpart .slides li form,
  .postid-427 #flexpart .slides li form .clm-title-item,
  .postid-1313 #flexpart .slides li form,
  .postid-1313 #flexpart .slides li form .clm-title-item {
    text-align: center;
  }
  .single #content .quick {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .single .nz #content .quick,
  .single .us #content .quick,
  .single .ca #content .quick,
  .page .nz #content .quick {
    padding: 0px 3%;
  }
  .postid-578 .nz #content .ctn_left,
  .postid-82 .uc #content .ctn_left,
  .postid-581 .us #content .ctn_left,
  .postid-1730 .ca #content .ctn_left {
    margin: 0px;
  }
  .postid-427 .us .block3 .block,
  .postid-1313 .ca .block3 .block {
    min-height: 230px;
  }
}

.section-content {
  padding: 0;
}

@media only screen and (max-width: 1200px) {
  td .uc_768 {
    display: block;
  }
  .postid-580 .block3 .block,
  .postid-1722 .block3 .block {
    min-height: 250px;
  }
  .postid-580 .block3 .block h3,
  .postid-1722 .block3 .block h3,
  .block3 .block h3 {
    line-height: 1.4;
  }
  .page #wrap .fix-width {
  }
  .single #template-oshc #content {
    padding: 30px 10px;
  }
  .single #template-ovhc #content {
    padding: 30px 10px;
  }
}
@media only screen and (max-width: 859px) {
  .postid-580 .block3 .block,
  .postid-1722 .block3 .block {
    min-height: 180px;
  }
}

@media only screen and (max-width: 479px) {
  .postid-580 .block3 .block,
  .postid-1722 .block3 .block {
    min-height: 250px;
  }
  #template-airport_pickup #service-suport .slides li form .clm-content-item,
  #service-suport.accommodation .slides li form .clm-content-item {
    min-height: 200px;
  }
  input#savequote_submit {
    display: block !important;
    margin-left: auto !important;
  }
}
@media only screen and (max-width: 359px) {
  #service-suport.accommodation .slides li form .clm-content-item {
    min-height: 230px;
  }
  .postid-228 .nz #content {
    padding: 0px 0;
  }
  .postid-228 #content .ctn_left {
    font-size: 18px;
  }
}
.img_gift {
  display: block !important;
  width: 67px !important;
  margin: -12px auto !important;
}
.tooltip_templates {
  display: none;
}
.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-customized
  .tooltipster-box {
  background: #fff;
  border: 3px solid #ccc;
  border-radius: 3px;
  box-shadow: 0px;
}

.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-customized
  .tooltipster-content {
  color: #000;
  padding: 8px;
}
#section_program .block-info ul {
  height: 375px;
  overflow-y: scroll;
  padding-right: 10px;
}
.entry-content .registration-form {
  display: block !important;
  margin: 20px 0px;
}
.registration-form .wpcf7 {
  width: 100% !important;
  background-color: transparent !important;
  border: 0;
}
.form-full {
  background-color: #eaf7f8;
  padding: 10px 0;
  height: auto;
  overflow: hidden;
  width: 100%;
}

.form-full .wpcf7-submit {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  background: #ef4b88;
  color: #fff;
  padding: 15px 0;
  border-radius: 10px;
  width: 300px;
  margin-top: 30px;
}

.form-full .col-sm-6 {
  width: 47%;
  padding: 0;
  margin: 10px;
}
.form-full .col-sm-12 {
  width: 96%;
}
.form-full .wpcf7-submit:hover {
  background: #f54272;
}
.form-full input[type="tel"],
.form-full input[type="text"],
.form-full input[type="email"] {
  height: 40px !important;
}
.registration-form div.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  float: left !important;
  width: 97% !important;
  text-align: center;
}
.registration-form div.wpcf7-validation-errors,
.registration-form div.wpcf7-acceptance-missing {
  border: 2px solid #f7e700;
  float: left !important;
  width: 97% !important;
  text-align: center;
}
.registration-form .wpcf7 form {
  margin: 0;
}
.registration-form .wpcf7 h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  background-color: #ef4b88;
  color: #fff;
  padding: 15px 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin: 0;
}
#template-telephone #service-suport .slides {
  width: 100% !important;
  text-align: center;
}
#template-telephone #service-suport .slides li {
  display: inline-block !important;
  *display: inline !important;
  float: none !important;
}
#wrap .form-register-service .right-radio label {
  margin-left: 35px !important;
}
#wrap .form-register-service .right-radio input {
  height: 25px !important;
}

#saveQuoteModal .wpcf7 {
  width: auto;
  background: #ffffff;
  border: none;
}
input#savequote_email {
  border: solid 1px;
  display: inline-block;
  width: auto;
}
input#savequote_submit {
  width: auto;
  background: #f54989;
  display: inline-block;
  padding: 4px 15px;
  margin-left: 10px;
}

.postid-210 #service-suport {
  display: none !important;
}

/*menu-mobile*/

#drop-menu * {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
#drop-menu ul li {
  border-top: solid #efefef 1px;
}
#drop-menu ul li.menu-item-has-children {
  position: relative;
}
#drop-menu ul li.menu-item-has-children:after {
  font: 30px/1 FontAwesome;
  color: #4d4d4d;
  content: "\f107";
  position: absolute;
  top: 5px;
  right: 15px;
}
#drop-menu ul li.menu-item-has-children.drop-up:after {
  font: 30px/1 FontAwesome;
  color: #4d4d4d;
  content: "\f106";
  position: absolute;
  top: 5px;
  right: 15px;
}
#drop-menu ul li.drop-up a,
#drop-menu ul li.drop-up > ul li.drop-up a {
  color: #f54989;
  font-weight: bold;
}
#drop-menu ul li.drop-down a,
#drop-menu ul li.drop-up > ul a,
#drop-menu ul li.drop-up > ul li.drop-up > ul a {
  color: #5b5b5b;
  font-weight: normal;
}
#drop-menu ul li.drop-up .sub-menu {
  display: block;
}
#drop-menu ul li.drop-down .sub-menu {
  display: none;
}
li#menu-item-61462 {
  border-top: none !important;
}
li#menu-item-61510 {
  border-bottom: solid #efefef 1px;
}
#drop-menu a {
  color: #5b5b5b;
  line-height: 40px;
}
#drop-menu ul li a {
  margin-left: 15px;
  padding: 12px 0 12px;
}
#drop-menu ul ul li a {
  margin-left: 35px;
}
#drop-menu ul ul ul li a {
  margin-left: 55px;
}
#menu-menu-login-m .sub-menu {
  position: absolute;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  right: 10px;
  top: 45px;
  width: 250px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  box-shadow: 0px 5px 3px -5px #777;
}
#menu-menu-login-m .span_login:before {
  content: "\f007";
  font-family: "FontAwesome";
  color: #f8f7fa;
  margin-right: 5px;
  font-size: 15px;
}
#menu-menu-login-m .span_login:after {
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #f8f7fa;
  margin-left: 15px;
  font-size: 12px;
}
@media (min-width: 769px) {
  .on-mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .on-desktop {
    display: none;
  }
}
.menu-item-61473,
.menu-item-61475,
.menu-item-61484,
.menu-item-61491,
.menu-item-61493 {
  display: none;
}
.row-field:after {
  content: "";
  display: block;
  clear: both;
}

.content-wrap .row-field {
  margin-bottom: 15px;
}

.content-wrap .row-field .err {
  color: #f00;
  font-style: italic;
  font-size: 13px;
  font-size: 1.3rem;
}

.content-wrap .row-field input {
  height: 40px;
  background-color: #f0f0f0;
  width: 100%;
}

.content-wrap .row-field input[type="checkbox"] {
  width: auto;
  height: auto;
}

.content-wrap .row-field input[type="submit"] {
  line-height: 1;
  padding: 6px 15px;
  height: auto;
}

form .wpcf7-response-output {
  margin: 1em 0 0 0;
  padding: 0.2em 1em;
  border: 2px solid #46b450;
}

.react-datepicker-wrapper {
  width: 100%;
}

.price-item {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.warning-icon {
  position: relative;
}

.warning-icon:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext {
  width: 125px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  font-size: 12px;
  left: 30px;
  top: -15px;
}

.ahm-visa-mastercard-container {
  display: flex;
  flex-direction: column;
  & .note {
    margin-top: 1rem;
    font-size: 12px;
  }
}

input[type="button"]:disabled {
  cursor: not-allowed;
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flywire-btn {
  border: none;
  background-image: url(https://oshcstudents.com.au/wp-content/uploads/2016/01/pay_now_w_flywire.png);
  background-repeat: no-repeat;
  width: 154px;
  height: 46px;
  background-size: 154px 46px;
  padding: 10px 25px;
}

.flywire-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.braintree-sheet__content--form {
  display: flex;
  flex-direction: column;
}

.braintree-sheet__content--form label {
  width: 100% !important;
  padding-left: 0px !important;
}

.braintree-sheet__content--form .braintree-form__flexible-fields {
  flex-direction: column;
  margin-top: 5px;
}

.braintree-sheet__content--form
  .braintree-form__flexible-fields
  .braintree-form__field-group {
  flex-basis: auto;
  margin-bottom: 0px;
}

.braintree-form__notice-of-collection {
  font-weight: bold;
  margin-top: 30px !important;
}

.braintree-sheet__content--form
  .braintree-form__field-group.braintree-form__field-group--has-error {
  display: flex;
  flex-direction: column;
}

.dropin-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nib-dropin-form {
  width: 400px;
}

.nib-dropin-btn {
  color: white;
  width: 150px !important;
  padding: 7px 20px !important;
}

.price_text_qa_none {
  font-size: 20px;
}

.alipay-btn {
  border: none;
  background-image: url(https://d10bqim15li85m.cloudfront.net/Upload/Alipay.svg);
  background-repeat: no-repeat;
  width: 154px;
  height: 46px;
  background-position: center;
  background-size: cover;
  padding: 10px 25px;
}

.alipay-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.alipay-container-notes {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.alipay-popup .modal-dialog {
  max-width: 80%;
  height: 80vh;
}

.alipay-checkout-frame {
  width: 100%;
  height: 80vh;
}

.session-expired-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.session-expired-welcome {
  color: #f54989;
  margin-top: 5px;
  font-weight: bold;
}

.session-expired-link {
  color: #f54989;
  font-weight: bold;
  margin: 0px 5px;
  cursor: pointer;
}

.upload-document {
  display: inline-block;
  width: 100%;

  & .fix-width {
    padding: 0px !important;
  }

  & .wrap-payment {
    padding: 0px !important;
    border: none !important;
  }

  & .wrap-main-payment {
    background: white;
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }

  & .payment-cash .payment-item {
    margin: 0px !important;

    & .title {
      background: #fafafa !important;
      color: #4d4d4d !important;
      border-top: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      font-weight: 400 !important;
    }

    & input {
      display: none !important;
    }
  }
}

#button-terms {
  color: #4d4d4d;
  font-weight: 600 !important;
  font-size: 17px;
}

.terms_and_conditions {
  margin-top: 30px !important;
}

.collapse-required {
  margin-right: 5px;
  font-size: 16px !important;
}

.session-expired-popup {
  & .btn-close {
    display: none;
  }
}

.policy-summary-header {
  display: flex;

  & h4 {
    flex-grow: 1;
    margin: 0px;
  }

  & input {
    padding: 5px 15px !important;
    border: none !important;
    background-color: #dc143c !important;
  }
}

.re-confirm-popup {
  & .btn-close {
    display: none;
  }

  & input {
    width: 100%;
  }

  & select {
    width: 100%;
  }

  & #options_td {
    border: none;
    padding: 0px 10px;
  }
}

.tooltip-error {
  width: 120px;
  background-color: white;
  color: red;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;

  right: 38px;
  top: -26px;
  border: 1px solid;
  font-size: 12px;
}

.wechat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wechat-container-notes {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: 30px;
}

.upload-document-last-item {
  background-color: transparent !important;
  & .title {
    border-bottom: 1px solid #d9d9d9;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  & .wrap-main-payment {
    border-bottom: 1px solid #d9d9d9;
  }

  & .payment-item-expanded {
    border-radius: 0px;
  }

  & .wrap-main-payment {
    border-top: none;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

.upload-document-first-item {
  background-color: transparent !important;
  & .title {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

.group #primary-menu ul li span {
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  cursor: pointer;
}

nav#primary-menu ul ul li span {
  padding: 0px 15px;
  display: block;
  text-decoration: none;
  height: 30px;
  line-height: 30px;
  color: #4d4d4d;
}

.dependent-title {
  width: 80px !important;
}

.bupa-visamastercard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.m-menu-right {
  padding: 5px;
  color: #fff;
  border: none;
  border-radius: 7px;
  margin: 5px;
  background: #f54989;
  float: right;
  font-weight: bold;
}

.agent-name-btn::before {
  content: "\f007";
  font-family: "FontAwesome";
  color: #f54989;
  margin-right: 5px;
  font-size: 15px;
}

.m-agent-name-btn::before {
  content: "\f007";
  font-family: "FontAwesome";
  color: #f8f7fa;
  margin-right: 5px;
  font-size: 15px;
}

.institution-icon {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  cursor: pointer;
}

.institution-tooltip-text {
  font-size: 14px;
}